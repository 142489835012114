<template>
  <div class="row">
    <!-- Stats Cards -->
    <div class="col-lg-3 col-md-6 col-sm-6">
      <stats-card
        v-if="!loading && !loading6"
        :title="Intl.NumberFormat(this.$i18n.locale).format((this.record.library_Track_SizeKb/1048576).toFixed(2)) 
          + $t('pagination.3') 
          + Intl.NumberFormat(this.$i18n.locale).format(this.record4.paramLimits_Content_MaxAudioTrackSizeGb
          + this.record4.paramLimits_Content_MaxVideoTrackSizeGb)
          + $t('dashboard.gb')"
        :sub-title="$t('dashboard.mediaLimits')"
      >
        <div slot="footer">
          <base-progress
            :value="Math.round((this.record.library_Track_SizeKb/1048576)/(this.record4.paramLimits_Content_MaxAudioTrackSizeGb 
              + this.record4.paramLimits_Content_MaxVideoTrackSizeGb)*100)"
            type="info"
            style="margin: 0 20px;"
          >
          </base-progress>
        </div>
      </stats-card>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6">
      <stats-card
        v-if="!loading && !loading6"
        :title="Intl.NumberFormat(this.$i18n.locale).format(this.record.device_Total_Count)
          + $t('pagination.3')
          + Intl.NumberFormat(this.$i18n.locale).format(this.record4.paramLimits_PlayPoint_MaxDeviceCount)"
        :sub-title="$t('dashboard.devLimits')"
      >
        <div slot="footer">
          <base-progress 
            :value="Math.round(this.record.device_Total_Count/this.record4.paramLimits_PlayPoint_MaxDeviceCount*100)"
            type="info"
            style="margin: 0 20px;"
          >
          </base-progress>
        </div>
      </stats-card>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6">
      <stats-card
        v-if="!loading && !loading6"
        :title="Intl.NumberFormat(this.$i18n.locale).format((this.dbSize/1048576).toFixed(2))
          + $t('pagination.3')
          + Intl.NumberFormat(this.$i18n.locale).format(this.dbSubscritionSize)
          + $t('dashboard.gb')"
        :sub-title="$t('dashboard.dbLimits')"
      >
        <div slot="footer">
          <base-progress
            :value="Math.round((this.dbSize/1048576)/this.dbSubscritionSize*100)"
            type="info"
            style="margin: 0 20px;"
          >
          </base-progress>
        </div>
      </stats-card>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6">
      <stats-card
        v-if="!loading6"
        :title="this.record4.subscription_Name"
        :sub-title="$t('dashboard.subscription')"
      >
        <div slot="footer">
          <base-progress :value="Math.round(this.record.platform_State_Status)"
            type="info"
            style="margin: 0 20px;"
          >
          </base-progress>
        </div>
      </stats-card>
    </div>
    
    <!-- Big Chart -->
    <div class="col-12">
      <card type="chart"  v-loading="loading5">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="card-category">{{ $t('dashboard.BCCategory') }}</h5>
              <h2 class="card-title">{{ $t('dashboard.BCTitle') }}</h2>
            </div>
            <div class="col-sm-6 d-flex d-sm-block">
              <div
                class="btn-group btn-group-toggle"
                :class="isRTL ? 'float-left' : 'float-right'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in bigLineChartCategories"
                  :key="option.name"
                  class="btn btn-sm  btn-simple"
                  :class="{ active: bigLineChart.activeIndex === index, ['btn-'+ colorUnification]: true }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="initBigChart(index)"
                    name="options"
                    autocomplete="off"
                    :checked="bigLineChart.activeIndex === index"
                  />
                  <span class="d-none d-sm-block">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </template>
        <div class="chart-area">
          <line-chart v-if="!loading5"
            style="height: 100%"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :gradient-colors="bigLineChart.gradientColors"
            :gradient-stops="bigLineChart.gradientStops"
            :extra-options="bigLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
    </div>

    <!-- Stats Cards -->
    <div class="col-lg-3 col-md-6 col-sm-6">
      <stats-card
        v-if="!loading"
        @click.native="pushToDev('online')" style="cursor: pointer"
        :title="Intl.NumberFormat(this.$i18n.locale).format(this.record.device_Active_Count)
          + $t('pagination.3') 
          + Intl.NumberFormat(this.$i18n.locale).format(this.record.device_Total_Count)"
        :sub-title="$t('dashboard.active')"
        type='success'
        icon='tim-icons icon-check-2'
      >
        <div slot="footer">
          <base-progress
            :value="Math.round(this.record.device_Active_Count/this.record.device_Total_Count*100)"
            type="success"
            style="margin: 0 20px;"
          >
          </base-progress>
        </div>
      </stats-card>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6">
      <stats-card
        v-if="!loading"
        @click.native="pushToDev('linklost')" style="cursor: pointer"
        :title="Intl.NumberFormat(this.$i18n.locale).format(this.record.device_NotActive_Count)
          + $t('pagination.3')
          + Intl.NumberFormat(this.$i18n.locale).format(this.record.device_Total_Count)"
        :sub-title="$t('dashboard.moreHour')"
        type='citrus'
        icon='tim-icons icon-bell-55'
      >
        <div slot="footer">
          <base-progress
          :value="Math.round(this.record.device_NotActive_Count/this.record.device_Total_Count*100)"
          type="citrus"
          style="margin: 0 20px;"
          >
          </base-progress>
        </div>
      </stats-card>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6">
      <stats-card
        v-if="!loading"
        @click.native="pushToDev('linkdown')" style="cursor: pointer"
        :title="Intl.NumberFormat(this.$i18n.locale).format(this.record.device_Unavailable_Count)
          + $t('pagination.3')
          + Intl.NumberFormat(this.$i18n.locale).format(this.record.device_Total_Count)"
        :sub-title="$t('dashboard.moreDay')"
        type='danger'
        icon='tim-icons icon-alert-circle-exc'
      >
        <div slot="footer">
          <base-progress
            :value="Math.round(this.record.device_Unavailable_Count/this.record.device_Total_Count*100)"
            type="danger"
            style="margin: 0 20px;"
          >
          </base-progress>
        </div>
      </stats-card>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-6">
      <stats-card
        v-if="!loading"
        @click.native="pushToDev('neverup')" style="cursor: pointer"
        :title="Intl.NumberFormat(this.$i18n.locale).format(this.record.device_Unknown_Count)
          + $t('pagination.3')
          + Intl.NumberFormat(this.$i18n.locale).format(this.record.device_Total_Count)"
        :sub-title="$t('dashboard.unknown')"
        type='info'
        icon='tim-icons icon-button-power'
      >
        <div slot="footer">
          <base-progress
            :value="Math.round(this.record.device_Unknown_Count/this.record.device_Total_Count*100)"
            type="info"
            style="margin: 0 20px;"
          >
          </base-progress>
        </div>
      </stats-card>
    </div>

    <!-- Small charts - Load average Free space on all devices -->
    <div class="col-lg-4 col-md-6 col-sm-6" :class="{ 'text-right': isRTL }" v-if="!loading7">
      <card type="chart">
        <template slot="header">
          <h5 class="card-category">{{ $t('dashboard.devTitle') }}</h5>
          <h3 class="card-title">
            <i class="tim-icons icon-headphones text-success"></i> {{ $t('dashboard.laTitle') }}
          </h3>
        </template>
        <div class="chart-area">
          <line-chart
            v-loading="loading7"
            style="height: 100%"
            :chart-data="purpleLineChart.chartData"
            :gradient-colors="purpleLineChart.gradientColors"
            :gradient-stops="purpleLineChart.gradientStops"
            :extra-options="purpleLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
    </div>

    <!-- Small charts - Free space on all devices -->
    <div class="col-lg-4 col-md-6 col-sm-6" :class="{ 'text-right': isRTL }" v-if="!loading8">
      <card type="chart">
        <template slot="header">
          <h5 class="card-category">{{ $t('dashboard.devTitle') }}</h5>
          <h3 class="card-title">
            <i class="tim-icons icon-sound-wave text-info "></i> {{ $t('dashboard.hddTitle') }}
          </h3>
        </template>
        <div class="chart-area">
          <bar-chart
            v-loading="loading8"
            style="height: 100%"
            :chart-data="blueBarChart.chartData"
            :gradient-stops="blueBarChart.gradientStops"
            :extra-options="blueBarChart.extraOptions"
          >
          </bar-chart>
        </div>
      </card>
    </div>

    <!-- Small charts - DB -->
    <div class="col-lg-4 col-md-6 col-sm-6" :class="{ 'text-right': isRTL }" v-if="!loading4">
      <card type="chart">
        <template slot="header">
          <h5 class="card-category">{{ $t('dashboard.dbTitle') }}</h5>
          <h3 class="card-title">
            <i class="tim-icons icon-bank text-success "></i> {{ $t('dashboard.dbTitle2') }}
          </h3>
        </template>
        <div class="row">
            <div class="col-6">
              <div class="chart-area">
                <pie-chart
                  :chart-data="pieChart2.chartData"
                  :extra-options="pieChart2.extraOptions"
                  :height="120"
                >
                </pie-chart>
              </div>
            </div>

            <div class="col-6">
              <h4 class="card-title">
                <i class="tim-icons  icon-bullet-list-67 text-success "></i> {{ Intl.NumberFormat(this.$i18n.locale).format(Math.round(this.dbSize/1024)) }} {{ $t('dashboard.mb') }}
              </h4>
              <p><b>{{ $t('dashboard.dbText') }}</b></p>
              <p class="category"><b>Audit:</b> {{ Intl.NumberFormat(this.$i18n.locale).format(Math.round(this.record.storage_Mongo_Audit_SizeKb/1024)) }} {{ $t('dashboard.mb') }}</p>
              <p class="category"><b>Debug:</b> {{ Intl.NumberFormat(this.$i18n.locale).format(Math.round(this.record.storage_Mongo_Debug_SizeKb/1024)) }} {{ $t('dashboard.mb') }}</p>
              <p class="category"><b>Heartbeat:</b> {{ Intl.NumberFormat(this.$i18n.locale).format(Math.round(this.record.storage_Mongo_Heartbeat_SizeKb/1024)) }} {{ $t('dashboard.mb') }}</p>
              <p class="category"><b>Played:</b> {{ Intl.NumberFormat(this.$i18n.locale).format(Math.round(this.record.storage_Mongo_PlayTrack_SizeKb/1024)) }} {{ $t('dashboard.mb') }}</p>
              <p class="category"><b>PlayList:</b> {{ Intl.NumberFormat(this.$i18n.locale).format(Math.round(this.record.storage_Mongo_PlayList_SizeKb/1024)) }} {{ $t('dashboard.mb') }}</p>
              <p class="category"><b>Update:</b> {{ Intl.NumberFormat(this.$i18n.locale).format(Math.round(this.record.storage_Mongo_Update_SizeKb/1024)) }} {{ $t('dashboard.mb') }}</p>
            </div>
          </div>
      </card>
    </div>
   
  </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import PieChart from '@/components/Charts/PieChart';
import * as chartConfigs from '@/components/Charts/config';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import {BaseProgress} from 'src/components'



export default {
  components: {
    BaseProgress,
    LineChart,
    BarChart,
    PieChart,
    StatsCard,
  },
  data() {
    return {
      bigChartData: {},
      bigChartLabels: {},
      bigChartDatasetOptions: {},
      bigLineChart: {},

      purpleLineChart: { chartData: {}},
      blueBarChart: { chartData: {}},
      pieChart2: { chartData: {}},
      dbSize: null,
      dbSubscritionSize: null,
      loading: true,
      loading2: true,
      loading3: true,
      loading4: true,
      loading5: true,
      loading6: true,
      loading7: true,
      loading8: true,
      record: [],
      record2: [],
      record3: [],
      record4: [],
      record5: [],
      record6: [],
    };
  },
  computed: {
    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    // enableRTL() {
    //   return this.$route.query.enableRTL;
    // },

    isRTL() {
      return this.$rtl.isRTL;
    },

    bigLineChartCategories() {
      return [{ name: this.$t('dashboard.BCList1'), icon: 'tim-icons icon-volume-98' },
        { name: this.$t('dashboard.BCList2'), icon: 'tim-icons icon-alert-circle-exc' },
        { name: this.$t('dashboard.BCList3'), icon: 'tim-icons icon-sound-wave' }
      ];
    }
  },
  methods: {
    // Fetch all metrics
    async fetchAllMetricks() {
      this.record = await this.$store.dispatch('allMetrics')
      this.dbSize = this.record.storage_Mongo_Audit_SizeKb
        + this.record.storage_Mongo_Debug_SizeKb 
        + this.record.storage_Mongo_Heartbeat_SizeKb
        + this.record.storage_Mongo_PlayTrack_SizeKb
        + this.record.storage_Mongo_PlayList_SizeKb
        + this.record.storage_Mongo_Update_SizeKb
      this.loading = false
    },

    // Fetch subscription limits
    async subscription() {
      this.record4 = await this.$store.dispatch('subscription')
      this.dbSubscritionSize = this.record4.paramLimits_Logs_AuditStorageGb
      + this.record4.paramLimits_Logs_PlayTrackStorageGb
      + this.record4.paramLimits_Logs_DebugStorageGb
      + this.record4.paramLimits_Logs_HeartbeatStorageGb
      + this.record4.paramLimits_Logs_PlayListStorageGb
      + this.record4.paramLimits_Logs_UpdateStorageGb
      this.loading6 = false
    },

    //Load average on all devices chart
    async initMelChart() {
      this.record5[0] = await this.$store.dispatch('la1Chart');
      this.record5[1] = await this.$store.dispatch('la5Chart');
      this.record5[2] = await this.$store.dispatch('la15Chart');
      
      this.purpleLineChart = {
        extraOptions: chartConfigs.small_ChartOptions,
        chartData: {
          labels: this.record5[0].labels,
          datasets: [
            {
              label: this.$i18n.t('dashboard.laText1'),
              fill: false,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: this.record5[0].data
            },
            {
              label: this.$i18n.t('dashboard.laText5'),
              fill: false,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.info,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: this.record5[1].data
            },
            {
              label: this.$i18n.t('dashboard.laText15'),
              fill: false,
              borderColor: 'purple',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: 'purple',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: 'purple',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: this.record5[2].data
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0]
      }

      this.loading7 = false;
    },

    //Free space on all devices chart
    async initDevChart() {
      this.record6 = await this.$store.dispatch('spaceChart');
      
      // Set dynamic custom range of Y scales for better view with almost equal and big data
      chartConfigs.barChartOptionsGradient.scales.yAxes[0].ticks.suggestedMin = Math.max(...this.record6.data)*0.999;

      this.blueBarChart = {
        // extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: this.record6.labels,
          datasets: [
            {
              label: this.$i18n.t('dashboard.hddText'),
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: this.record6.data
            }
          ]
        },
        extraOptions: chartConfigs.barChartOptionsGradient,
        gradientColors: config.colors.infoGradient,
        gradientStops: [0, 1]
      }

      this.loading8 = false;
    },

    //DB chart
    DBChart() {
      this.pieChart2 = {
        chartData: {
          labels: ["Audit", "Debug", "Heartbeat", "Played", "PlayList", "Update"],
          datasets: [
            {
              label: 'Emails',
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ['#ff8779', '#2a84e9', 'green', 'brown', 'yellow ', 'orange '],
              borderWidth: 0,
              data: [
                Math.round(this.record.storage_Mongo_Audit_SizeKb/this.dbSize*100),
                Math.round(this.record.storage_Mongo_Debug_SizeKb/this.dbSize*100),
                Math.round(this.record.storage_Mongo_Heartbeat_SizeKb/this.dbSize*100),
                Math.round(this.record.storage_Mongo_PlayTrack_SizeKb/this.dbSize*100),
                Math.round(this.record.storage_Mongo_PlayList_SizeKb/this.dbSize*100),
                Math.round(this.record.storage_Mongo_Update_SizeKb/this.dbSize*100)
              ]
            }
          ]
        },
        extraOptions: chartConfigs.pieChartOptions
      }

      this.loading4 = false;
    },

    //Big chart
    async BigChart() {
      // Fill with client counting untill Dima fix it on server
      this.record2 = await this.$store.dispatch('melodyChart');
      this.record3 = await this.$store.dispatch('deviceChart');

      let untillDimaFinishedNormal = [];
      this.record3.data.forEach(element => {
        untillDimaFinishedNormal.push(this.record.device_Total_Count-this.record.device_Unknown_Count-element)
      });

      this.bigChartData = [
        this.record2.data,
        untillDimaFinishedNormal,
        this.record3.data
      ]
      this.bigChartLabels = [this.record2.labels, this.record2.labels, this.record3.labels]
      this.bigChartDatasetOptions = {
        fill: true,
        borderColor: config.colors.primary,
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: config.colors.primary,
        pointBorderColor: 'rgba(255,255,255,0)',
        pointHoverBackgroundColor: config.colors.primary,
        pointBorderWidth: 20,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 4
      }

      
      this.bigLineChart = {
        activeIndex: 0,
        chartData: {
          datasets: [{
            ...this.bigChartDatasetOptions,
            data: this.bigChartData[0]
          }],
          labels: this.bigChartLabels[0]
        },
        extraOptions: chartConfigs.big_ChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },

      this.loading5 = false;
    },

    //Change Big chart pages
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...this.bigChartDatasetOptions,
          data: this.bigChartData[index]
        }],
        labels: this.bigChartLabels[index]
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },

    //Push to the devices
    pushToDev (value) {
      this.$store.commit('setDevFilter', value);
      this.$router.push('/equipments/devices')
    } 

  },
  async mounted() {
    // Fetch all metrics
    await this.fetchAllMetricks()

    // Fetch subscription limitss
    await this.subscription()

    // Melodies chart
    await this.initMelChart()

    // Devices chart
    await this.initDevChart()

    // Fake chart
    await this.DBChart()

    // Big chart
    await this.BigChart()

  },
  beforeDestroy() {
    // if (this.$rtl.isRTL) {
    //   // this.i18n.locale = 'en';
    //   this.$rtl.disableRTL();
    // }
  }
};
</script>
<style></style>
