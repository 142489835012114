var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"col-lg-4 col-md-6 ml-auto mr-auto"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticClass:"card-login card-white"},[_c('template',{slot:"header"},[_c('img',{staticClass:"login-img",attrs:{"src":"img/card-info.png","alt":""}}),_c('h1',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('loginRegPage.titleL')))])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"username","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"email","placeholder":_vm.$t('loginRegPage.email'),"addon-left-icon":"tim-icons icon-email-85","error":errors[0]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":_vm.$t('loginRegPage.password'),"addon-left-icon":"tim-icons icon-lock-circle","type":"password","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('base-button',{staticClass:"mb-3",attrs:{"native-type":"submit","type":"info","size":"lg","block":""}},[_vm._v(" "+_vm._s(_vm.$t('loginRegPage.start'))+" ")]),_c('div',{staticStyle:{"float":"right"}},[_c('small',[_c('i',[_vm._v(" "+_vm._s(_vm.version)+" ")])])])],1)],2)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }