<template>
  <div>
    <div class="row">
      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('buttons.create') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL" :disabled="loading">
          <a class="dropdown-item" @click="modals.miniUploadJSON = true">{{ $t('buttons.importJSON') }}</a>
        </base-dropdown>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ $t('sidebar.tags') }}</h4>
          <tabs :type="colorUnification" ref="childComponent">
            <!-- Devices tag tab -->
            <tab-pane :label="$t('sidebar.devices')">
              <div>
                <div
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <el-select
                    :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      :class="['select-'+ colorUnification]"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>

                  <base-input>
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      :placeholder="$t('profileAction.search')"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </base-input>
                </div>
                <el-table :data="queriedData" @expand-change="expandChange" v-loading="loading" :empty-text="loading ? $t('dataTable.loading') : $t('dataTable.noData')">
                  <el-table-column type="expand" v-if="isMobile" width="25">
                    <div slot-scope="props">
                      <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.lastheartbeatstatecode)"> {{props.row.lastheartbeatstatecode}} </span></p>
                      <p><b>{{ $t('dataTable.type') }}</b>: {{ props.row.typeCode }}</p>
                      <p><b>{{ $t('dataTable.key') }}</b>: {{ props.row.licensekey }}</p>
                      <p v-if="props.row.groupName !=='-'"><b>{{ $t('dataTable.group') }}</b>: <router-link :to="'groupsOne/' + props.row.groupUid"> {{props.row.groupName}} </router-link></p>
                      <p v-else><b>{{ $t('dataTable.group') }}</b>: -</p>
                    </div>
                  </el-table-column>

                  <!-- Expand only for Tags edit in full page view, for edit Tags in mobile need to go in Device card -->
                  <el-table-column type="expand" v-if="!isMobile" width="25">
                    <div slot-scope="props">
                      <div class="row" v-for="(tag, index) in tags" :key="index">
                        <div class="col-5">
                          <base-input
                            :disabled="!tags[index].edit"
                            type="text"
                            v-model="tag.key"
                          >
                          </base-input>
                        </div>
                        <div class="col-5">
                          <base-input
                            :disabled="!tags[index].edit"
                            type="text"
                            v-model="tag.value"
                          >
                          </base-input>
                        </div>
                        <div class="col-2" style="text-align: center;">
                          <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="citrus" @click="editTag(index)" v-if="!tags[index].edit">
                            <i class="tim-icons icon-pencil"></i>
                          </base-button>

                          <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="success" @click="saveTag(index, props.row.uid)" v-if="tags[index].edit">
                            <i class="tim-icons icon-check-2"></i>
                          </base-button>

                          <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="delTag(index, props.row.uid)" v-if="tags[index].edit">
                            <i class="tim-icons icon-trash-simple"></i>
                          </base-button>
                        </div>
                      </div>
                      <div class="row">
                        <base-button round class="animation-on-hover mx-3" :type="colorUnification" @click="addTag()">{{ $t('dataTable.add') }}</base-button>
                      </div>
                    </div>
                  </el-table-column>
                  
                  <el-table-column
                    v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :prop="column.prop"
                    :label="$t(column.label)"
                    :formatter="column.formatter"
                    sortable
                    :align="$rtl.isRTL ? 'right' : 'left'"
                  >
                  </el-table-column>

                  <el-table-column v-if="isMobile" width="38">
                    <template slot-scope="props">
                      <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.lastheartbeatstatecode) + ')'"></span>
                    </template>
                  </el-table-column>

                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                    {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                    {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                    {{ $t('pagination.4') }}
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  :type="colorUnification"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </tab-pane>

            <!-- Profiles tag tab -->
            <tab-pane :label="$t('sidebar.confProfile')">
              <div>
                <div
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <el-select
                    :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                    v-model="pagination2.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      :class="['select-'+ colorUnification]"
                      v-for="item in pagination2.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>

                  <base-input>
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      :placeholder="$t('profileAction.search')"
                      v-model="searchQuery2"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </base-input>
                </div>
                <el-table :data="queriedData2" @expand-change="expandChange" v-loading="loading2" :empty-text="loading2 ? $t('dataTable.loading') : $t('dataTable.noData')">
                  <el-table-column type="expand" v-if="isMobile" width="25">
                    <div slot-scope="props">
                      <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.statecode)"> {{props.row.statecode}} </span></p>
                      <p><b>{{ $t('dataTable.type') }}</b>: {{ props.row.typecode }}</p>
                    </div>
                  </el-table-column>

                  <!-- Expand only for Tags edit in full page view, for edit Tags in mobile need to go in Device card -->
                  <el-table-column type="expand" v-if="!isMobile" width="25">
                    <div slot-scope="props">
                      <div class="row" v-for="(tag, index) in tags" :key="index">
                        <div class="col-5">
                          <base-input
                            :disabled="!tags[index].edit"
                            type="text"
                            v-model="tag.key"
                          >
                          </base-input>
                        </div>
                        <div class="col-5">
                          <base-input
                            :disabled="!tags[index].edit"
                            type="text"
                            v-model="tag.value"
                          >
                          </base-input>
                        </div>
                        <div class="col-2" style="text-align: center;">
                          <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="citrus" @click="editTag(index)" v-if="!tags[index].edit">
                            <i class="tim-icons icon-pencil"></i>
                          </base-button>

                          <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="success" @click="saveTag(index, props.row.uid)" v-if="tags[index].edit">
                            <i class="tim-icons icon-check-2"></i>
                          </base-button>

                          <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="delTag(index, props.row.uid)" v-if="tags[index].edit">
                            <i class="tim-icons icon-trash-simple"></i>
                          </base-button>
                        </div>
                      </div>
                      <div class="row">
                        <base-button round class="animation-on-hover  mx-3" :type="colorUnification" @click="addTag()">{{ $t('dataTable.add') }}</base-button>
                      </div>
                    </div>
                  </el-table-column>
                  
                  <el-table-column
                    v-for="column in isMobile ? tableColumns2.slice(0, 1) : tableColumns2"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :prop="column.prop"
                    :label="$t(column.label)"
                    :formatter="column.formatter"
                    sortable
                    :align="$rtl.isRTL ? 'right' : 'left'"
                  >
                  </el-table-column>

                  <el-table-column v-if="isMobile" width="38">
                    <template slot-scope="props">
                      <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.statecode) + ')'"></span>
                    </template>
                  </el-table-column>

                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from2 + 1) }}
                    {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to2) }}
                    {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total2) }}
                    {{ $t('pagination.4') }}
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  :type="colorUnification"
                  v-model="pagination2.currentPage"
                  :per-page="pagination2.perPage"
                  :total="total2"
                >
                </base-pagination>
              </div>
            </tab-pane>

            <!-- Groups tag tab -->
            <tab-pane :label="$t('sidebar.groups')">
            </tab-pane>

            <!-- Playlists tag tab -->
            <tab-pane :label="$t('sidebar.playlists')">
            </tab-pane> 
          </tabs> 
        </card>
      </div>
    </div>

    <!-- Scroll to top component -->
    <ScrollToTop></ScrollToTop>
    
    <!--Modal for Upload JSON-->
    <modal
      :show.sync="modals.miniUploadJSON"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div class="modal-profile" style="margin: auto;">
        <i class="tim-icons icon-cloud-upload-94" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white" v-if="this.files.length == 0">{{ $t('modal.titleUpl') }}</h4>
      <h4 align="center" style="color: white" v-else>{{ $t('modal.titleUplAlt') }} {{ this.files.length }}</h4>

      <input type="file" id="filesJSON" accept=".json" ref="filesJSON" style="display: none" multiple v-on:change="handleFilesUploadJSON()">

      <base-button style="margin:auto; display:block" round icon type="default" v-on:click="addFilesJSON()" v-if="uploading == 0">
          <i class="tim-icons icon-attach-87"></i>
      </base-button>
      

      <div style="height: 20px; margin: 0 20px;" v-else>
        <base-progress :value="uploading"></base-progress>
      </div>

      <h5 align="center" style="color: white" v-if="uploading == 100">{{ $t('modal.titleUplWait') }}</h5>

      <div>
        <base-button 
          type="neutral"
          link
          :disabled="this.files.length == 0 || uploading > 0 ? true : false"
          @click="submitFilesJSON"
          >{{ $t('modal.confirmUpl') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniUploadJSON = false, files = '', $refs.filesJSON.value = null"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, TabPane, Tabs, Modal, BaseProgress } from 'src/components';
import Fuse from 'fuse.js';
import { search, compare } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";

export default {
  components: {
    BaseProgress,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    TabPane,
    Tabs,
    ScrollToTop
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    //Get % of uploading
    uploading() {
      return this.$store.getters.uploading
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    queriedData2() {
      let result = this.tableData2;
      if (this.searchedData2.length > 0) {
        result = this.searchedData2;
      }
      return result.slice(this.from2, this.to2);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.tableData2.length;
    }
  },
  data() {
    return {
      loading: true,
      loading2: true,
      loading4: true,
      tags: [],
      activeTag: '',
      tableData: [],
      searchedData: [],
      searchQuery: '',
      tableData2: [],
      searchedData2: [],
      searchQuery2: '',
      files: '',
      fuseSearch: null,
      fuseSearch2: null,
      modals: {
        miniUploadJSON: false
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      pagination2: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 30,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'/equipments/devicesOne/' + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'lastheartbeatstatecode',
          label: 'dataTable.status',
          minWidth: 20,
          formatter: (row, col, cellValue, index) => {           
            switch (cellValue.toString()) {
              case "online":
                return <span slot="header" class="badge badge-pill badge-success"> {cellValue} </span>;
                break;
              case "linklost":
                return <span slot="header" class="badge badge-pill badge-citrus"> {cellValue} </span>;
                break;
              case "linkdown":
                return <span slot="header" class="badge badge-pill badge-danger"> {cellValue} </span>;
                break;
              case "neverup":
                return <span slot="header" class="badge badge-pill badge-info"> {cellValue} </span>;
                break;
            }
          }
        },
        {
          prop: 'typeCode',
          label: 'dataTable.type',
          minWidth: 20
        },
      ],
      tableColumns2: [
       {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 50,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'/security/profileOne/' + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'statecode',
          label: 'dataTable.status',
          minWidth: 20,
          formatter: (row, col, cellValue, index) => {           
          if (cellValue==='ACTIVE') {
            return <span slot="header" class="badge badge-pill badge-success"> {cellValue} </span>
          } else
            return <span slot="header" class="badge badge-pill badge-citrus"> {cellValue} </span>
          }
        },
        {
          prop: 'typecode',
          label: 'dataTable.type',
          minWidth: 20
        }
      ]
    };
  },
  methods: {
    // Edit Device`s tags
    async editTag(index) {
      let temp = this.tags[index];
      temp.edit = !temp.edit;

      this.tags.splice(index, 1, temp)
    },

    // Add Device`s tags
    async addTag() {
      this.tags.push({"uid":"","key":"","value":"","scope":"system","edit":true});
    },

    // Save Device`s tags
    async saveTag(index, deviceUID) {
      let temp = this.tags[index];
      temp.edit = !temp.edit;

      this.tags.splice(index, 1, temp);

      const Data = {
        'uid': deviceUID,
        'contentType': this.activeTag,
        'formData': {
          "uid": this.tags[index].uid,
          "key": this.tags[index].key,
          "value": this.tags[index].value,
          "scope": this.tags[index].scope
        }
      };

      if (this.tags[index].uid === '') {
        const answer = await this.$store.dispatch('addTag', Data);

        this.$notify({
          type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
          message: this.$t('notifications.crtConfirmeTag' + answer.statusCode.toString()),
          icon: 'tim-icons icon-bell-55'
        });

      } else {
        const answer = await this.$store.dispatch('editTag', Data);
        
        this.$notify({
          type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
          message: this.$t('notifications.renConfirmeTag' + answer.statusCode.toString()),
          icon: 'tim-icons icon-bell-55'
        });
      }

      // Make transfer of Object to expand function like it was when click
      let uid = {
          'uid': deviceUID
        };
      await this.expandChange(uid);
    },

    // Delete Device`s tags
    async delTag(index, deviceUID) {
      let temp = this.tags[index];
      temp.edit = !temp.edit;

      this.tags.splice(index, 1, temp);

      const Data = {
        'uid': deviceUID,
        'contentType': this.activeTag,
        'formData': {
          "uid": this.tags[index].uid
        }
      };
      if (this.tags[index].uid === '') {
        this.tags.splice(index, 1);

        this.$notify({
          type: 'success',
          message: this.$t('notifications.delConfirmeTag200'),
          icon: 'tim-icons icon-bell-55'
        });   
      } else {
         const answer = await this.$store.dispatch('delTag', Data);
        
        this.$notify({
          type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
          message: this.$t('notifications.delConfirmeTag' + answer.statusCode.toString()),
          icon: 'tim-icons icon-bell-55'
        });

        // Make transfer of Object to expand function like it was when click
        let uid = {
          'uid': deviceUID
        };
        await this.expandChange(uid);
      }
    },

    // Action when cell expand in the table
    async expandChange(row) {
      this.loading4 = true;

      // Find curent active tab
      this.activeTag = this.$refs.childComponent.tabs.findIndex(element => element.active == true) === 0 ? 'playpoint' : 'profile';

      const Data = {
        'uid': row.uid,
        'contentType': this.activeTag
      };

      this.tags = await this.$store.dispatch('fetchTag', Data);
      
      this.tags.forEach(element => {
        element['edit'] = false;
      });

      this.tags.sort(compare);

      this.loading4 = false;
    },

    // Select colors for status data in mobile view
    stateColor(cellValue) {
      switch (cellValue.toString()) {
        case "online":
          return "success";
          break;
        case "linklost":
          return "citrus";
          break;
        case "linkdown":
          return "danger";
          break;
        case "neverup":
          return "info";
          break;
        case "PREPARE":
          return "citrus";
          break;
        case "ACTIVE":
          return "success";
          break;
      }
    },

    // Fetch device list
    async fetchDevices() {
      this.loading = true;

      this.tableData = await this.$store.dispatch('fetchDev');

      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name', 'lastheartbeatstatecode'],
        threshold: 0.1
      });

      this.loading = false;
    },

    // Fetch Profiles list
    async fetchProfiles() {
      this.loading2 = true;

      this.tableData2 = await this.$store.dispatch('fetchProfile');

      // Fuse search initialization.
      this.fuseSearch2 = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name'],
        threshold: 0.1
      });

      this.loading2 = false;
    },

    //Replace the Input by button click
    addFilesJSON(){
      this.$refs.filesJSON.click();
    },

    //Save selected files to local variable
    handleFilesUploadJSON(){
      this.files = this.$refs.filesJSON.files;
    },
    
    //Send JSON files to the server
    async submitFilesJSON(){
      const readUploadedFileAsText = (inputFile) => {
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
          temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
          };

          temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
          };
          temporaryFileReader.readAsText(inputFile);
        });
      };
      
      for( var i = 0; i < this.files.length; i++ ){
        const formData = JSON.parse(await readUploadedFileAsText(this.files[i])) 
        
        const answer = await this.$store.dispatch('importTag', formData);
        const error = answer.statusCode.toString() === '912' ? answer.headers[0].Value.toString() : '';

        this.$notify({
            type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
            message: this.$t('notifications.uplConfirmeTag'+ answer.statusCode.toString()) + error,
            icon: 'tim-icons icon-bell-55'
        });

        await this.$store.commit('setUpload', 0);
      }

      this.files = '';
      this.$refs.filesJSON.value = null;
      this.modals.miniUploadJSON = false;

      await this.fetchDevices();
    }
  },
  async mounted() {
    // Fetch data from server by AXIOS - JSON.
    await this.fetchDevices();

    // Fetch data from server by AXIOS - JSON.
    await this.fetchProfiles();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query*/
     
    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name', 'lastheartbeatstatecode');
    },
    searchQuery2(value) {
      this.searchedData2 = search(value, this.tableData2, this.fuseSearch2, 'name', 'lastheartbeatstatecode');
    }
  }
};
</script>
<style>
</style>
