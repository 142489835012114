<template>
  <div>
    <div class="row">
      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('buttons.action') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL" :disabled="loading">
          <a class="dropdown-item" @click="editProfile" v-if="isEdit">{{ $t('buttons.activate') }}</a>
          <a class="dropdown-item" @click="editProfile" v-if="!isEdit && !loading">{{ $t('buttons.edit') }}</a>
          <div class="dropdown-divider" v-if="isEdit"></div>
          <a class="dropdown-item" @click="modals.miniRename = true" v-if="isEdit">{{ $t('buttons.rename') }}</a>
        </base-dropdown>
      </div>
      
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDel = true" v-if="isEdit && !isMobile" :disabled="true">{{ $t('buttons.delete') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDel = true" v-if="isMobile" :disabled="true">
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </div>
    
    <div class="row mt-2">
      <div class="col-12">    
        <card v-loading="loading">
          <h4 class="card-title">{{ record.name }}
            <span slot="header" :class="[isEdit ? 'badge badge-pill badge-citrus' : 'badge badge-pill badge-success']">
              {{ record.statecode }}
            </span>
          </h4>
          
          <small class="text-muted"><b>{{ $t('dataTable.description') }}</b>
            {{ record.description }}
          </small>

          <hr color="grey" border="none">
          
          <div  class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="row">
                <div class="col-4"><h5>{{ $t('dataTable.key') }}:</h5></div>
                <div class="col" ><h5> {{ this.record.filterKey }} </h5></div>
              </div>

              <div class="row">
                <div class="col-4"><h5>{{ $t('dataTable.value') }}:</h5></div>
                <div class="col" ><h5> {{ this.record.filterValue }} </h5></div>
              </div>

              <div class="row">
                <div class="col-4"><h5>{{ $t('dataTable.type') }}:</h5></div>
                <div class="col" ><h5> {{ this.record.typecode }} </h5></div>
              </div>
            </div>
          </div>
        </card> 
      </div>
    </div>

    <!-- Scroll to top component -->
    <ScrollToTop></ScrollToTop>

    <!--Modal for Delete -->
    <modal
      :show.sync="modals.miniDel"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-bell-55" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDel') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="deleteGroup(), modals.miniDel = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDel = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    

    <!--Modal for Rename -->
    <modal
      :show.sync="modals.miniRename"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">
          
        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-pencil" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.renameTitle') }}</h4>
        
        <div class="col">
          <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.key') }}</label>
          <div>
            <ValidationProvider
              :rules="{ required: true, min: 3, max: 50}"
              v-slot="{ valid }"
              :bails="false" 
            >
              <input
                class="form-control modal-inputs"
                name="valueGrName" type="text"
                v-model="valueName"
              >
              <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
              >
                {{ $t('validation.name') }}
              </span>
            </ValidationProvider>
          </div>
          
          <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.value') }}</label>
          <ValidationProvider
            :rules="{ required: true, min: 3, max: 150}"
            v-slot="{ valid }"
            :bails="false" 
          >
            <input
              class="form-control modal-inputs"
              name="valueDescr" type="text"
              v-model="valueDescr"
            >
            <span
              v-if="!valid"
              style="color: #000080; font-size: 12px;" 
            >
              {{ $t('validation.description') }}
            </span>
          </ValidationProvider>

          <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.type') }}</label>
          <ValidationProvider
            :rules="{ required: true}"
            v-slot="{ valid }"
            :bails="false" 
          >
            <el-select
              v-model="valueType"
              :value="valueType"
              filterable
              class="select-default modal-inputs"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.code"
                :value="item.id">
              </el-option>
            </el-select>
            <span
              v-if="!valid"
              style="color: #000080; font-size: 12px;" 
            >
              {{ $t('validation.required') }}
            </span>
          </ValidationProvider>
        </div>   

        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="renameProfile(), modals.miniRename = false"
            >{{ $t('modal.confirmRename') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniRename = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
            
      </ValidationObserver>
    </modal>

  </div>
</template>

<script>
import { Modal} from 'src/components';
import { Select, Option } from 'element-ui';
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import ScrollToTop from "../Layout/ScrollToTop.vue";


extend('required', required);
extend('min', min);
extend('max', max);


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    ScrollToTop
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    }
  },
  data() {
    return {
      componentKey: 0,
      dateRange: '',
      loading: true,
      loading2: true,
      isEdit: false,
      record: [],
      typeList: [],
      valueName: null,
      valueDescr: null,
      valueType: null,
      modals: {
        miniDel: false,
        miniRename: false
      }
    };
  },
  methods: {
    // Select colors for status data in mobile view
    stateColor(cellValue) {
      switch (cellValue.toString()) {
        case "online":
          return "success";
          break;
        case "linklost":
          return "citrus";
          break;
        case "linkdown":
          return "danger";
          break;
        case "neverup":
          return "info";
          break;
      }
    },

    // Fetch Group by ID for head of page
    async fetchProfileById() {
      this.loading = true;

      this.record = await this.$store.dispatch('fetchProfileById', this.$route.params.id);
      this.isEdit = this.record.statecode === 'ACTIVE' ? false : true;
      this.valueName = this.record.filterKey;
      this.valueDescr = this.record.filterValue;
      this.valueType = this.record.typeid;

      this.loading = false;
    },

    // Fetch types list for profile
    async fetchTypeList() {
      this.loading2 = true;

      this.typeList = await this.$store.dispatch('fetchListTypes');

      this.loading2 = false;
    },

    //Rename Profile
    async renameProfile(){
      const Data = {
        'uid': this.record.uid,
        'name': this.record.name,
        'stateid': this.record.stateid,
        'typeid': this.record.typeid,
        'description': this.record.description,
        'filterKey': this.valueName,
        'filterValue': this.valueDescr
      };

      console.log(Data)
      const answer = await this.$store.dispatch('renameProfile', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.renConfirmePr' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchProfileById()
    },

    // Status change
    async editProfile() {
      const answer = await this.$store.dispatch('editProfile', this.record.uid)
      
      this.$notify({
        type: answer.toString() === '1' ? 'danger' : 'success',
        message: this.$t('notifications.editConfirmePr'+ answer.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchProfileById();
    },

    //Delete Profile
    async deleteProfile() {
      const Data = {
        'name': this.valueName,
        'uid': this.record.uid
      }
    
      const answer = await this.$store.dispatch('deleteProfile', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.delConfirmeGr'+ answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      this.$router.push('/security/profile')  
    }
    
  },
  async mounted() {
    // Fetch Profile by ID for head of page
    await this.fetchProfileById();
    
    // Fetch type list for profile
    await this.fetchTypeList()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

  }
};
</script>
<style>
</style>
