<template>
  <card>
    <h4 slot="header" class="title">{{ $t('profile.editProfile') }}</h4>
      <div class="row">
        <div class="col-md-3">
          <base-input
            type="text"
            :label="$t('profile.firstName')"
            v-model="firstName"
          >
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input
            type="text"
            :label="$t('profile.lastName')"
            v-model="lastName"
          >
          </base-input>
        </div>
        <div class="col-md-2">
          <base-input
            disabled
            type="text"
            :label="$t('profile.rights')"
            v-model="role"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input
            disabled
            type="email"
            :label="$t('profile.contactEmail')"
            v-model="email"
          >
          </base-input>
        </div>
      </div>

      <base-button round @click="updateProfile" :type="colorUnification" class="btn-fill">
        {{ $t('buttons.save') }}
      </base-button>
  </card>
</template>
<script>
export default {
  data() {
    return {
      email: JSON.parse(localStorage.getItem('user')).username,
      firstName: JSON.parse(localStorage.getItem('personalization')).firstName,
      lastName: JSON.parse(localStorage.getItem('personalization')).lastName,
      role: JSON.parse(localStorage.getItem('user')).role,
      uid: JSON.parse(localStorage.getItem('user')).uid
    };
  },
  methods: {
    async updateProfile() {
      const Data = {
        "uid": this.uid,
        "firstName": this.firstName,
        "lastName": this.lastName,
        "color": this.colorUnification,
        "menuType": JSON.parse(localStorage.getItem('personalization')).menuType.toString(),
        "mode": JSON.parse(localStorage.getItem('personalization')).mode.toString(),
        "language": this.$i18n.locale
      }
                    
      const answer = await this.$store.dispatch('personSave', Data);
      
      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.personal' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

    }
  },
  computed: {
    colorUnification() {
      return this.$store.getters.colorUnification
    }
  }
};
</script>
<style></style>
