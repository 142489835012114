<template>
  <div>
    <div class="row">
      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('buttons.action') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL" :disabled="loading">
          <a class="dropdown-item" @click="editPlaylist" v-if="isEdit">{{ $t('buttons.activate') }}</a>
          <a class="dropdown-item" @click="editPlaylist" v-if="!isEdit && !loading">{{ $t('buttons.edit') }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="modals.miniRename = true" v-if="isEdit" >{{ $t('buttons.rename') }}</a>
          <a class="dropdown-item" @click="Copy">{{ $t('buttons.copy') }}</a>
          <a class="dropdown-item" @click="saveJSON">{{ $t('buttons.downloadJSON') }}</a>
        </base-dropdown>
      </div>

      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('sidebar.reports') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL">
          <a class="dropdown-item" @click="modals.miniRepR1 = true">{{ $t('buttons.reportR1') }}</a>
        </base-dropdown>
      </div>

      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDel = true" v-if="isEdit && !isMobile">{{ $t('buttons.delete') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDel = true" v-if="isEdit && isMobile">
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <!-- Head data #record-->   
        <card v-loading="loading">
          <h4 class="card-title">{{ record.name }}
            <span slot="header" :class="[isEdit ? 'badge badge-pill badge-citrus' : 'badge badge-pill badge-success']">
              {{ record.stateCode }}
            </span>
          </h4>
    
          
          <small class="text-muted"><b>{{ $t('playlist.description') }}</b>
            {{ record.description }}
          </small> 
          
          <hr color="grey" border="none">
          
          <div  class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="row">
                <div class="col"><h5>{{ $t('dataTable.type') }}:</h5></div>
                <div class="col" ><h5> {{ this.record.typeCode }} </h5></div>
              </div>
              <div class="row">
                <div class="col"><h5>{{ $t('dataTable.version') }}:</h5></div>
                <div class="col" ><h5> {{ this.record.version }} </h5></div>
              </div>
            </div>
          </div>
        </card>
        
        <!-- Base table #queriedData3-->
        <card card-body-classes="table-full-width" v-if="!isEdit">
          <h4 slot="header" class="card-title">{{ $t('dataTable.plContent') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData" v-loading="loading2" :empty-text="loading2 ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <audio controls v-if="isAudio" class="audio" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>

                  <video controls v-if="!isAudio" class="video" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>
                </div>
              </el-table-column>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>

              <!--Start play time for smart Playlist -->
              <el-table-column :min-width="110" :label="$t('dataTable.time')" v-if="isSmart">
                <div slot-scope="props">
                  {{ props.row.start }}
                </div>
              </el-table-column>

              <!--Audio -->
              <el-table-column :min-width="45" :label="$t('dataTable.listen')" v-if="isAudio && !isMobile">
                <div slot-scope="props">
                  <audio controls class="audio" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>
                </div>
              </el-table-column>

              <!--Video -->
              <el-table-column :min-width="45" :label="$t('dataTable.watch')" v-if="!isAudio && !isMobile">
                <div slot-scope="props">
                  <video controls class="video" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
        
        <!-- Suggestions for edits #queriedData-->
        <card card-body-classes="table-full-width" v-else>
          <tabs :type="colorUnification">

            <!-- Table Curent playlist for edit #queriedData-->
            <tab-pane :label="$t('dataTable.plContent')">
              <div>
                
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-select
                      :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                      :type="colorUnification"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        :class="['select-'+ colorUnification]"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>

                    <base-input>
                      <el-input
                        type="search"
                        class="mb-3 search-input"
                        clearable
                        prefix-icon="el-icon-search"
                        :placeholder="$t('profileAction.search')"
                        v-model="searchQuery"
                        aria-controls="datatables"
                      >
                      </el-input>
                    </base-input>
                  </div>
                  <el-table :data="queriedData" v-loading="loading2" :empty-text="loading2 ? $t('dataTable.loading') : $t('dataTable.noData')">
                    <el-table-column type="expand" v-if="isMobile" width="25">
                      <div slot-scope="props">
                        <p><b>{{ $t('dataTable.№') }}</b>: {{ props.row.order/10 }}</p>
                        <p v-if="isSmart"><b>{{ $t('dataTable.time') }}</b>: {{ props.row.start }}</p>
                        <p><b>{{ $t('dataTable.position') }}</b>:
                          <el-dropdown>
                            <base-button 
                              round
                              type="info"
                              size="sm"
                              icon
                            >
                              <i class="tim-icons icon-refresh-02"></i>
                            </base-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item 
                                @click.native="position(props.row.uid, props.row.linkid, 'begin', props.row.name)"
                              > {{ $t('dataTable.positionBegin') }} </el-dropdown-item>
                              <el-dropdown-item
                                @click.native="position(props.row.uid, props.row.linkid, 'up10', props.row.name)"
                              > {{ $t('dataTable.positionUp10') }} </el-dropdown-item>
                              <el-dropdown-item
                                @click.native="position(props.row.uid, props.row.linkid, 'up', props.row.name)"
                              >{{ $t('dataTable.positionUp') }} </el-dropdown-item>
                              <el-dropdown-item
                                @click.native="position(props.row.uid, props.row.linkid, 'down', props.row.name)"
                              >{{ $t('dataTable.positionDown') }} </el-dropdown-item>
                              <el-dropdown-item
                                @click.native="position(props.row.uid, props.row.linkid, 'down10', props.row.name)"
                              >{{ $t('dataTable.positionDown10') }} </el-dropdown-item>
                              <el-dropdown-item
                                @click.native="position(props.row.uid, props.row.linkid, 'end', props.row.name)"
                              >{{ $t('dataTable.positionEnd') }} </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </p>

                        <audio controls v-if="isAudio" class="audio" controlslist="nodownload" :key="props.row.uid">
                          <source :src="props.row.url" type="audio/mp3" >
                        </audio>

                        <video controls v-if="!isAudio" class="video" controlslist="nodownload" :key="props.row.uid">
                          <source :src="props.row.url" type="video/mp4" >
                        </video>
                      </div>
                    </el-table-column>
                    
                    <el-table-column
                      v-for="column in isMobile ? tableColumns.slice(1, 2) : tableColumns"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="$t(column.label)"
                      :formatter="column.formatter"
                      sortable
                      :align="$rtl.isRTL ? 'right' : 'left'"
                    >
                    </el-table-column>

                    <!--Start play time for smart Playlist -->
                    <el-table-column :min-width="20" :label="$t('dataTable.time')" v-if="isSmart && !isMobile">
                      <div slot-scope="props">
                        {{ props.row.start }}
                      </div>
                    </el-table-column>
                    
                    <!--Audio -->
                    <el-table-column :min-width="45" :label="$t('dataTable.listen')" v-if="isAudio && !isMobile">
                      <div slot-scope="props">
                        <audio controls class="audio" controlslist="nodownload" :key="props.row.uid">
                          <source :src="props.row.url" type="audio/mp3" >
                        </audio>
                      </div>
                    </el-table-column>

                    <!--Video -->
                    <el-table-column :min-width="45" :label="$t('dataTable.watch')" v-if="!isAudio && !isMobile">
                      <div slot-scope="props">
                        <video controls class="video" controlslist="nodownload" :key="props.row.uid">
                          <source :src="props.row.url" type="video/mp4" >
                        </video>
                      </div>
                    </el-table-column>

                    <!--Action Position change-->
                    <el-table-column min-width="10" align="right" :label="$t('dataTable.position')" v-if="!isSmart && !isMobile">
                      <div slot-scope="props">
                        <el-dropdown>
                          <base-button 
                            round
                            type="info"
                            size="sm"
                            icon
                          >
                            <i class="tim-icons icon-refresh-02"></i>
                          </base-button>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item 
                              @click.native="position(props.row.uid, props.row.linkid, 'begin', props.row.name)"
                            > {{ $t('dataTable.positionBegin') }} </el-dropdown-item>
                            <el-dropdown-item
                              @click.native="position(props.row.uid, props.row.linkid, 'up10', props.row.name)"
                            > {{ $t('dataTable.positionUp10') }} </el-dropdown-item>
                            <el-dropdown-item
                              @click.native="position(props.row.uid, props.row.linkid, 'up', props.row.name)"
                            >{{ $t('dataTable.positionUp') }} </el-dropdown-item>
                            <el-dropdown-item
                              @click.native="position(props.row.uid, props.row.linkid, 'down', props.row.name)"
                            >{{ $t('dataTable.positionDown') }} </el-dropdown-item>
                            <el-dropdown-item
                              @click.native="position(props.row.uid, props.row.linkid, 'down10', props.row.name)"
                            >{{ $t('dataTable.positionDown10') }} </el-dropdown-item>
                            <el-dropdown-item
                              @click.native="position(props.row.uid, props.row.linkid, 'end', props.row.name)"
                            >{{ $t('dataTable.positionEnd') }} </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </el-table-column>

                    <!--Action Delete-->
                    <el-table-column :min-width="isMobile ? 25 : 10" align="right" :label="$t('dataTable.del')" v-if="!isSmart">
                      <div slot-scope="props">
                        <base-button
                          @click.native="delFromPlaylist(props.row.uid, props.row.linkid, props.row.name)"
                          round
                          type="danger"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-simple-remove"></i>
                        </base-button>
                      </div>
                    </el-table-column>

                    <!--Action Edit smart playlist-->
                    <el-table-column :min-width="isMobile ? 25 : 10" align="right" :label="$t('dataTable.position')" v-if="isSmart">
                      <div slot-scope="props">
                        <base-button
                          @click.native="smartEdit()"
                          round
                          type="danger"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-settings"></i>
                        </base-button>
                      </div>
                    </el-table-column>

                  </el-table>
                </div>
                <div
                  slot="footer"
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <div class="">
                    <p class="card-category">
                      {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                      {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                      {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                      {{ $t('pagination.4') }}
                    </p>
                  </div>
                  <base-pagination
                    class="pagination-no-border"
                    :type="colorUnification"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                  >
                  </base-pagination>
                </div>

              </div>
            </tab-pane>

            <!-- Table Ads library #queriedData2-->
            <tab-pane :label="$t(isAudio ? 'dataTable.adsLibrary' : 'dataTable.vidAdsLibrary')" v-if="!isSmart">
              <div>
                
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-select
                      :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                      :type="colorUnification"
                      v-model="pagination2.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        :class="['select-'+ colorUnification]"
                        v-for="item in pagination2.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>

                    <base-input>
                      <el-input
                        type="search"
                        class="mb-3 search-input"
                        clearable
                        prefix-icon="el-icon-search"
                        :placeholder="$t('profileAction.search')"
                        v-model="searchQuery2"
                        aria-controls="datatables"
                      >
                      </el-input>
                    </base-input>
                  </div>
                  <el-table :data="queriedData2" v-loading="loading3" :empty-text="loading3 ? $t('dataTable.loading') : $t('dataTable.noData')">
                    <el-table-column type="expand" v-if="isMobile" width="25">
                      <div slot-scope="props">
                        <audio controls v-if="isAudio" class="audio" controlslist="nodownload" :key="props.row.uid">
                          <source :src="props.row.url" type="audio/mp3" >
                        </audio>

                        <video controls v-if="!isAudio" class="video" controlslist="nodownload" :key="props.row.uid">
                          <source :src="props.row.url" type="video/mp4" >
                        </video>
                      </div>
                    </el-table-column>
                    
                    <el-table-column
                      v-for="column in tableColumns2"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="$t(column.label)"
                      :formatter="column.formatter"
                      sortable
                      :align="$rtl.isRTL ? 'right' : 'left'"
                    >
                    </el-table-column>

                    <!--Audio -->
                    <el-table-column :min-width="40" :label="$t('dataTable.listen')" v-if="isAudio && !isMobile">
                      <div slot-scope="props">
                        <audio controls class="audio" controlslist="nodownload">
                          <source :src="props.row.url" type="audio/mp3" >
                        </audio>
                      </div>
                    </el-table-column>

                    <!--Video -->
                    <el-table-column :min-width="40" :label="$t('dataTable.watch')" v-if="!isAudio && !isMobile">
                      <div slot-scope="props">
                        <video controls class="video" controlslist="nodownload">
                          <source :src="props.row.url" type="video/mp4" >
                        </video>
                      </div>
                    </el-table-column>

                    <!--Action Add-->
                    <el-table-column :min-width="isMobile ? 30 : 10" align="right" :label="$t('dataTable.add')">
                      <div slot-scope="props">
                        <base-button
                          @click.native="addToPlaylist(props.row.uid, props.row.name)"
                          round
                          :type="colorUnification"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-simple-add"></i>
                        </base-button>
                      </div>
                    </el-table-column>

                  </el-table>
                </div>
                <div
                  slot="footer"
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <div class="">
                    <p class="card-category">
                      {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from2 + 1) }}
                      {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to2) }}
                      {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total2) }}
                      {{ $t('pagination.4') }}
                    </p>
                  </div>
                  <base-pagination
                    class="pagination-no-border"
                    :type="colorUnification"
                    v-model="pagination2.currentPage"
                    :per-page="pagination2.perPage"
                    :total="total2"
                  >
                  </base-pagination>
                </div>

              </div>
            </tab-pane>

            <!-- Table Melodies library #queriedData3-->
            <tab-pane :label="$t(isAudio ? 'dataTable.melLibrary' : 'dataTable.vidLibrary')" v-if="!isSmart">
              <div>
                
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-select
                      :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                      :type="colorUnification"
                      v-model="pagination3.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        :class="['select-'+ colorUnification]"
                        v-for="item in pagination3.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>

                    <base-input>
                      <el-input
                        type="search"
                        class="mb-3 search-input"
                        clearable
                        prefix-icon="el-icon-search"
                        :placeholder="$t('profileAction.search')"
                        v-model="searchQuery3"
                        aria-controls="datatables"
                      >
                      </el-input>
                    </base-input>
                  </div>
                  <el-table :data="queriedData3" v-loading="loading4" :empty-text="loading4 ? $t('dataTable.loading') : $t('dataTable.noData')">
                    <el-table-column type="expand" v-if="isMobile" width="25">
                      <div slot-scope="props">
                        <audio controls v-if="isAudio" class="audio" controlslist="nodownload" :key="props.row.uid">
                          <source :src="props.row.url" type="audio/mp3" >
                        </audio>

                        <video controls v-if="!isAudio" class="video" controlslist="nodownload" :key="props.row.uid">
                          <source :src="props.row.url" type="video/mp4" >
                        </video>
                      </div>
                    </el-table-column>

                    <el-table-column
                      v-for="column in tableColumns3"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="$t(column.label)"
                      :formatter="column.formatter"
                      sortable
                      :align="$rtl.isRTL ? 'right' : 'left'"
                    >
                    </el-table-column>

                    <!--Audio -->
                    <el-table-column :min-width="40" :label="$t('dataTable.listen')" v-if="isAudio && !isMobile">
                      <div slot-scope="props">
                        <audio controls class="audio" controlslist="nodownload">
                          <source :src="props.row.url" type="audio/mp3" >
                        </audio>
                      </div>
                    </el-table-column>

                    <!--Video -->
                    <el-table-column :min-width="40" :label="$t('dataTable.watch')" v-if="!isAudio && !isMobile">
                      <div slot-scope="props">
                        <video controls class="video" controlslist="nodownload">
                          <source :src="props.row.url" type="video/mp4" >
                        </video>
                      </div>
                    </el-table-column>

                    <!--Action -->
                    <el-table-column :min-width="isMobile ? 30 : 10" align="right" :label="$t('dataTable.add')">
                      <div slot-scope="props">
                        <base-button
                          @click.native="addToPlaylist(props.row.uid, props.row.name)"
                          round
                          :type="colorUnification"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-simple-add"></i>
                        </base-button>
                      </div>
                    </el-table-column>
                    
                  </el-table>
                </div>
                <div
                  slot="footer"
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <div class="">
                    <p class="card-category">
                      {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from3 + 1) }}
                      {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to3) }}
                      {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total3) }}
                      {{ $t('pagination.4') }}
                    </p>
                  </div>
                  <base-pagination
                    class="pagination-no-border"
                    :type="colorUnification"
                    v-model="pagination3.currentPage"
                    :per-page="pagination3.perPage"
                    :total="total3"
                  >
                  </base-pagination>
                </div>

              </div>
            </tab-pane>
          </tabs>          
        </card>      
  
      </div>
    </div>

    <!-- Scroll to top component -->
    <ScrollToTop></ScrollToTop>

    <!--Modal for Delete -->
    <modal
      :show.sync="modals.miniDel"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-bell-55" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDel') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="deletePlaylist(), modals.miniDel = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDel = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for Rename -->
    <modal
      :show.sync="modals.miniRename"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">
          
        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-pencil" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.renameTitle') }}</h4>
        
        <div class="col">
          <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.name') }}</label>
          <div>
            <ValidationProvider
              :rules="{ required: true, min: 3, max: 50}"
              v-slot="{ valid }"
              :bails="false" 
            >    
              <input
                class="form-control modal-inputs"
                name="valueGrName" type="text"
                v-model="valuePlName"
              >
              <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
              >
                {{ $t('validation.name') }}
              </span>
            </ValidationProvider>
          </div>
          
          <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.description') }}</label>
          <ValidationProvider
            :rules="{ required: true, min: 3, max: 150}"
            v-slot="{ valid }"
            :bails="false" 
          >  
            <input
              class="form-control modal-inputs"
              name="valueDiscr" type="text"
              v-model="valueDescr"
            >
            <span
              v-if="!valid"
              style="color: #000080; font-size: 12px;" 
            >
              {{ $t('validation.description') }}
            </span>
          </ValidationProvider>
        </div>   

        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="renamePlaylist(), modals.miniRename = false"
            >{{ $t('modal.confirmRename') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniRename = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
            
      </ValidationObserver>
    </modal>

    <!--Modal for ReportR1 (reports with date interval)-->
    <modal
      :show.sync="modals.miniRepR1"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">

        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-chart-pie-36" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.statTitleDate') }}</h4>
      
        <ValidationProvider
          :rules="{ required: true}"
          v-slot="{ valid }"
          :bails="false" 
        >
          <el-date-picker
            :key="componentKey"
            value-format="yyyy.MM.dd"
            :picker-options="{firstDayOfWeek: 1, disabledDate: LimitDate}"
            v-model="dateRange"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('dataTable.intervalStart')"
            :end-placeholder="$t('dataTable.intervalFinish')"
          >
          </el-date-picker>
          <span
            v-if="!valid"
            style="color: #000080; font-size: 12px;" 
          >
            {{ $t('validation.required') }}
          </span>
        </ValidationProvider>
      
       
        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="reportR1(), modals.miniRepR1 = false"
            >{{ $t('modal.confirmStat') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="dateRange ='', modals.miniRepR1 = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
      </ValidationObserver>
    </modal>

  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Dropdown, DropdownItem, DropdownMenu, DatePicker } from 'element-ui';
import { BasePagination, TabPane, Tabs, Modal, BaseDropdown } from 'src/components';
import Fuse from 'fuse.js';
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import { search } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";
import locale from 'element-ui/lib/locale';
import ua from 'element-ui/lib/locale/lang/ua';
import de from 'element-ui/lib/locale/lang/de';
import ru from 'element-ui/lib/locale/lang/ru-RU';
import en from 'element-ui/lib/locale/lang/en';
import cn from 'element-ui/lib/locale/lang/zh-CN';
import ar from 'element-ui/lib/locale/lang/ar';
import es from 'element-ui/lib/locale/lang/es';
import fr from 'element-ui/lib/locale/lang/fr';
import pt from 'element-ui/lib/locale/lang/pt';


extend('required', required);
extend('min', min);
extend('max', max);


export default {
  components: {
    BaseDropdown,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    ValidationProvider,
    ValidationObserver,
    Modal,
    TabPane,
    Tabs,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ScrollToTop,
    [DatePicker.name]: DatePicker
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    //Compute curent language
    componentLanguage() {
      switch (this.$i18n.locale.toString()) {
        case "ru":
          return ru;
          break;
        case "en":
          return en;
          break;
        case "de":
          return de;
          break;
        case "ua":
          return ua;
          break;
        case "cn":
          return cn;
          break;
        case "ar":
          return ar;
          break;
        case "es":
          return es;
          break;
        case "fr":
          return fr;
          break;
        case "pt":
          return pt;
          break;
      }
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    queriedData2() {
      let result = this.tableData2;
      if (this.searchedData2.length > 0) {
        result = this.searchedData2;
      }
      return result.slice(this.from2, this.to2);
    },
    queriedData3() {
      let result = this.tableData3;
      if (this.searchedData3.length > 0) {
        result = this.searchedData3;
      }
      return result.slice(this.from3, this.to3);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    to3() {
      let highBound = this.from3 + this.pagination3.perPage;
      if (this.total3 < highBound) {
        highBound = this.total3;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    from3() {
      return this.pagination3.perPage * (this.pagination3.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.tableData2.length;
    },
    total3() {
      return this.searchedData3.length > 0
        ? this.searchedData3.length
        : this.tableData3.length;
    }
  },
  data() {
    return {
      componentKey: 0,
      dateRange: '',
      loading: true,
      loading2: true,
      loading3: true,
      loading4: true,
      isEdit: false,
      isAudio: false,
      isSmart: false,     
      tableData: [],
      tableData2: [],
      tableData3: [],
      searchedData: [],
      searchedData2: [],
      searchedData3: [],
      searchQuery: '',
      searchQuery2: '',
      searchQuery3: '',
      valuePlName: null,
      valueDescr: null,
      fuseSearch: null,
      fuseSearch2: null,
      fuseSearch3: null,
      modals: {
        miniDel: false,
        miniRename: false,
        miniRepR1: false
      },
      record: {
        state: {
          code: ''
      }
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
       pagination2: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      pagination3: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'order',
          label: 'dataTable.№',
          minWidth: 15,
          formatter: (row, col, cellValue, index) => {
            return cellValue/10
          }
        },
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 40,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={ (this.isAudio ? '/media/melodiesOne/' : '/media/videosOne/') + row.uid}> {cellValue} </router-link>
          }
        }
      ],
      tableColumns2: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 40,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={ (this.isAudio ? '/media/adsOne/' : '/media/videosAdsOne/') + row.uid}> {cellValue} </router-link>
          }
        }
      ],
      tableColumns3: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 40,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={ (this.isAudio ? '/media/melodiesOne/' : '/media/videosOne/') + row.uid}> {cellValue} </router-link>
          }
        }
      ]
    };
  },
  methods: {
    //Restricting the ability to select a date according to the datastore setting
    LimitDate(date) {
        return date > new Date() || date < (new Date() - 45 * 86400000)
    },

    //R1 report
    async reportR1(){
      const Data = {
        'trackuid': '-',
        'playpointuid': '-',
        'playlistuid': this.$route.params.id,
        'fromdate': this.dateRange[0],
        'todate': this.dateRange[1],
        'regionalTime': new Date().getTimezoneOffset()
      }
      
      this.$notify({
        type: 'info',
        message: this.$t('notifications.reportWait'),
        icon: 'tim-icons icon-bell-55'
      });

      await this.$store.dispatch('repR1date', Data);
      this.dateRange = '';

      this.$notify({
        type: 'success',
        message: this.$t('notifications.report'),
        icon: 'tim-icons icon-bell-55'
      })
    },
    
    // Fetch Playlist by ID for head of page
    async fetchPlaylistById() {
      this.loading = true;

      this.record = await this.$store.dispatch('fetchPlById', this.$route.params.id);
      this.isEdit = this.record.stateCode === 'ACTIVE' ? false : true;
      this.valuePlName = this.record.name;
      this.valueDescr = this.record.description;
      this.isAudio = this.record.typeCode.startsWith('AUDIO');
      this.isSmart = this.record.typeCode.endsWith('SMART');

      this.loading = false;
    },

    // Fetch Melodies for adding in Playlist
    async fetchMedia() {
      this.loading4 = true;
      const Data = {
        'contentType': this.isAudio ? 'audio' : 'video'
      }
      this.tableData3 = await this.$store.dispatch('fetchMedia', Data);

      // Fourth Fuse search initialization.
      this.fuseSearch3 = new Fuse(this.tableData3, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name'],
        threshold: 0.1
      });

      this.loading4 = false;
    },

    // Fetch Ads for adding in Playlist
    async fetchMediaAds() {
      this.loading3 = true;
      const Data = {
        'contentType': this.isAudio ? 'audio-com' : 'video-com'
      }
      this.tableData2 = await this.$store.dispatch('fetchMedia', Data);

      // Second Fuse search initialization.
      this.fuseSearch2 = new Fuse(this.tableData2, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name'],
        threshold: 0.1
      });

      this.loading3 = false;
    },

    // Fetch Playlist content for edit and main page
    async fetchPlaylistPage() {
      this.loading2 = true
      this.tableData = []
      this.tableData = await this.$store.dispatch('fetchPlContent', this.$route.params.id)
      this.tableData = this.tableData.tracks

      // First Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['order', 'name'],
        threshold: 0.1
      });

      this.loading2 = false
    },

    //Rename Playlist
    async renamePlaylist(){
      const Data = {
          'name': this.valuePlName,
          'uid': this.$route.params.id,
          'description': this.valueDescr
      }
      
      const answer = await this.$store.dispatch('renamePl', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.renConfirmePl' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      this.fetchPlaylistById()
    },

    // Status change
    async editPlaylist() {
      const answer = await this.$store.dispatch('editPl', this.$route.params.id);
      
      this.$notify({
        type: answer.toString() === '1' ? 'danger' : 'success',
        message: this.$t('notifications.editConfirmePl'+ answer.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPlaylistById()
      await this.fetchPlaylistPage()
    },

    // Delete media from Playlist
    async delFromPlaylist(value, value2, value3) {
      const Data = {
          'track': value,
          'playlist': this.$route.params.id,
          'position': value2
      };
    
      const answer = await this.$store.dispatch('deleteFromPl', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.outConfirmePl' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      this.searchQuery = '';
      await this.fetchPlaylistPage();
    },

    // Add media to Playlist
    async addToPlaylist(value, value2) {
      const Data = {
        'track': value,
        'playlist': this.$route.params.id
      };
    
      const answer = await this.$store.dispatch('addToPl', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.inConfirmePl' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPlaylistPage();
    },

    //Copy Playlist
    async Copy() {
      await this.$store.dispatch('CopyPl', this.$route.params.id);
            
      this.$notify({
        type: 'success',
        message: this.$t('notifications.cpConfirme'),
        icon: 'tim-icons icon-bell-55'
      });

      this.$store.commit('setPlFilter', '[COPY');
      this.$router.push('/media/playlists');
    },

    //Delete Playlist
    async deletePlaylist() {
      const Data = {
        'uid': this.$route.params.id
      }
    
      const answer = await this.$store.dispatch('deletePl', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.delConfirmePl' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      this.$router.push('/media/playlists')   
    },

    // Change track position in playlist
    async position(value, value2, value3) {
      const Data = {
        'track': value,
        'playlist': this.$route.params.id,
        'position': value2,
        'mode': value3
      };
    
      const answer = await this.$store.dispatch('position', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.positionConfirmePl' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPlaylistPage();
    },

    // Change track position in Smart playlist
    async smartEdit() {

      const source = await this.$store.dispatch('sourceSmartPl', this.$route.params.id);

      // Data from Fisrt smart playlist page

      // Smart playlist name transfer
      this.$store.commit('setSmartPlUid', this.$route.params.id);
      this.$store.commit('setSmartPlName', this.record.name);
      this.$store.commit('setSmartPlDescr', this.record.description);

      // Set playlist type
      this.$store.commit('setPlType', this.record.typeCode);

      // Set ads and melodies period
      this.$store.commit('setMelTime', source.melTime);
      this.$store.commit('setAdsTime', source.adsTime);

      // Set work time for day
      // this.$store.commit('setWorkTime', source.);
      this.$store.commit('setStartDay', source.startDay);
      this.$store.commit('setEndDay', source.endDay);

      // Data from Second smart playlist page
      // Set ads list for play
      this.$store.commit('setAdsList', source.adsList);

      // Data from Third smart playlist page
      // Set melodies list for play
      this.$store.commit('setMelList', source.melList);

      // Need to fullfil vuex store with playlist data
      this.$router.push('/media/wizard');
    },

    // Change track position in playlist
    async saveJSON() {
      const Data = {
        'file': JSON.stringify(await this.$store.dispatch('fetchPlContent', this.$route.params.id)),
        'name': this.record.name
      };

      await this.$store.dispatch('saveJSON', Data);

      this.$notify({
        type: 'success',
        message: this.$t('notifications.jsonDownload'),
        icon: 'tim-icons icon-bell-55'
      })
    }
  },
  async mounted() {
    // Set start language for date picker
    locale.use(this.componentLanguage);

    // Fetch Playlist by ID for head of page (RECORD)
    await this.fetchPlaylistById()

    // Fetch Playlist consist for small table (DATATABLE)
    await this.fetchPlaylistPage()

    if (this.isEdit) {
      // Fetch Ads for adding in Playlist (DATATABLE2)
      await this.fetchMediaAds()

      // Fetch Melodies for adding in Playlist (DATATABLE3)
      await this.fetchMedia()
    }
  },
  watch: {
    //Change languge of date pick component, when locale changed
    componentLanguage() {
      locale.use(this.componentLanguage)
      this.componentKey += 1; //re-render the component for full locale change (day not changing, need to list day page for re-render)
    },

    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

    async isEdit() {
      if (this.isEdit) {
        // Fetch Ads for adding in Playlist (DATATABLE2)
        await this.fetchMediaAds()

        // Fetch Melodies for adding in Playlist (DATATABLE3)
        await this.fetchMedia()
      }
    },

    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'order', 'name');
    },
    searchQuery2(value) {
      this.searchedData2 = search(value, this.tableData2, this.fuseSearch2, 'name');
    },
    searchQuery3(value) {
      this.searchedData3 = search(value, this.tableData3, this.fuseSearch3, 'name');
    }
  }
};
</script>
<style>
</style>
