<template>
  <card class="card-user">
    <p class="card-text"></p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <a href="javascript:void(0)">
        <img class="avatar" src="@/assets/img/user-logo.jpg" alt="..." />
        <h5 class="title">{{ firstName + ' ' + lastName }}</h5>
      </a>
      <p class="description"><b>{{ record.subscription_Company }}</b></p>
      <small class="text-muted"><b>{{ $t('profile.subscription') }}</b>
        {{ new Date(record.subscription_EndDate).toLocaleString() }}
      </small>
      <br>
      <small class="text-muted"><b>{{ $t('device.version') }}</b>
        {{ version }}
      </small>
    </div>
  </card>
</template>
<script>
export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      firstName: JSON.parse(localStorage.getItem('personalization')).firstName,
      lastName: JSON.parse(localStorage.getItem('personalization')).lastName,
      record: []
    }
  },
  computed: {

  },
  methods: {
    // Fetch subscription data
    async subscription() {
      this.record = await this.$store.dispatch('subscription')
    }
  },
  async mounted() {
    // Fetch subscription data
    await this.subscription()
  }
}
</script>
<style></style>
