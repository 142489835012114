<template>
  <div>
    <div class="row mt-2">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ $t('sidebar.logs') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData" v-loading="loading" :empty-text="loading ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.lastheartbeatstatecode)"> {{props.row.lastheartbeatstatecode}} </span></p>
                  <p><b>{{ $t('dataTable.type') }}</b>: {{ props.row.typeCode }}</p>
                  <p><b>{{ $t('dataTable.version') }}</b>: {{ props.row.playpointrevision }}</p>
                  <div>
                    <base-button
                      @click.native="$router.push('heartBeat/' + props.row.uid)" 
                      round
                      type="primary"
                      size="sm"
                      icon
                      title="Heartbeat"
                    >
                      <i class="tim-icons icon-heart-2"></i>
                    </base-button>
                    <base-button
                      @click.native="$router.push('playTrack/' + props.row.uid)" 
                      round
                      type="info"
                      size="sm"
                      icon
                      title="PlayTrack"
                    >
                      <i class="tim-icons icon-volume-98"></i>
                    </base-button>
                    <base-button
                      @click.native="$router.push('debug/' + props.row.uid)" 
                      round
                      type="success"
                      size="sm"
                      icon
                      title="Debug"
                    >
                      <i class="tim-icons icon-settings"></i>
                    </base-button>
                    <base-button
                      @click.native="$router.push('playList/' + props.row.uid)" 
                      round
                      type="danger"
                      size="sm"
                      icon
                      title="PlayList"
                    >
                      <i class="tim-icons icon-paper"></i>
                    </base-button>
                    <base-button
                      @click.native="$router.push('UpdatesLog/' + props.row.uid)" 
                      round
                      type="citrus"
                      size="sm"
                      icon
                      title="Update"
                    >
                      <i class="tim-icons icon-spaceship"></i>
                    </base-button>
                </div>
                </div>
              </el-table-column>
              
              <el-table-column
                v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>

              <el-table-column v-if="isMobile" width="38">
                <template slot-scope="props">
                  <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.lastheartbeatstatecode) + ')'"></span>
                </template>
              </el-table-column>

              <el-table-column :min-width="30" align="right" :label="$t('dataTable.category')" v-if="!isMobile">
                <div slot-scope="props">
                  <base-button
                    @click.native="$router.push('heartBeat/' + props.row.uid)" 
                    round
                    type="primary"
                    size="sm"
                    icon
                    title="Heartbeat"
                  >
                    <i class="tim-icons icon-heart-2"></i>
                  </base-button>
                  <base-button
                    @click.native="$router.push('playTrack/' + props.row.uid)" 
                    round
                    type="info"
                    size="sm"
                    icon
                    title="PlayTrack"
                  >
                    <i class="tim-icons icon-volume-98"></i>
                  </base-button>
                  <base-button
                    @click.native="$router.push('debug/' + props.row.uid)" 
                    round
                    type="success"
                    size="sm"
                    icon
                    title="Debug"
                  >
                    <i class="tim-icons icon-settings"></i>
                  </base-button>
                  <base-button
                    @click.native="$router.push('playList/' + props.row.uid)" 
                    round
                    type="danger"
                    size="sm"
                    icon
                    title="PlayList"
                  >
                    <i class="tim-icons icon-paper"></i>
                  </base-button>
                  <base-button
                    @click.native="$router.push('UpdatesLog/' + props.row.uid)" 
                    round
                    type="citrus"
                    size="sm"
                    icon
                    title="Update"
                  >
                    <i class="tim-icons icon-spaceship"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import { search } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ScrollToTop
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      loading: true,
      tableData: [],
      searchedData: [],
      searchQuery: '',
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 30,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'/equipments/devicesOne/' + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'lastheartbeatstatecode',
          label: 'dataTable.status',
          minWidth: 20,
          formatter: (row, col, cellValue, index) => {           
            switch (cellValue.toString()) {
              case "online":
                return <span slot="header" class="badge badge-pill badge-success"> {cellValue} </span>;
                break;
              case "linklost":
                return <span slot="header" class="badge badge-pill badge-citrus"> {cellValue} </span>;
                break;
              case "linkdown":
                return <span slot="header" class="badge badge-pill badge-danger"> {cellValue} </span>;
                break;
              case "neverup":
                return <span slot="header" class="badge badge-pill badge-info"> {cellValue} </span>;
                break;
            }
          }
        },
        {
          prop: 'playpointrevision',
          label: 'dataTable.version',
          minWidth: 10
        },
        {
          prop: 'typeCode',
          label: 'dataTable.type',
          minWidth: 10
        }
      ]
    };
  },
  methods: {
    // Select colors for status data in mobile view
    stateColor(cellValue) {
      switch (cellValue.toString()) {
        case "online":
          return "success";
          break;
        case "linklost":
          return "citrus";
          break;
        case "linkdown":
          return "danger";
          break;
        case "neverup":
          return "info";
          break;
      }
    },

    // Fetch device list
    async fetchDevices() {
      this.loading = true;

      this.tableData = await this.$store.dispatch('fetchUpdDev');

      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name', 'lastheartbeatstatecode', 'playpointrevision'],
        threshold: 0.1
      });

      this.loading = false;
    }
  },
  async mounted() {
    // Fetch data from server by AXIOS - JSON.
    await this.fetchDevices();
    
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query*/
     
    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name', 'lastheartbeatstatecode', 'playpointrevision');
    }
  }
};
</script>
<style>
</style>
