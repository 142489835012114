<template>
  <div>
    <div class="row">
      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('buttons.action') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL" :disabled="loading">
          <a class="dropdown-item" @click="modals.miniRename = true">{{ $t('buttons.rename') }}</a>

          <div class="dropdown-divider" v-if="adminMenu"></div>
          <a class="dropdown-item" @click="modals.miniDemo = true" v-if="adminMenu">{{ $t('buttons.alert') }}</a>
          <a class="dropdown-item" @click="modals.miniReset = true" v-if="adminMenu">{{ $t('buttons.reset') }}</a>
        </base-dropdown>
      </div>
      <!-- <base-button round class="animation-on-hover" :type="colorUnification" @click="modals.miniRename = true"  :disabled="loading">{{ $t('buttons.rename') }}</base-button> -->
      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('sidebar.reports') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL">
          <a class="dropdown-item" @click="modals.miniRepR1 = true">{{ $t('buttons.reportR1') }}</a>
          <a class="dropdown-item" @click="modals.miniStat = true">{{ $t('buttons.statistic') }}</a>
        </base-dropdown>
      </div>

      <!-- <base-button round class="animation-on-hover" :type="colorUnification" @click="modals.miniDemo = true" v-if="!isMobile && adminMenu" :disabled="loading">{{ $t('buttons.demo') }}</base-button> 
      <base-button round class="animation-on-hover" icon :type="colorUnification" @click="modals.miniDemo = true" v-if="isMobile && adminMenu" :disabled="loading">
        <i class="tim-icons icon-chat-33"></i>
      </base-button> -->

      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDel = true" v-if="!isMobile" :disabled="loading">{{ $t('buttons.delete') }}</base-button> 
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDel = true" v-if="isMobile" :disabled="loading">
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <div  class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <card card-body-classes="table-full-width" v-loading="loading">
              <h4 class="card-title">{{ record.name }}
                <span slot="header" :class="badgeColor">
                  {{ record.lhbstatecode }}  {{ new Date(record.lhbplaypointdatetime).toLocaleString() }}
                </span>
              </h4>

              <small class="text-muted"><b>{{ $t('device.description') }}</b>
                {{ record.description }}
              </small> 

              <hr color="grey" border="none">

              <div class="row">
                <div class="col" ><h5>{{ $t('device.licKey') }}</h5></div>
                <div class="col"><h5>{{ record.licensekey }}</h5></div>
              </div>
              
              <div class="row">
                  <div class="col"><h5>{{ $t('device.model') }}</h5></div>
                  <div class="col"><h5>{{ record.typeCode }}</h5></div>
              </div>
              
              <div class="row">
                  <div class="col"><h5>{{ $t('device.expire') }}</h5></div>
                  <div class="col"><h5>{{ new Date(record.licenseexpiredate).toLocaleString() }}</h5></div>
              </div>
              
              <div class="row">
                  <div class="col"><h5>{{ $t('device.inGroup') }}</h5></div>
                  <div class="col">
                    <h5 v-if="record.groupuid !=='-'">
                      <router-link :to="'/equipments/groupsOne/' + record.groupuid">
                        {{ record.groupname }}
                      </router-link>  
                    </h5>
                    <h5 v-else> {{ record.groupname }} </h5>
                  </div>
              </div>
              
              <div class="row">
                  <div class="col"><h5>{{ $t('device.curentPl') }}</h5></div>
                  <div class="col">
                    <h5 v-if="record.playlistuid !== null">
                      <router-link :to="'/media/playlistsOne/' + record.playlistuid">
                        {{ record.playlistname }}
                      </router-link>  
                    </h5>
                    <h5 v-else> - </h5>
                  </div>
              </div>
              
              <div class="row">
                  <div class="col"><h5>{{ $t('device.curentPub') }}</h5></div>
                  <div class="col">
                    <h5 v-if="record.deployuid !== null">
                      <router-link :to="'/deployments/publicationsOne/' + record.deployuid">
                        {{ record.deployname }}
                      </router-link>  
                    </h5>
                    <h5 v-else> - </h5>
                  </div>
              </div>

              <div class="row">
                <div class="col"><h5>{{ $t('device.volume') }}</h5></div>
              </div>

              <div class="row">
                <div class="col-9">
                  <el-slider v-model="volumeLevel" :disabled="volumeEdit" :marks="volumeMarks"></el-slider>
                </div>
                <div class="col-3" style="text-align: center;">
                  <base-button round class="animation-on-hover" icon type="citrus" @click="volumeEdit=!volumeEdit" :disabled="loading4" v-if="volumeEdit">
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>

                  <base-button round class="animation-on-hover" icon type="success" @click="editVolumeLevel(), volumeEdit=!volumeEdit, saveTag(0, tagsVolume)"  v-if="!volumeEdit">
                    <i class="tim-icons icon-check-2"></i>
                  </base-button>

                  <base-button round class="animation-on-hover" icon type="info" @click="volumeEdit=!volumeEdit, delTag(0, tagsVolume)"  v-if="!volumeEdit">
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </div>
              </div>

            </card>

            <card card-body-classes="table-full-width" v-loading="loading">
              <h4 class="card-title">{{ $t('device.techInfo') }}</h4>

              <small class="text-muted"><b>{{ $t('device.description') }}</b>
                {{ record.lhbplaypointcomment }}
              </small> 

              <hr color="grey" border="none">

              <div class="row">
                <div class="col"><h5>{{ $t('device.created') }}</h5></div>
                <div class="col"><h5>{{ new Date(record.createdate).toLocaleString() }}</h5></div>
              </div>
              
              <div class="row">
                <div class="col"><h5>{{ $t('device.changed') }}</h5></div>
                <div class="col"><h5>{{ new Date(record.changedate).toLocaleString() }}</h5></div>
              </div>
              
              <div class="row">
                  <div class="col"><h5>{{ $t('device.version') }}</h5></div>
                  <div class="col"><h5>{{ record.lhbplaypointrevision }}</h5></div>
              </div>
              
              <div v-if="record.playpointmetrics != null">
                <div class="row">
                    <div class="col"><h5>{{ $t('device.ip') }}</h5></div>
                    <div class="col"><h5>{{ record.playpointmetrics.length > 12 ? record.playpointmetrics[11].pointvalue : '-' }}</h5></div>
                </div>
                
                <div class="row">
                    <div class="col"><h5>{{ $t('device.mac') }}</h5></div>
                    <div class="col"><h5>{{ record.playpointmetrics.length > 12 ? record.playpointmetrics[12].pointvalue : '-' }}</h5></div>
                </div>
                
                <div class="row">
                    <div class="col"><h5>{{ $t('device.temp') }}</h5></div>
                    <div class="col"><h5>{{ record.playpointmetrics.length > 12 ? record.playpointmetrics[10].pointvalue + record.playpointmetrics[10].pointparamtype : '-' }}</h5></div>
                </div>
              </div>
            </card>

            <card card-body-classes="table-full-width" v-loading="loading4">
              <h4 class="card-title">{{ $t('tag.tags') }}</h4>

              <small class="text-muted"><b>{{ $t('tag.description') }}</b>
                {{ $t('tag.tagsDescr') }} 
              </small> 

              <hr color="grey" border="none">

              <div class="row" v-for="tag in tags" v-if="!adminMenu">
                <div class="col"><h5>{{ $t('tag.' + tag.key.replace(/\./g, '')) }}</h5></div>
                <div class="col"><h5>{{ tag.value }}</h5></div>
              </div>

              <div class="row" v-for="(tag, index) in tags" :key="index" v-if="adminMenu">
                <div class="col-5">
                  <base-input
                    :disabled="!tags[index].edit"
                    type="text"
                    v-model="tag.key"
                  >
                  </base-input>
                </div>
                <div class="col-5">
                  <base-input
                    :disabled="!tags[index].edit"
                    type="text"
                    v-model="tag.value"
                  >
                  </base-input>
                </div>
                <div class="col-2" style="text-align: center;">
                  <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="citrus" @click="editTag(index)" v-if="!tags[index].edit" :disabled="loading4">
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>

                  <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="success" @click="saveTag(index, tags)" v-if="tags[index].edit">
                    <i class="tim-icons icon-check-2"></i>
                  </base-button>

                  <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="delTag(index, tags)" v-if="tags[index].edit">
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </div>
              </div>
              <div class="row" v-if="adminMenu">
                <base-button round class="animation-on-hover mx-3" :type="colorUnification" @click="addTag()">{{ $t('dataTable.add') }}</base-button>
              </div>

            </card>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <!-- <card card-body-classes="table-full-width" v-loading="loading">
              <h4 class="card-title" v-if="!isOnline">{{ $t('playlist.onlinePlay') }}</h4>

              <div align="center">
                <base-button
                  round
                  class="animation-on-hover"
                  :type="colorUnification"
                  @click="onlinePlayer()"
                  v-if="isOnline"
                >
                  <i class="tim-icons icon-wifi"></i>{{ $t('playlist.onlinePlay') }}
                </base-button>

                <audio controls ref='audio_player' @ended="nextMedia()" controlslist="nodownload" class="audio" v-show="!isOnline && isAudio"></audio>
                <video controls ref='video_player' @ended="nextMedia()" controlslist="nodownload" class="video" v-show="!isOnline && !isAudio"></video>
                
                <div v-if="!isOnline && !loading3">
                  <small class="text-muted"><b>{{ $t('playlist.curent') }}</b>
                    <router-link
                      :to="((record.typeCode == 'VIDEO') ? '/media/videosOne/' : '/media/melodiesOne/') + this.tableData[this.curentMedia].uid"
                    >
                      {{ this.tableData[this.curentMedia].name }}
                    </router-link>
                  </small>
                </div>
              </div>
            </card> -->

            <h4 class="card-title" style="text-align: center">{{ $t('device.lastPlayed') }}</h4>
          
            <time-line v-loading="loading2" type="simple">
              <time-line-item v-for="tracks in record2" :key="tracks.playTime"
                inverted
                :badge-type="colorUnification"
                :badge-icon="(record.typeCode == 'VIDEO') ? 'tim-icons icon-video-66' : 'tim-icons icon-volume-98'"
              >
                <div slot="header">
                  <span  :class="badgeColor"
                    >{{ new Date(tracks.playTime).toLocaleString() }}
                    </span> 
                    <label class="float-right">
                      {{ tracks.playList }}
                    </label>
                </div>
                <div slot="content" style="margin: -5px 0px;">
                    <router-link :to="((record.typeCode == 'VIDEO') ? '/media/videosOne/' : '/media/melodiesOne/') + tracks.trackUid">
                      {{ tracks.trackName }}
                    </router-link>                    
                </div>

              </time-line-item>

            </time-line> 
            
          </div>
        </div>
      </div>
    </div>

    <!--Modal for Delete -->
    <modal
      :show.sync="modals.miniDel"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-bell-55" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDel') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="deleteDevice(), modals.miniDel = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDel = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for reset Heartbeat -->
    <modal
      :show.sync="modals.miniReset"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-bell-55" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleReset') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="resetHeartbeat(), modals.miniReset = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniReset = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for Rename -->
    <modal
      :show.sync="modals.miniRename"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">
          
        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-pencil" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.renameTitle') }}</h4>
        
        <div class="col">
          <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.name') }}</label>
          <div>
            <ValidationProvider
              :rules="{ required: true, min: 3, max: 50}"
              v-slot="{ valid }"
              :bails="false" 
            >
              <input
                class="form-control modal-inputs"
                name="valueDevName" type="text"
                v-model="valueDevName"
              >
              <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
              >
                {{ $t('validation.name') }}
              </span>
            </ValidationProvider>
          </div>
          
          <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.description') }}</label>
          <ValidationProvider
            :rules="{ required: true, min: 3, max: 150}"
            v-slot="{ valid }"
            :bails="false" 
          >
            <input
              class="form-control modal-inputs"
              name="valueDiscr" type="text"
              v-model="valueDescr"
            >
            <span
              v-if="!valid"
              style="color: #000080; font-size: 12px;" 
            >
              {{ $t('validation.description') }}
            </span>
          </ValidationProvider>
        </div>   

        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="renameDevice(), modals.miniRename = false"
            >{{ $t('modal.confirmRename') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniRename = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>

      </ValidationObserver>
    </modal>

    <!--Modal for Demo functions -->
    <modal
      :show.sync="modals.miniDemo"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >          
      <div class="modal-profile" style="margin: auto;">
        <i class="tim-icons icon-chat-33" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.demoTitle') }}</h4>
      
      <div style="display: flex; flex-direction: row; justify-content: space-around;">

        <base-button round class="animation-on-hover" @click="demoMessage('DEFAULT')">{{ $t('buttons.alertDefault') }}</base-button>
        
        <base-button round class="animation-on-hover" @click="demoMessage('CHEMICAL')">{{ $t('buttons.alertChemical') }}</base-button>
        
        <base-button round class="animation-on-hover" @click="demoMessage('NUCLEAR')">{{ $t('buttons.alertNuclear') }}</base-button>
      </div>   

      <div>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDemo = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>
    
    <!--Modal for Statistic (reports with date interval)-->
    <modal
      :show.sync="modals.miniStat"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">

        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-chart-pie-36" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.statTitleDate') }}</h4>
      
        <ValidationProvider
          :rules="{ required: true}"
          v-slot="{ valid }"
          :bails="false" 
        >
          <el-date-picker
            :key="componentKey"
            value-format="yyyy.MM.dd"
            :picker-options="{firstDayOfWeek: 1, disabledDate: LimitDate}"
            v-model="dateRange"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('dataTable.intervalStart')"
            :end-placeholder="$t('dataTable.intervalFinish')"
          >
          </el-date-picker>
          <span
            v-if="!valid"
            style="color: #000080; font-size: 12px;" 
          >
            {{ $t('validation.required') }}
          </span>
        </ValidationProvider>
      
       
        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="statistic(), modals.miniStat = false"
            >{{ $t('modal.confirmStat') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="dateRange ='', modals.miniStat = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
      </ValidationObserver>
    </modal>
    
    <!--Modal for ReportR1 (reports with date interval)-->
    <modal
      :show.sync="modals.miniRepR1"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">

        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-chart-pie-36" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.statTitleDate') }}</h4>
      
        <ValidationProvider
          :rules="{ required: true}"
          v-slot="{ valid }"
          :bails="false" 
        >
          <el-date-picker
            :key="componentKey"
            value-format="yyyy.MM.dd"
            :picker-options="{firstDayOfWeek: 1, disabledDate: LimitDateR1}"
            v-model="dateRange"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('dataTable.intervalStart')"
            :end-placeholder="$t('dataTable.intervalFinish')"
          >
          </el-date-picker>
          <span
            v-if="!valid"
            style="color: #000080; font-size: 12px;" 
          >
            {{ $t('validation.required') }}
          </span>
        </ValidationProvider>
      
       
        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="reportR1(), modals.miniRepR1 = false"
            >{{ $t('modal.confirmStat') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="dateRange ='', modals.miniRepR1 = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
      </ValidationObserver>
    </modal>

  </div>
</template>

<script>
import { Select, Option, DatePicker, Slider } from 'element-ui';
import { TimeLine, TimeLineItem, Modal } from 'src/components';
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import locale from 'element-ui/lib/locale';
import ua from 'element-ui/lib/locale/lang/ua';
import de from 'element-ui/lib/locale/lang/de';
import ru from 'element-ui/lib/locale/lang/ru-RU';
import en from 'element-ui/lib/locale/lang/en';
import cn from 'element-ui/lib/locale/lang/zh-CN';
import ar from 'element-ui/lib/locale/lang/ar';
import es from 'element-ui/lib/locale/lang/es';
import fr from 'element-ui/lib/locale/lang/fr';
import pt from 'element-ui/lib/locale/lang/pt';

extend('required', required);
extend('min', min);
extend('max', max);


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
    TimeLine,
    TimeLineItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Slider.name]: Slider
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    //Compute curent language
    componentLanguage() {
      switch (this.$i18n.locale.toString()) {
        case "ru":
          return ru;
          break;
        case "en":
          return en;
          break;
        case "de":
          return de;
          break;
        case "ua":
          return ua;
          break;
        case "cn":
          return cn;
          break;
        case "ar":
          return ar;
          break;
        case "es":
          return es;
          break;
        case "fr":
          return fr;
          break;
        case "pt":
          return pt;
          break;
      }
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },
    // Status badges color
    badgeColor() {
      switch (this.record.lhbstatecode.toString()) {
        case "online":
          return "badge badge-pill badge-success";
          break;
        case "linklost":
          return "badge badge-pill badge-citrus";
          break;
        case "linkdown":
          return "badge badge-pill badge-danger";
          break;
        case "neverup":
          return "badge badge-pill badge-info";
          break;
      }
    }
  },
  data() {
    return {
      volumeLevel: 0,
      volumeEdit: true,
      volumeMarks: {
        0: '0%',
        25: '25%',
        50: '50%',
        75: '75%',
        100: '100%',
      },
      componentKey: 0,
      dateRange: '',      
      playlistVer: null,
      curentMedia: 0,
      isOnline: true,
      isAudio: true,
      media_player: null,
      valueDevName: null,
      valueDescr: null,
      devTypes: null,
      loading: true,
      loading2: true,
      loading3: true,
      loading4: true,
      tableData: [],
      adminMenu: false,
      tags: [],
      tagsVolume: [],
      record: {
        lhbstatecode: ''
      },
      record2: [],
      modals: {
        miniDel: false,
        miniStat: false,
        miniRename: false,
        miniReset: false,
        miniRepR1: false,
        miniDemo: false
      }
    };
  },
  methods: {
    //Restricting the ability to select a date according to the datastore setting
    LimitDate(date) {
        return date > new Date() || date < (new Date() - 45 * 86400000)
    },

    //Restricting the ability to select a date according to the datastore setting
    LimitDateR1(date) {
        return date > new Date() || date < (new Date() - 7 * 86400000)
    },
    
    // Sent demo alerts to device
    async demoMessage(value){
      const Data = {
        'uid': this.$route.params.id,
        'alerttype': value
      };
      
      await this.$store.dispatch('alarmStart', Data);

      this.$notify({
        type: 'success',
        message: this.$t('notifications.demo'),
        icon: 'tim-icons icon-bell-55'
      })
    },

    //Statistic report with Date
    async statistic(){
      const Data = {
        'mode': 'device',
        'uid': this.$route.params.id,
        'fromdate': this.dateRange[0],
        'todate': this.dateRange[1],
        'regionalTime': new Date().getTimezoneOffset()
      }
      
      this.$notify({
        type: 'info',
        message: this.$t('notifications.reportWait'),
        icon: 'tim-icons icon-bell-55'
      });

      await this.$store.dispatch('repStatisticDate', Data);
      this.dateRange = '';

      this.$notify({
        type: 'success',
        message: this.$t('notifications.report'),
        icon: 'tim-icons icon-bell-55'
      })
    },

    //R1 report with Date
    async reportR1(){
      const Data = {
        'trackuid': '-',
        'playpointuid': this.$route.params.id,
        'playlistuid': '-',
        'fromdate': this.dateRange[0],
        'todate': this.dateRange[1],
        'regionalTime': new Date().getTimezoneOffset()
      }
      
      this.$notify({
        type: 'info',
        message: this.$t('notifications.reportWait'),
        icon: 'tim-icons icon-bell-55'
      });

      await this.$store.dispatch('repR1date', Data);
      this.dateRange = '';

      this.$notify({
        type: 'success',
        message: this.$t('notifications.report'),
        icon: 'tim-icons icon-bell-55'
      })
    },

    // Edit volume level
    async editVolumeLevel() {
      if (this.tagsVolume.length === 0) {
        this.tagsVolume.push({"uid":"","key":"playpoint.volume.all","value": this.volumeLevel.toString(),"scope":"user","edit":true});
      } else {
        this.tagsVolume[0].value = this.volumeLevel.toString();
      }
    },

    // Fetch curent device volume level (set by user or by default policy)
    async fetchCurentVolumeLevel() {
      this.tagsVolume = this.tags.filter((item) => {return item.key === 'playpoint.volume.all'});
      
      const Data = {
        'uid': this.$route.params.id,
        'resultpolicy': 'true'
      };

      const curentVolume = await this.$store.dispatch('fetchCurentVol', Data);

      this.volumeLevel = this.tagsVolume.length === 0 ? parseInt(curentVolume[0].value) : parseInt(this.tagsVolume[0].value);
    },
    
    // Fetch Device`s tags
    async fetchDeviceTag() {
      this.loading4 = true;

      const Data = {
        'uid': this.$route.params.id,
        'contentType': 'playpoint'
      };

      this.tags = await this.$store.dispatch('fetchTag', Data);
      
      // Make filter for diferent users view
      if (!this.adminMenu) {
        this.tags = this.tags.filter((item) => {return item.scope === 'user'});
      }

      // Set slider for volume level by cheking of existing user configuration or default policy
      await this.fetchCurentVolumeLevel();
      
      this.tags.forEach(element => {
        element['edit'] = false;
      });

      this.loading4 = false;
    },
    
    // Add Device`s tags
    async addTag() {
      this.tags.push({"uid":"","key":"","value":"","scope":"system","edit":true});
    },

    // Edit Device`s tags
    async editTag(index) {
      let temp = this.tags[index];
      temp.edit = !temp.edit;

      this.tags.splice(index, 1, temp)
    },

    // Save Device`s tags
    async saveTag(index, tagsArray) {
      let temp = tagsArray[index];
      temp.edit = !temp.edit;

      tagsArray.splice(index, 1, temp);

      const Data = {
        'uid': this.$route.params.id,
        'contentType': 'playpoint',
        'formData': {
          "uid": tagsArray[index].uid,
          "key": tagsArray[index].key,
          "value": tagsArray[index].value,
          "scope": tagsArray[index].scope
        }
      };

      if (tagsArray[index].uid === '') {
        const answer = await this.$store.dispatch('addTag', Data);

        this.$notify({
          type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
          message: this.$t('notifications.crtConfirmeTag' + answer.statusCode.toString()),
          icon: 'tim-icons icon-bell-55'
        });
      } else {
        const answer = await this.$store.dispatch('editTag', Data);
        
        this.$notify({
          type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
          message: this.$t('notifications.renConfirmeTag' + answer.statusCode.toString()),
          icon: 'tim-icons icon-bell-55'
        });
      };

      await this.fetchDeviceTag();
    },

    // Delete Device`s tags
    async delTag(index, tagsArray) {
      // Checking for an error when trying to reset the already default volume level to the default
      if (tagsArray.length === 0) {
        this.$notify({
          type: 'danger',
          message: this.$t('notifications.delConfirmeTagEmpty'),
          icon: 'tim-icons icon-bell-55'
        });
        
        return
      } 

      let temp = tagsArray[index];
      temp.edit = !temp.edit;

      tagsArray.splice(index, 1, temp);

      const Data = {
        'uid': this.$route.params.id,
        'contentType': 'playpoint',
        'formData': {
          "uid": tagsArray[index].uid
        }
      };
      if (tagsArray[index].uid === '') {
        tagsArray.splice(index, 1);

        this.$notify({
          type: 'success',
          message: this.$t('notifications.delConfirmeTag200'),
          icon: 'tim-icons icon-bell-55'
        });        
      } else {
        const answer = await this.$store.dispatch('delTag', Data);

        this.$notify({
          type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
          message: this.$t('notifications.delConfirmeTag' + answer.statusCode.toString()),
          icon: 'tim-icons icon-bell-55'
        });

        await this.fetchDeviceTag();
      }
    },
    
    // Fetch Device by ID for head
    async fetchDeviceById() {
      this.loading = true;

      this.record = await this.$store.dispatch('fetchDevById', this.$route.params.id);
      this.valueDevName = this.record.name;
      this.valuDevType = this.record.typeCode;
      this.valueDescr = this.record.description;

      this.loading = false;
    },

    // Fetch last played tracks
    async fetchLastPlayed() {
      this.loading2 = true;

      this.record2 = await this.$store.dispatch('lastPlayed', this.record.licensekey);

      this.loading2 = false;
    },

    // Rename Device
    async renameDevice(){
      const Data = {
        'name': this.valueDevName,
        'uid': this.record.uid,
        'description': this.valueDescr
      };

      const answer = await this.$store.dispatch('renameDev', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.renConfirmeDev' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchDeviceById();
    },

    // Reset Device heartbeat for move it to "Didn`t connect"
    async resetHeartbeat(){

      const answer = await this.$store.dispatch('resetHeartbeat', this.record.uid);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.resetHeartbeat' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchDeviceById();
    },

    // Delete Device
    async deleteDevice() {
      const Data = {
        'uid': this.record.uid
      }

    const answer = await this.$store.dispatch('deleteDev', Data);

    this.$notify({
      type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
      message: this.$t('notifications.delConfirmeDev' + answer.statusCode.toString()),
      icon: 'tim-icons icon-bell-55'
    });

    this.$router.push('/equipments/devices')
    },

    // Set start media to player
    async onlinePlayer() {
      this.loading3 = true;
      this.$notify({
          type: 'info',
          message: this.$t('playlist.onlinePlayWait'),
          icon: 'tim-icons icon-bell-55'
        });
      this.isAudio = this.record.typeCode === 'AUDIO' ? true : false;
      this.tableData = await this.$store.dispatch('fetchPlContent', this.record.playlistuid);
      this.playlistVer = this.tableData.version;
      this.tableData = this.tableData.tracks;
      this.loading3 = false;

      this.media_player = this.isAudio ? this.$refs.audio_player : this.$refs.video_player;

      // Find start position if its SMART playlist, otherwise play from first track
      if (this.tableData[0].start !== null) {
        

        // Step №1: Check if it`s time to play content from playlist
        if ((new Date() > new Date(new Date().toISOString().split('T')[0] + 'T' + this.tableData[0].start)) &&
            (Date.now() < new Date(new Date().toISOString().split('T')[0] + 'T' + this.tableData[this.tableData.length-1].start))) {
          
          this.isOnline = false;
          
          // Step №2: Find position of content that must play now
          this.tableData.every(function(item, i, arr) {
            let playlistTimer = new Date(new Date().toISOString().split('T')[0] + 'T' + item.start)

            if (playlistTimer > Date.now()) {
              this.curentMedia = i;
              return false
            }
            return true
          }, this);
          
          
          this.media_player.src = this.tableData[this.curentMedia].url;
          this.media_player.play();
          this.sendHeartbeat();
        } else {
          this.$notify({
            type: 'danger',
            message: this.$t('playlist.onlinePlayRefusal'),
            icon: 'tim-icons icon-bell-55'
          });
        }

      } else {
        this.isOnline = false;
        this.media_player.src = this.tableData[this.curentMedia].url;
        this.media_player.play();
        this.sendHeartbeat();
      }
    },

    // Loop playing
    async nextMedia() {
      this.sendLogs();

      // Send heartbeat/update if time div 3
      if (new Date().getMinutes()%3 === 0) {
        this.sendHeartbeat();
        this.checkPlUpdate();
      }
      
      if (this.curentMedia === this.tableData.length - 1) {
          this.curentMedia = 0;
      } else {
          this.curentMedia++;
      }

      this.media_player.src = this.tableData[this.curentMedia].url;
      this.media_player.play();
      // Update played content list after avery played
      await this.fetchLastPlayed();
    },

    // Send logs
    async sendLogs () {
      const Data = {
        'objectclass': "engine_playmonitor",
        'version': "2.0",
        'playpointkey': this.record.licensekey,
        'playlist': this.record.playlistname,
        'playlistuid': this.record.playlistuid,
        'playlistversion': this.playlistVer,
        'playtrackuid': this.tableData[this.curentMedia].uid,
        'playtrackname': this.tableData[this.curentMedia].name,
        'playtime': new Date().toISOString(),
        'comment': "-"
      }
      await this.$store.dispatch('sendPlayed', Data);
    },

    // Send logs
    async sendHeartbeat () {
      const Data = {
        'objectclass': "engine_heartbeat",
        'version': "1.0",
        'playpointkey': this.record.licensekey,
        'playpointrevision': this.$i18n.t('playlist.onlinePlay'),
        'playpointstate': "OK",
        'playpointplaylist': this.record.playlistname,
        'playpointcomment': this.$i18n.t('playlist.onlinePlay'),
        'playpointdatetime': new Date().toUTCString()
      }
      await this.$store.dispatch('sendHeartbeat', Data);
    },

    // Check playlist update
    async checkPlUpdate () {
      let fetchPl =  await this.$store.dispatch('fetchDevById', this.$route.params.id);
      let fetchPlVersion =  await this.$store.dispatch('fetchPlContent', fetchPl.playlistuid);

      // fetchPlVersion.version !== this.playlistVer || 

      if (fetchPlVersion.uid !== this.record.playlistuid) {
        await this.fetchDeviceById();
        await this.onlinePlayer();
      }
    }
  },
  async mounted() {
    // Set start language for date picker
    locale.use(this.componentLanguage);

    // Check user rights for work with tags
    this.adminMenu = JSON.parse(localStorage.getItem('user')).role === "Admin" ? true : false;

    // Fetch Device by ID for head
    await this.fetchDeviceById();

    // Fetch Device`s tag
    await this.fetchDeviceTag();

    // Fetch last played tracks
    await this.fetchLastPlayed();
  },
  watch: {
    //Change languge of date pick component, when locale changed
    componentLanguage() {
      locale.use(this.componentLanguage)
      this.componentKey += 1; //re-render the component for full locale change (day not changing, need to list day page for re-render)
    }
  }
};
</script>
<style>
</style>
