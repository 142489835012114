<template>
  <div>
    <div class="row">
      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('buttons.action') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL" :disabled="loading">
          <a class="dropdown-item" @click="modals.miniRename = true">{{ $t('buttons.rename') }}</a>
        </base-dropdown>
      </div>

      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('sidebar.reports') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL">
          <a class="dropdown-item" @click="modals.miniRepR1 = true">{{ $t('buttons.reportR1') }}</a>
        </base-dropdown>
      </div>
      
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDel = true" v-if="!isMobile && (isUsed==='Unused')">{{ $t('buttons.delete') }}</base-button> 
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDel = true" v-if="isMobile && (isUsed==='Unused')">
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </div>

    <div class="row mt-2">
      <div class="col-12">   
        <card v-loading="loading">
          <h4 class="card-title">{{ this.record.name }}
            <span v-if="this.isUsed" slot="header" :class="[this.record.useCount === 0 ? 'badge badge-pill badge-citrus' : 'badge badge-pill badge-success']">
              {{ this.isUsed }}
            </span>  
          </h4>

          <small class="text-muted"><b>{{ $t('melodies.description') }}</b>
            {{ record.description }}
          </small>   
          
          <hr color="grey" border="none">
          
          <div  class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="row">
                  <div class="col"><h5>{{ $t('melodies.type') }}</h5></div>
                  <div class="col" ><h5> {{ this.record.typeCode }} </h5></div>
              </div>
              <div class="row">
                  <div class="col"><h5>{{ $t('melodies.size') }}</h5></div>
                  <div class="col"><h5> {{ Intl.NumberFormat(this.$i18n.locale).format(Math.round(this.record.size/1000)) }} {{ $t('dashboard.kbyte') }}</h5></div>
              </div>
              <div class="row">
                  <div class="col"><h5>{{ $t('melodies.duration') }}</h5></div>
                  <div class="col"><h5> {{ Intl.NumberFormat(this.$i18n.locale).format(Math.round(this.record.duration)) }} {{ $t('dashboard.second') }}</h5></div>
              </div>
              <div class="row">
                  <div class="col"><h5>{{ $t('melodies.uploaded') }}</h5></div>
                  <div class="col"><h5> {{ new Date(record.createDate).toLocaleString() }} </h5></div>
              </div>
              <div class="row">
                  <div class="col"><h5>{{ $t('melodies.version') }}</h5></div>
                  <div class="col"><h5> {{ this.record.version }} </h5></div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6" style="display: flex; justify-content: center; align-items: center;" v-if="!loading">
              <video controls class="video" controlslist="nodownload">
                <source :src=" this.record.url " type="video/mp4" >
              </video>
            </div>
          </div>

        </card>
      </div>
    </div>
    <!-- Lower menu -->
    <div  class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">    
        <card>
          <h4 slot="header" class="card-title">{{ $t('dataTable.usedPl') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                :type="colorUnification"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData" v-loading="loading2" :empty-text="loading2 ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.stateCode)"> {{props.row.stateCode}} </span></p>
                </div>
              </el-table-column>

              <el-table-column
                v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>
                            
              <el-table-column v-if="isMobile" width="38">
                <template slot-scope="props">
                  <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.stateCode) + ')'"></span>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>    
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">    
        <card>
          <h4 slot="header" class="card-title">{{ $t('dataTable.usedGr') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                :type="colorUnification"
                v-model="pagination2.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination2.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery2"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData2" v-loading="loading3" :empty-text="loading3 ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.stateCode)"> {{props.row.stateCode}} </span></p>
                </div>
              </el-table-column>

              <el-table-column
                v-for="column in isMobile ? tableColumns2.slice(0, 1) : tableColumns2"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>

              <el-table-column v-if="isMobile" width="38">
                <template slot-scope="props">
                  <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.stateCode) + ')'"></span>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from2 + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to2) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total2) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination2.currentPage"
              :per-page="pagination2.perPage"
              :total="total2"
            >
            </base-pagination>
          </div>
        </card>    
      </div>
    </div>

    <!-- Scroll to top component -->
    <ScrollToTop></ScrollToTop> 

    <!--Modal for Delete -->
    <modal
      :show.sync="modals.miniDel"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-bell-55" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDel') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="deleteMedia(), modals.miniDel = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDel = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for ReportR1 (reports with date interval)-->
    <modal
      :show.sync="modals.miniRepR1"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">

        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-chart-pie-36" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.statTitleDate') }}</h4>
      
        <ValidationProvider
          :rules="{ required: true}"
          v-slot="{ valid }"
          :bails="false" 
        >
          <el-date-picker
            :key="componentKey"
            value-format="yyyy.MM.dd"
            :picker-options="{firstDayOfWeek: 1, disabledDate: LimitDate}"
            v-model="dateRange"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('dataTable.intervalStart')"
            :end-placeholder="$t('dataTable.intervalFinish')"
          >
          </el-date-picker>
          <span
            v-if="!valid"
            style="color: #000080; font-size: 12px;" 
          >
            {{ $t('validation.required') }}
          </span>
        </ValidationProvider>
      
       
        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="reportR1(), modals.miniRepR1 = false"
            >{{ $t('modal.confirmStat') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="dateRange ='', modals.miniRepR1 = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
      </ValidationObserver>
    </modal>

    <!--Modal for Rename -->
    <modal
      :show.sync="modals.miniRename"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">
          
        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-pencil" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.renameTitle') }}</h4>
        
        <div class="col">
          <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.name') }}</label>
          <div>
            <input
              disabled
              class="form-control modal-inputs"
              name="valueDevName" type="text"
              v-model="valueName"
            >
          </div>
          
          <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.description') }}</label>
          <ValidationProvider
            :rules="{ required: true, min: 3, max: 150}"
            v-slot="{ valid }"
            :bails="false" 
          >
            <input
              class="form-control modal-inputs"
              name="valueDiscr" type="text"
              v-model="valueDescr"
            >
            <span
              v-if="!valid"
              style="color: #000080; font-size: 12px;" 
            >
              {{ $t('validation.description') }}
            </span>
          </ValidationProvider>
        </div>   

        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="renameMedia(), modals.miniRename = false"
            >{{ $t('modal.confirmRename') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniRename = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>

      </ValidationObserver>
    </modal>

  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, DatePicker } from 'element-ui';
import { BasePagination, Modal } from 'src/components';
import Fuse from 'fuse.js';
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import { search } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";
import locale from 'element-ui/lib/locale';
import ua from 'element-ui/lib/locale/lang/ua';
import de from 'element-ui/lib/locale/lang/de';
import ru from 'element-ui/lib/locale/lang/ru-RU';
import en from 'element-ui/lib/locale/lang/en';
import cn from 'element-ui/lib/locale/lang/zh-CN';
import ar from 'element-ui/lib/locale/lang/ar';
import es from 'element-ui/lib/locale/lang/es';
import fr from 'element-ui/lib/locale/lang/fr';
import pt from 'element-ui/lib/locale/lang/pt';

extend('required', required);
extend('min', min);
extend('max', max);


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ScrollToTop,
    [DatePicker.name]: DatePicker
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    //Compute curent language
    componentLanguage() {
      switch (this.$i18n.locale.toString()) {
        case "ru":
          return ru;
          break;
        case "en":
          return en;
          break;
        case "de":
          return de;
          break;
        case "ua":
          return ua;
          break;
        case "cn":
          return cn;
          break;
        case "ar":
          return ar;
          break;
        case "es":
          return es;
          break;
        case "fr":
          return fr;
          break;
        case "pt":
          return pt;
          break;
      }
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    queriedData2() {
      let result = this.tableData2;
      if (this.searchedData2.length > 0) {
        result = this.searchedData2;
      }
      return result.slice(this.from2, this.to2);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.tableData2.length;
    }
  },
  data() {
    return {
      componentKey: 0,
      dateRange: '',
      valueName: null,
      valueDescr: null,
      isUsed: false,
      loading: true,
      loading2: true,
      loading3: true,
      record: { type: []},
      record2: [],
      tableData: [],
      tableData2: [],
      searchedData: [],
      searchedData2: [],
      searchQuery: '',
      searchQuery2: '',
      fuseSearch: null,
      fuseSearch2: null,
      modals: {
        miniDel: false,
        miniRepR1: false,
        miniRename: false
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      pagination2: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 68,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'/media/playlistsOne/' + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'stateCode',
          label: 'dataTable.status',
          minWidth: 32,
          formatter: (row, col, cellValue, index) => {           
          if (cellValue==='ACTIVE') {
            return <span slot="header" class="badge badge-pill badge-success"> {cellValue} </span>
          } else
            return <span slot="header" class="badge badge-pill badge-citrus"> {cellValue} </span>
          }
        }
      ],
      tableColumns2: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 68,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'/equipments/groupsOne/' + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'stateCode',
          label: 'dataTable.status',
          minWidth: 32,
          formatter: (row, col, cellValue, index) => {           
          if (cellValue==='ACTIVE') {
            return <span slot="header" class="badge badge-pill badge-success"> {cellValue} </span>
          } else
            return <span slot="header" class="badge badge-pill badge-citrus"> {cellValue} </span>
          }
        }
      ]
    };
  },
  methods: {
    // Select colors for status data in mobile view
    stateColor(cellValue) {         
      if (cellValue==='ACTIVE') {
        return "success"
      } else
        return "citrus"
    },
    
    //Restricting the ability to select a date according to the datastore setting
    LimitDate(date) {
        return date > new Date() || date < (new Date() - 45 * 86400000)
    },

    //Rename Media
    async renameMedia(){
      const formData = {
        'uid': this.record.uid,
        'description': this.valueDescr
      };
      const Data = {
        'contentType': 'video-com',
        'formData': formData
      };

      const answer = await this.$store.dispatch('renameMedia', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.renConfirmeMedia' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchMediaById();
    },

    //R1 report
    async reportR1(){
      const Data = {
        'trackuid': this.$route.params.id,
        'playpointuid': '-',
        'playlistuid': '-',
        'fromdate': this.dateRange[0],
        'todate': this.dateRange[1],
        'regionalTime': new Date().getTimezoneOffset()
      }
      
      this.$notify({
        type: 'info',
        message: this.$t('notifications.reportWait'),
        icon: 'tim-icons icon-bell-55'
      });

      await this.$store.dispatch('repR1date', Data);
      this.dateRange = '';

      this.$notify({
        type: 'success',
        message: this.$t('notifications.report'),
        icon: 'tim-icons icon-bell-55'
      })
    },

    //Delete the element
    async deleteMedia() {
      const forDelete = {
        "uid": this.record.uid
      }

      const Data = {
        'contentType': 'video-com',
        "forDelete": forDelete
      }
      
      const answer = await this.$store.dispatch('deleteMedia', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.delConfirmeMedia' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      this.$router.push('/media/videosAds')    
    },

    // Fetch Video by ID for head of page
    async fetchMediaById() {
      this.loading = true;
      const Data = {
        'contentType': 'video-com',
        'uid': this.$route.params.id
      }
      this.record = await this.$store.dispatch('fetchMediaById', Data);
      this.isUsed = this.record.useCount === 0 ? 'Unused' : 'Used';
      this.valueName = this.record.name;
      this.valueDescr = this.record.description;

      this.loading = false;
    },

    // Fetch Video is in groups
    async fetchMediaInGr() {
      this.loading3 = true;
      const Data = {
        'contentType': 'video-com',
        'uid': this.$route.params.id
      }
      this.tableData2 = await this.$store.dispatch('fetchMediaInGr', Data);

      // Second Fuse search initialization.
      this.fuseSearch2 = new Fuse(this.tableData2, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name', 'stateCode'],
        threshold: 0.1
      });

      this.loading3 = false;
    },

    // Fetch Video is in playlists
    async fetchMediaInPl() {
      this.loading2 = true;
      const Data = {
        'contentType': 'video-com',
        'uid': this.$route.params.id
      }
      this.tableData = await this.$store.dispatch('fetchMediaInPl', Data);

      // First Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name', 'stateCode'],
        threshold: 0.1
      });

      this.loading2 = false;
    }
  },
  async mounted() {
    // Set start language for date picker
    locale.use(this.componentLanguage);

    // Fetch Videos by ID for head of page
    await this.fetchMediaById();
    
    // Fetch Video is in playlists
    await this.fetchMediaInPl();

    // Fetch Video is in groups
    await this.fetchMediaInGr();

  },
  watch: {
    //Change languge of date pick component, when locale changed
    componentLanguage() {
      locale.use(this.componentLanguage)
      this.componentKey += 1; //re-render the component for full locale change (day not changing, need to list day page for re-render)
    },

    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name', 'stateCode');
    },
    searchQuery2(value) {
      this.searchedData2 = search(value, this.tableData2, this.fuseSearch2, 'name', 'stateCode');
    }
  }
};
</script>
<style>
</style>
