<template>
  <div>
    <div class="row">
      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('buttons.action') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL" :disabled="loading">
          <a class="dropdown-item" @click="editPackage" v-if="isEdit">{{ $t('buttons.activate') }}</a>
          <a class="dropdown-item" @click="editPackage" v-if="!isEdit && !loading">{{ $t('buttons.edit') }}</a>
          <div class="dropdown-divider" v-if="isEdit"></div>
          <a class="dropdown-item" @click="modals.miniRename = true" v-if="isEdit">{{ $t('buttons.rename') }}</a>
        </base-dropdown>
      </div>
      
      <base-button round class="animation-on-hover" :type="colorUnification" @click="modals.miniUpload = true" v-if="!isMobile">{{ $t('buttons.upload') }}</base-button>
      <base-button round class="animation-on-hover" icon :type="colorUnification" @click="modals.miniUpload = true" v-if="isMobile">
        <i class="tim-icons icon-cloud-upload-94"></i>
      </base-button>
      
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDel = true" v-if="isEdit && !isMobile" :disabled='true'>{{ $t('buttons.delete') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDel = true" v-if="isMobile">
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </div>
    
    <div class="row mt-2">
      <div class="col-12">    
        <card v-loading="loading">
          <h4 class="card-title">{{ record.name }}
            <span slot="header" :class="[isEdit ? 'badge badge-pill badge-citrus' : 'badge badge-pill badge-success']">
              {{ record.statecode }}
            </span>
          </h4>
          
          <small class="text-muted"><b>{{ $t('group.description') }}</b>
            {{ record.description }}
          </small>

          <hr color="grey" border="none">
          
          <div  class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="row">
                <div class="col-4"><h5>{{ $t('dataTable.changed') }}:</h5></div>
                <div class="col" ><h5> {{ new Date(this.record.changedate).toLocaleString() }} </h5></div>
              </div>
            </div>
          </div>
        </card>
        
        <!-- Main table with Group - (queriedData)-->
        <card card-body-classes="table-full-width" v-if="!loading">
          <h4 slot="header" class="card-title">{{ $t('dataTable.pkContent') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData" v-loading="loading2" :empty-text="loading2 ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.statecode)"> {{props.row.statecode}} </span></p>
                  <p><b>{{ $t('dataTable.original') }}</b>: {{props.row.nameoriginal}} </p>
                  <p><b>{{ $t('dataTable.changed') }}</b>: {{new Date(props.row.changedate).toLocaleString()}} </p>
                  <p><b>{{ $t('dataTable.del') }}</b>: 
                    <base-button
                      @click.native="delFromGroup(props.row.uid, props.row.linkid)"
                      round
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </p>
                </div>
              </el-table-column>

              <el-table-column
                v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>

              <el-table-column v-if="isMobile" width="38">
                <template slot-scope="props">
                  <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.statecode) + ')'"></span>
                </template>
              </el-table-column>

              <el-table-column :min-width="25" align="right" :label="$t('dataTable.del')" v-if="!isMobile && isEdit">
                <div slot-scope="props">
                  <base-button
                    @click.native="delFromGroup(props.row.uid, props.row.linkid)"
                    round
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
 
      </div>
    </div>

    <!-- Scroll to top component -->
    <ScrollToTop></ScrollToTop>

    <!--Modal for Delete -->
    <modal
      :show.sync="modals.miniDel"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-bell-55" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDel') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="deletePackage(), modals.miniDel = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDel = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for Rename -->
    <modal
      :show.sync="modals.miniRename"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">
          
        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-pencil" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.renameTitle') }}</h4>
        
        <div class="col">
          <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.name') }}</label>
          <div>
            <ValidationProvider
              :rules="{ required: true, min: 3, max: 50}"
              v-slot="{ valid }"
              :bails="false" 
            >
              <input
                class="form-control modal-inputs"
                name="valueGrName" type="text"
                v-model="valueGrName"
              >
              <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
              >
                {{ $t('validation.name') }}
              </span>
            </ValidationProvider>
          </div>
          
          <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.description') }}</label>
          <ValidationProvider
            :rules="{ required: true, min: 3, max: 150}"
            v-slot="{ valid }"
            :bails="false" 
          >
            <input
              class="form-control modal-inputs"
              name="valueDescr" type="text"
              v-model="valueDescr"
            >
            <span
              v-if="!valid"
              style="color: #000080; font-size: 12px;" 
            >
              {{ $t('validation.description') }}
            </span>
          </ValidationProvider>
        </div>   

        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="renamePackage(), modals.miniRename = false"
            >{{ $t('modal.confirmRename') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniRename = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
            
      </ValidationObserver>
    </modal>

    <!--Modal for Upload TGZ-->
    <modal
      :show.sync="modals.miniUpload"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div class="modal-profile" style="margin: auto;">
        <i class="tim-icons icon-cloud-upload-94" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white" v-if="this.files.length == 0">{{ $t('modal.titleUpl') }}</h4>
      <h4 align="center" style="color: white" v-else>{{ $t('modal.titleUplAlt') }} {{ this.files.length }}</h4>
      
      <input type="file" id="files" accept=".tgz" ref="files" style="display: none" multiple v-on:change="handleFilesUpload()">
      
      <base-button style="margin:auto; display:block" round icon type="default" v-on:click="addFiles()" v-if="uploading == 0">
          <i class="tim-icons icon-attach-87"></i>
      </base-button>

      <div style="height: 20px; margin: 0 20px;" v-else>
        <base-progress :value="uploading"></base-progress>
      </div>

      <h5 align="center" style="color: white" v-if="uploading == 100">{{ $t('modal.titleUplWait') }}</h5>

      <div>
        <base-button 
          type="neutral"
          link
          :disabled="this.files.length == 0 || uploading > 0 ? true : false"
          @click="submitFiles"
          >{{ $t('modal.confirmUpl') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniUpload = false, files = '', $refs.files.value = null"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Modal} from 'src/components';
import Fuse from 'fuse.js';
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import { search } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";


extend('required', required);
extend('min', min);
extend('max', max);


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ScrollToTop
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },
    
    //Get % of uploading
    uploading() {
      return this.$store.getters.uploading
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      componentKey: 0,
      dateRange: '',
      loading: true,
      loading2: true,
      isEdit: false,
      record: [],
      tableData: [],
      tableData2: [],
      searchedData: [],
      searchQuery: '',
      files: '',
      fuseSearch: null,
      valueGrName: null,
      valueDescr: null,
      modals: {
        miniDel: false,
        miniRename: false,
        miniUpload: false
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 25,
          formatter: (row, col, cellValue, index) => {
            return <a href={row.url} target="_blank">{ cellValue }</a>
          }
        },
        {
          prop: 'statecode',
          label: 'dataTable.status',
          minWidth: 20,
          formatter: (row, col, cellValue, index) => {           
          if (cellValue==='ACTIVE') {
            return <span slot="header" class="badge badge-pill badge-success"> {cellValue} </span>
          } else
            return <span slot="header" class="badge badge-pill badge-citrus"> {cellValue} </span>
          }
        },
        {
          prop: 'nameoriginal',
          label: 'dataTable.original',
          minWidth: 15
        },
        {
          prop: 'changedate',
          label: 'dataTable.changed',
          minWidth: 15,
          formatter: (cellrow, col, cellValue, indexValue) => {
            return new Date(cellValue).toLocaleString()
          }
        },
      ],
    };
  },
  methods: {
    // Select colors for status data in mobile view
    stateColor(cellValue) {         
      if (cellValue==='ACTIVE') {
        return "success"
      } else
        return "citrus"
    },

    // Fetch Package by ID for head of page
    async fetchPackageById() {
      this.loading = true;

      this.record = await this.$store.dispatch('fetchPkById', this.$route.params.id);
      this.isEdit = this.record.statecode === 'ACTIVE' ? false : true;
      this.valueGrName = this.record.name;
      this.valueDescr = this.record.description;

      this.loading = false;
    },

    // Fetch files list
    async fetchFilesList() {
      this.loading2 = true;

      this.searchQuery = ''
      this.tableData = await this.$store.dispatch('fetchFilesInPk', this.$route.params.id);

      // First Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name', 'name_original'],
        threshold: 0.1
      });

      this.loading2 = false;
    },

    //Rename Package
    async renamePackage(){
      const Data = {
        'name': this.valueGrName,
        'uid': this.record.uid,
        'description': this.valueDescr
      };

      const answer = await this.$store.dispatch('renameGr', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.renConfirmePk' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPackageById()
    },

    // Status change
    async editPackage() {
      const answer = await this.$store.dispatch('editPackages', this.record.uid)
      
      this.$notify({
        type: answer.toString() === '1' ? 'danger' : 'success',
        message: this.$t('notifications.editConfirmeGr'+ answer.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchFilesList()
      await this.fetchPackageById()
    },

    // Delete file from Package
    async delFromGroup(value, value2) {
      const Data = {
        'uid': this.$route.params.id,
        'fileuid': value,
        'linkid': value2
      };
    
      const answer = await this.$store.dispatch('deleteFromPk', Data);

      // this.$notify({
      //   type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
      //   message: this.$t('notifications.outConfirmePk' + answer.statusCode.toString()),
      //   icon: 'tim-icons icon-bell-55'
      // });

      console.log(answer)

      await this.fetchFilesList();
    },

    //Delete Package
    async deletePackage() {
      const Data = {
        'uid': this.record.uid
      }
    
      // There is no API for delete package yet from Dima
      // const answer = await this.$store.dispatch('deleteGr', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.delConfirmePk'+ answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      this.$router.push('/equipments/groups')  
    },

    //Replace the Input by button click
    addFiles(){
      this.$refs.files.click();
    },

    //Save selected files to local variable
    handleFilesUpload(){
      this.files = this.$refs.files.files;
    },
    
    // Send files to the server
    async submitFiles(){
      let formData = new FormData();
      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];
        formData.append('files[' + i + ']', file);
      }
      
      const Data = {
        'uid': this.record.uid,
        'files': formData
      }
      const answer = await this.$store.dispatch('uploadUpdates', Data);

      const error = answer.statusCode.toString() === '902' ? answer.headers[0].Value.toString() : '';

      this.$notify({
          type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
          message: this.$t('notifications.uplConfirmeVideo'+ answer.statusCode.toString()) + error,
          icon: 'tim-icons icon-bell-55'
        });

      this.files = '';
      this.$refs.files.value = null;
      await this.$store.commit('setUpload', 0);
      this.modals.miniUpload = false;

      await this.fetchFilesList();
    },
    
  },
  async mounted() {
    // Fetch Package by ID for head of page
    await this.fetchPackageById();

    // Fetch files list (DATATABLE)
    await this.fetchFilesList();
    
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name', 'name_original');
    }
  }
};
</script>
<style>
</style>
