<template>
  <div class="row">
    <card class="card-subcategories card-plain">
      <div>
          <tabs
            :type="colorUnification"
            tabContentClasses="tab-subcategories"
            square
            centered
            class="row"
          >
            <tab-pane>
              <span slot="label">
                <i class="tim-icons icon-heart-2"></i>{{ $t('reports.head1') }}
              </span>
              
                <div  class="row">
                  <!-- First report -->
                  <div class="col-lg-6 col-md-6 col-sm-6">    
                    <card>
                      <h4 class="card-title" align="center">{{ $t('reports.r2Title') }}</h4>
                      <div class="report_des">
                        {{ $t('reports.r2') }}
                      </div>
                      <div align="right">
                        <base-button
                          round class="animation-on-hover"
                          :type="colorUnification"
                          align="right"
                          @click="reportR = 'repR2', reportR2R3()"
                        >
                            {{ $t('buttons.download') }}
                        </base-button>
                      </div>
                    </card>    
                  </div>

                  <!-- Second report -->
                  <div class="col-lg-6 col-md-6 col-sm-6">    
                    <card>
                      <h4 class="card-title" align="center">{{ $t('reports.r3Title') }}</h4>
                      <div class="report_des">
                        {{ $t('reports.r3') }}
                      </div>
                      <div align="right">
                        <base-button
                          round class="animation-on-hover"
                          :type="colorUnification"
                          align="right"
                          @click="reportR = 'repR3', reportR2R3()"
                        >
                            {{ $t('buttons.download') }}
                        </base-button>
                      </div>
                    </card>    
                  </div> 
                </div>
                
            </tab-pane>

            <tab-pane>
              <span slot="label">
                <i class="tim-icons icon-chart-pie-36"></i>{{ $t('reports.head2') }}
              </span>
                <div  class="row">
                  <!-- First report -->
                  <div class="col-lg-6 col-md-6 col-sm-6">    
                    <card>
                      <h4 class="card-title" align="center">{{ $t('reports.s1Title') }}</h4>
                      <div class="report_des">
                        {{ $t('reports.s1') }}
                      </div>
                      <div align="right">
                        <base-button
                          round class="animation-on-hover"
                          :type="colorUnification"
                          align="right"
                          @click="reportS = 'repS1date', modals.miniStat = true"
                        >
                            {{ $t('buttons.download') }}
                        </base-button>
                      </div>
                    </card>    
                  </div>

                  <!-- Second report -->
                  <div class="col-lg-6 col-md-6 col-sm-6">    
                    <card>
                      <h4 class="card-title" align="center">{{ $t('reports.s2Title') }}</h4>
                      <div class="report_des">
                        {{ $t('reports.s2') }}
                      </div>
                      <div align="right">
                        <base-button
                          round class="animation-on-hover"
                          :type="colorUnification"
                          align="right"
                          @click="reportS = 'repS2date', modals.miniStat = true"
                        >
                            {{ $t('buttons.download') }}
                        </base-button>
                      </div>
                    </card>    
                  </div> 
                </div>
            </tab-pane>

            <tab-pane>
              <span slot="label">
                <i class="tim-icons icon-alert-circle-exc"></i>{{ $t('reports.head3') }}
              </span>
              <div  class="row">
                <!-- First report -->
                <div class="col-lg-6 col-md-6 col-sm-6">    
                  <card>
                    <h4 class="card-title" align="center">
                      {{ $t('reports.a1Title') }}
                    </h4>
                    <div class="report_des">
                      {{ $t('reports.a1') }}
                    </div>
                    <div align="right">
                      <base-button
                        round class="animation-on-hover"
                        :type="colorUnification"
                        align="right"
                        @click="reportS = 'repA1date', modals.miniStat = true"
                      >
                          {{ $t('buttons.download') }}
                      </base-button>
                    </div>
                  </card>    
                </div>

                <!-- Second report -->
                <div class="col-lg-6 col-md-6 col-sm-6">    
                  <card>
                    <h4 class="card-title" align="center">
                      {{ $t('reports.a2Title') }}
                    </h4>
                    <div class="report_des">
                      {{ $t('reports.a2') }}
                    </div>
                    <div align="right">
                      <base-button
                        round class="animation-on-hover"
                        :type="colorUnification"
                        align="right"
                        @click="reportS = 'repA2date', modals.miniStat = true"
                      >
                          {{ $t('buttons.download') }}
                      </base-button>
                    </div>
                  </card>    
                </div>

                <!-- Third report -->
                <div class="col-lg-6 col-md-6 col-sm-6">    
                  <card>
                    <h4 class="card-title" align="center">
                      {{ $t('reports.a3Title') }}
                    </h4>
                    <div class="report_des">
                      {{ $t('reports.a3') }}
                    </div>
                    <div align="right">
                      <base-button
                        round class="animation-on-hover"
                        :type="colorUnification"
                        align="right"
                        @click="reportS = 'repA3date', modals.miniStat = true"
                      >
                          {{ $t('buttons.download') }}
                      </base-button>
                    </div>
                  </card>    
                </div>

                <!-- Fourth report -->
                <div class="col-lg-6 col-md-6 col-sm-6">    
                  <card>
                    <h4 class="card-title" align="center">{{ $t('reports.a4Title') }}</h4>
                    <div class="report_des">
                      {{ $t('reports.a4') }}
                    </div>
                    <div align="right">
                      <base-button
                        round class="animation-on-hover"
                        :type="colorUnification"
                        align="right"
                        @click="reportR = 'repAllDevTags', reportR2R3()"
                      >
                          {{ $t('buttons.download') }}
                      </base-button>
                    </div>
                  </card>    
                </div>

              </div>
            </tab-pane>
          </tabs>
      
      </div>
    </card>

    <!--Modal for Statistic (reports with date interval)-->
    <modal
      :show.sync="modals.miniStat"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">

        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-chart-pie-36" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.statTitleDate') }}</h4>
      
        <ValidationProvider
          :rules="{ required: true}"
          v-slot="{ valid }"
          :bails="false" 
        >
          <el-date-picker
            :key="componentKey"
            value-format="yyyy.MM.dd"
            :picker-options="{firstDayOfWeek: 1, disabledDate: LimitDate}"
            v-model="dateRange"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('dataTable.intervalStart')"
            :end-placeholder="$t('dataTable.intervalFinish')"
          >
          </el-date-picker>
          <span
            v-if="!valid"
            style="color: #000080; font-size: 12px;" 
          >
            {{ $t('validation.required') }}
          </span>
        </ValidationProvider>
      
       
        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="reportS1S2(), modals.miniStat = false"
            >{{ $t('modal.confirmStat') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="dateRange ='', modals.miniStat = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
      </ValidationObserver>
    </modal>

  </div>
</template>
<script>
import { TabPane, Tabs, Modal } from 'src/components';
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { DatePicker } from 'element-ui';
import locale from 'element-ui/lib/locale';
import ua from 'element-ui/lib/locale/lang/ua';
import de from 'element-ui/lib/locale/lang/de';
import ru from 'element-ui/lib/locale/lang/ru-RU';
import en from 'element-ui/lib/locale/lang/en';
import cn from 'element-ui/lib/locale/lang/zh-CN';
import ar from 'element-ui/lib/locale/lang/ar';
import es from 'element-ui/lib/locale/lang/es';
import fr from 'element-ui/lib/locale/lang/fr';
import pt from 'element-ui/lib/locale/lang/pt';

extend('required', required);

export default {
  components: {
    Modal,
    TabPane,
    Tabs,
    [DatePicker.name]: DatePicker,
    ValidationProvider,
    ValidationObserver  
  },
  computed: {
    //Compute curent language
    componentLanguage() {
      switch (this.$i18n.locale.toString()) {
        case "ru":
          return ru;
          break;
        case "en":
          return en;
          break;
        case "de":
          return de;
          break;
        case "ua":
          return ua;
          break;
        case "cn":
          return cn;
          break;
        case "ar":
          return ar;
          break;
        case "es":
          return es;
          break;
        case "fr":
          return fr;
          break;
        case "pt":
          return pt;
          break;
      }
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    }
  },
  data() {
    return {
      componentKey: 0,
      reportS: '',
      reportR: '',
      reportA: '',
      dateRange: '',
      modals: {
        miniStat: false
      }
    };
  },
  methods: {
    //Restricting the ability to select a date according to the datastore setting
    LimitDate(date) {
        return date > new Date() || date < (new Date() - 45 * 86400000)
    },

    //S1 & S2 reports with date interval
    async reportS1S2(){
      this.$notify({
        type: 'info',
        message: this.$t('notifications.reportWait'),
        icon: 'tim-icons icon-time-alarm'
      });

      const Data = {
        'fromdate': this.dateRange[0],
        'todate': this.dateRange[1],
        'regionalTime': new Date().getTimezoneOffset()
      }
      await this.$store.dispatch(this.reportS, Data);
      this.dateRange = '';

      this.$notify({
        type: 'success',
        message: this.$t('notifications.report'),
        icon: 'tim-icons icon-bell-55'
      });
    },

    //R2 & R3 reports
    async reportR2R3(){
      this.$notify({
        type: 'info',
        message: this.$t('notifications.reportWait'),
        icon: 'tim-icons icon-time-alarm'
      });

      await this.$store.dispatch(this.reportR);

      this.$notify({
        type: 'success',
        message: this.$t('notifications.report'),
        icon: 'tim-icons icon-bell-55'
      });
    }
  },
  mounted() {
    // Set start language for date picker
    locale.use(this.componentLanguage)
  },  
  watch: {
    //Change languge of date pick component, when locale changed
    componentLanguage() {
      locale.use(this.componentLanguage)
      this.componentKey += 1; //re-render the component for full locale change (day not changing, need to list day page for re-render)
    }
  }
}
</script>
<style>
.report_des {
  text-align: justify;
  text-indent: 1.5em
}
</style>
