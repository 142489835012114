<template>
  <div>
    <div class="row">
      <base-button round class="animation-on-hover" :type="colorUnification" @click="modals.miniCreate = true" v-if="this.multipleSelection.length === 0" :disabled="loading">{{ $t('buttons.create') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDelUnused = true" v-if="this.multipleSelection.length === 0 && !isMobile" :disabled="loading">{{ $t('buttons.unused') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDelUnused = true" v-if="this.multipleSelection.length === 0 && isMobile" :disabled="loading">
        <i class="tim-icons icon-scissors"></i>
      </base-button>

      <div class="btn-group" style="margin-top: 5px;" v-if="this.multipleSelection.length > 0">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('buttons.changeStatus') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL">
          <a class="dropdown-item" @click="statusChange">{{ $t('buttons.activateNow') }}</a>
          <a class="dropdown-item" @click="modals.miniDelayed = true">{{ $t('buttons.activateDelayed') }}</a>
        </base-dropdown>
      </div>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDel = true" v-if="this.multipleSelection.length > 0 && !isMobile">{{ $t('buttons.delete') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDel = true" v-if="this.multipleSelection.length > 0 && isMobile">
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ $t('sidebar.publications') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table
              :data="queriedData"
              v-loading="loading"
              :empty-text="loading ? $t('dataTable.loading') : $t('dataTable.noData')"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
              >
              </el-table-column>

              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.stateCode)"> {{props.row.stateCode}} </span></p>
                  <p><b>{{ $t('dataTable.type') }}</b>: {{ props.row.typeCode }}</p>
                  <p><b>{{ $t('dataTable.playlist') }}</b>: <router-link :to="'/media/playlistsOne/' + props.row.playlistUid"> {{props.row.playlistName}} </router-link></p>
                  <p><b>{{ $t('dataTable.group') }}</b>: <router-link :to="'/equipments/groupsOne/' + props.row.playpointgroupUid"> {{ props.row.playpointgroupName }} </router-link></p>
                </div>
              </el-table-column>

              <el-table-column
                v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>
              
              <el-table-column v-if="isMobile" width="38">
                <template slot-scope="props">
                  <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.stateCode) + ')'"></span>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>

    <!-- Scroll to top component -->
    <ScrollToTop></ScrollToTop>

    <!--Modal for Delete unused -->
    <modal
      :show.sync="modals.miniDelUnused"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-trash-simple" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDelUnused') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="unusedDelete(), modals.miniDelUnused = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDelUnused = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for Delete selected -->
    <modal
      :show.sync="modals.miniDel"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-trash-simple" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDelSelected') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="Delete(), modals.miniDel = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDel = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for Create -->
    <modal
      :show.sync="modals.miniCreate"
      class="modal-info"
      :show-close="false"
      modal-classes="modal-lg"
      headerClasses="justify-content-center"
      type="notice"
    >
      <ValidationObserver v-slot="{ valid }">
          
        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-bulb-63" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.titleCreatePub') }}</h4>
        
        

        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.name') }}</label>
            <ValidationProvider
              :rules="{ required: true, min: 3, max: 50}"
              v-slot="{ valid }"
              :bails="false" 
            >
              <input
                class="form-control modal-inputs"
                name="valuePubName" type="text"
                v-model="valuePubName"
              >
              <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
              >
                {{ $t('validation.name') }}
              </span>
            </ValidationProvider>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.type') }}</label>
            <div>
              <ValidationProvider
                :rules="{ required: true}"
                v-slot="{ valid }"
                :bails="false" 
              >
                <el-select
                  v-model="valueType"
                  filterable
                  class="select-default modal-inputs"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <span
                    v-if="!valid"
                    style="color: #000080; font-size: 12px;" 
                >
                  {{ $t('validation.required') }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.description') }}</label>
        <ValidationProvider
          :rules="{ required: true, min: 3, max: 150}"
          v-slot="{ valid }"
          :bails="false" 
        >  
          <input
            class="form-control modal-inputs"
            name="valueDiscr" type="text"
            v-model="valueDescr"
          >
          <span
              v-if="!valid"
              style="color: #000080; font-size: 12px;" 
          >
            {{ $t('validation.description') }}
          </span>
        </ValidationProvider>

        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.playlist') }}</label>
            <div>
              <ValidationProvider
              :rules="{ required: true}"
              v-slot="{ valid }"
              :bails="false" 
              >
                <el-select
                  v-model="valuPl"
                  filterable
                  class="select-default modal-inputs"
                >
                  <el-option
                    v-for="item in plList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
                >
                  {{ $t('validation.required') }}
                </span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.group') }}</label>
            <div>
              <ValidationProvider
              :rules="{ required: true}"
              v-slot="{ valid }"
              :bails="false" 
             >
                <el-select
                  v-model="valuGr"
                  filterable
                  class="select-default modal-inputs"
                >
                  <el-option
                    v-for="item in grList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                <span
                    v-if="!valid"
                    style="color: #000080; font-size: 12px;" 
                >
                  {{ $t('validation.required') }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div> 

        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="createPub(), modals.miniCreate = false"
            >{{ $t('modal.confirmCreatePl') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniCreate = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
            
      </ValidationObserver>
    </modal>

    <!--Modal for Delayed activation -->
    <modal
      :show.sync="modals.miniDelayed"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >

      <ValidationObserver v-slot="{ valid }">

        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-link-72" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.titleDelayed') }}</h4>
        
        <ValidationProvider
          :rules="{ required: true}"
          v-slot="{ valid }"
          :bails="false" 
        >
          <el-date-picker
            :key="componentKey"
            :picker-options="{firstDayOfWeek: 1}"
            v-model="delayedDateTime"
            type="datetime"
            :placeholder="$t('modal.placeholderDelayed')">
          </el-date-picker>
          <span
            v-if="!valid"
            style="color: #000080; font-size: 12px;" 
          >
            {{ $t('validation.required') }}
          </span>
        </ValidationProvider>

        <div>
          <base-button
            type="neutral"
            link
            :disabled="!valid"
            @click="statusChangeDelayed(), modals.miniDelayed = false"
            >{{ $t('modal.confirmRename') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniDelayed = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>

      </ValidationObserver>
    </modal>

  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, DatePicker } from 'element-ui';
import { BasePagination, Modal } from 'src/components';
import Fuse from 'fuse.js';
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import { search } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";
import locale from 'element-ui/lib/locale';
import ua from 'element-ui/lib/locale/lang/ua';
import de from 'element-ui/lib/locale/lang/de';
import ru from 'element-ui/lib/locale/lang/ru-RU';
import en from 'element-ui/lib/locale/lang/en';
import cn from 'element-ui/lib/locale/lang/zh-CN';
import ar from 'element-ui/lib/locale/lang/ar';
import es from 'element-ui/lib/locale/lang/es';
import fr from 'element-ui/lib/locale/lang/fr';
import pt from 'element-ui/lib/locale/lang/pt';


extend('required', required);
extend('min', min);
extend('max', max);


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    ScrollToTop
  },
  computed: {
    
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    //Compute curent language
    componentLanguage() {
      switch (this.$i18n.locale.toString()) {
        case "ru":
          return ru;
          break;
        case "en":
          return en;
          break;
        case "de":
          return de;
          break;
        case "ua":
          return ua;
          break;
        case "cn":
          return cn;
          break;
        case "ar":
          return ar;
          break;
        case "es":
          return es;
          break;
        case "fr":
          return fr;
          break;
        case "pt":
          return pt;
          break;
      }
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      componentKey: 0,
      valuePubName: this.$i18n.t('modal.valuePubName'),
      valueDescr: this.$i18n.t('modal.valueDescr'),
      valueType: 'audio',
      loading: true,
      plList: [],
      grList: [],
      tableData: [],
      searchedData: [],
      multipleSelection: [],
      searchQuery: '',
      valuPl: '',
      valuGr: '',
      delayedDateTime: '',
      fuseSearch: null,
      modals: {
        miniDel: false,
        miniDelUnused: false,
        miniCreate: false,
        miniDelayed: false
      },
      typeOptions: [
        {
          value: 'audio',
          label: this.$i18n.t('modal.audioType'),
        }, {
          value: 'video',
          label: this.$i18n.t('modal.videoType'),
        }
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 30,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'publicationsOne/' + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'stateCode',
          label: 'dataTable.status',
          minWidth: 20,
          formatter: (row, col, cellValue, index) => {
            switch (cellValue.toString()) {
              case "ACTIVE":
                return <span slot="header" class="badge badge-pill badge-success"> {cellValue} </span>;
                break;
              case "PREPARE":
                return <span slot="header" class="badge badge-pill badge-citrus"> {cellValue} </span>;
                break;
              case "DELAYED":
                return <span slot="header" class="badge badge-pill badge-info"> {cellValue} </span>;
                break;
            }
          }
        },
        {
          prop: 'typeCode',
          label: 'dataTable.type',
          minWidth: 10
        },
        {
          prop: 'playlistName',
          label: 'dataTable.playlist',
          minWidth: 25,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'/media/playlistsOne/' + row.playlistUid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'playpointgroupName',
          label: 'dataTable.group',
          minWidth: 25,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'/equipments/groupsOne/' + row.playpointgroupUid}> {cellValue} </router-link>
          }
        }
      ]
    };
  },
  methods: {
    // Select colors for status data in mobile view
    stateColor(cellValue) {
      switch (cellValue.toString()) {
        case "ACTIVE":
          return "success";
          break;
        case "PREPARE":
          return "citrus";
          break;
        case "DELAYED":
          return "info";
          break;
      }
    },

    // Fetch Groups list for publication
    async fetchGrList() {
      this.grList = await this.$store.dispatch('fetcListGrForPub', this.valueType)
      if (this.grList.length > 0) {
        this.valuGr = this.grList[0].id
      } else {
        this.valuGr = ''
      }
    },

    // Fetch Playlists list for publication
    async fetchPlList() {
      let smart = await this.$store.dispatch('fetcListPlForPub', this.valueType + '-smart');
      this.plList = await this.$store.dispatch('fetcListPlForPub', this.valueType);
      this.plList = this.plList.concat(smart);

      if (this.plList.length > 0) {
        this.valuPl = this.plList[0].id
      } else {
        this.valuPl = ''
      }
    },

    // Fetch Publications
    async fetchPublications() {
      this.loading = true;
      
      this.tableData = await this.$store.dispatch('fetchPub', 'any')

      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name', 'stateCode'],
        threshold: 0.1
      });

      this.loading = false;
    },

    //Delete all unused elements in table
    async unusedDelete() {
      this.$notify({
        type: 'info',
        message: this.$t('notifications.unConfirmeWait'),
        icon: 'tim-icons icon-time-alarm'
      });

      const answer = await this.$store.dispatch('unusedPubDel');

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.unConfirmePub' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPublications();
    },

    // Status change
    async statusChange() {
      this.$notify({
        type: 'info',
        message: this.$t('notifications.statusConfirmeWait'),
        icon: 'tim-icons icon-bell-55'
      });

      // const answer = await this.$store.dispatch('editPub', this.$route.params.id);

      let answer = '';
      for (const item of this.multipleSelection) {
        let answer_temp = await this.$store.dispatch('editPub', item.uid);
        answer = !['0', '8', '9'].includes(answer_temp.toString()) ? '1' : answer_temp;
      }

      this.$notify({
        type: ['0', '8', '9'].includes(answer.toString()) ? 'success' : 'danger',
        message: this.$t('notifications.statusConfirme' + answer.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPublications();
    },

    // Status change delayed
    async statusChangeDelayed() {
      this.$notify({
        type: 'info',
        message: this.$t('notifications.statusConfirmeWait'),
        icon: 'tim-icons icon-bell-55'
      });

      // const answer = await this.$store.dispatch('editPub', this.$route.params.id);

      let answer = '';
      for (const item of this.multipleSelection) {
        const Data = {
          'uid': item.uid,
          'startdatetime': this.delayedDateTime.toISOString()
        }
        let answer_temp = await this.$store.dispatch('delayedActivation', Data);
        answer = !['0', '8', '9'].includes(answer_temp.toString()) ? '1' : answer_temp;
      }
      
      this.$notify({
        type: ['0', '8', '9'].includes(answer.toString()) ? 'success' : 'danger',
        message: this.$t('notifications.statusConfirme' + answer.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPublications();
    },

    //Delete selected elements in table
    async Delete() {
      this.$notify({
        type: 'info',
        message: this.$t('notifications.unConfirmeWait'),
        icon: 'tim-icons icon-time-alarm'
      });

      let listForDelete = this.multipleSelection.map(element => ({ 
          uid: element.uid
      }));
    
      const answer = await this.$store.dispatch('deletePubMass', listForDelete);
      
      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.unConfirme'+ answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      this.searchQuery = '';
      await this.fetchPublications();
    },

    //Fill selection array when selection is changed by user
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //Create publication
    async createPub(){
      const formData = {
        'name': this.valuePubName,
        'playpointgroupid': this.valuGr,
        'playlistid': this.valuPl,
        'description': this.valueDescr
      };
      const Data = {
        'contentType': this.valueType,
        'formData': formData
      };
                    
      const answer = await this.$store.dispatch('createPub', Data);
      
      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.crtConfirmePub' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPublications();
    }
  },
  async mounted() {
    // Set start language for date picker
    locale.use(this.componentLanguage)

    // Fetch Groups list for publication
    await this.fetchGrList();

    // Fetch Playlists list for publication
    await this.fetchPlList();

    // Fetch Publications
    await this.fetchPublications();

  },
  watch: {

    //Change languge of date pick component, when locale changed
    componentLanguage() {
      locale.use(this.componentLanguage)
      this.componentKey += 1; //re-render the component for full locale change (day not changing, need to list day page for re-render)
    },

    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query*/

    async valueType(value) {
      await this.fetchPlList()
      await this.fetchGrList()
    },
     
    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name', 'stateCode');
    }
  }
};
</script>
<style>
</style>
