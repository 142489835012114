<template>
  <div>
    <div class="row">
      <base-button round class="animation-on-hover" :type="colorUnification" @click="modals.miniUpload = true">{{ $t('buttons.upload') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDelUnused = true" v-if="this.multipleSelection.length === 0 && !isMobile" :disabled="loading">{{ $t('buttons.unused') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDelUnused = true" v-if="this.multipleSelection.length === 0 && isMobile" :disabled="loading">
        <i class="tim-icons icon-scissors"></i>
      </base-button>

      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDel = true" v-if="this.multipleSelection.length > 0 && !isMobile">{{ $t('buttons.delete') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDel = true" v-if="this.multipleSelection.length > 0 && isMobile">
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ $t('sidebar.ads') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData" v-loading="loading" :empty-text="loading ? $t('dataTable.loading') : $t('dataTable.noData')" @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
              >
              </el-table-column>

              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.useCount)"> {{props.row.useCount === 0 ? "Unused" : "Used"}} </span></p>
                  <audio controls class="audio" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>
                </div>
              </el-table-column>

              <el-table-column
                v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>
              
              <el-table-column v-if="isMobile" width="38">
                <template slot-scope="props">
                  <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.useCount) + ')'"></span>
                </template>
              </el-table-column>

              <!--Audio -->
              <el-table-column :min-width="40" :label="$t('dataTable.listen')" v-if="!isMobile">
                <div slot-scope="props">
                  <audio controls class="audio" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    
    <!-- Scroll to top component -->
    <ScrollToTop></ScrollToTop>

    <!--Modal for Delete unused -->
    <modal
      :show.sync="modals.miniDelUnused"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-trash-simple" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDelUnused') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="unusedDelete(), modals.miniDelUnused = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDelUnused = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for Delete selected -->
    <modal
      :show.sync="modals.miniDel"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-trash-simple" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDelSelected') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="Delete(), modals.miniDel = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDel = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for Upload -->
    <modal
      :show.sync="modals.miniUpload"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div class="modal-profile" style="margin: auto;">
        <i class="tim-icons icon-cloud-upload-94" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white" v-if="this.files.length == 0">{{ $t('modal.titleUpl') }}</h4>
      <h4 align="center" style="color: white" v-else>{{ $t('modal.titleUplAlt') }} {{ this.files.length }}</h4>
      
      <input type="file" id="files" accept="audio/*" ref="files" style="display: none" multiple v-on:change="handleFilesUpload()">
      
      <base-button style="margin:auto; display:block" round icon type="default" v-on:click="addFiles()" v-if="uploading == 0">
          <i class="tim-icons icon-attach-87"></i>
      </base-button>

      <div style="height: 20px; margin: 0 20px;" v-else>
        <base-progress :value="uploading"></base-progress>
      </div>

      <h5 align="center" style="color: white" v-if="uploading == 100">{{ $t('modal.titleUplWait') }}</h5>

      <div>
        <base-button 
          type="neutral"
          link
          :disabled="this.files.length == 0 || uploading > 0 ? true : false"
          @click="submitFiles"
          >{{ $t('modal.confirmUpl') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniUpload = false, files = '', $refs.files.value = null"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Modal, BaseProgress } from 'src/components';
import Fuse from 'fuse.js';
import { search } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";

export default {
  components: {
    BaseProgress,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ScrollToTop
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    //Get % of uploading
    uploading() {
      return this.$store.getters.uploading
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      loading: true,
      tableData: [],
      searchedData: [],
      multipleSelection: [],
      searchQuery: '',
      files: '',
      fuseSearch: null,
      modals: {
        miniDel: false,
        miniDelUnused: false,
        miniUpload: false
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 30,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'adsOne/' + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'useCount',
          label: 'dataTable.status',
          minWidth: 20,
          formatter: (row, col, cellValue, index) => {           
          if (cellValue === 0) {
            return <span slot="header" class="badge badge-pill badge-citrus"> Unused </span>
          } else
            return <span slot="header" class="badge badge-pill badge-success"> Used </span>
          }
        }
      ]
    };
  },
  methods: {
    // Select colors for status data in mobile view
    stateColor(cellValue) {         
      if (cellValue === 0) {
        return "citrus"
      } else
        return "success"
    },
    
    // Fetch media list
    async fetchMedia() {
      this.loading = true;

      const Data = {
        'contentType': 'audio-com'
      }
      this.tableData = await this.$store.dispatch('fetchMedia', Data);

      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name'],
        threshold: 0.1
      });

      this.loading = false;
    },

    //Replace the Input by button click
    addFiles(){
      this.$refs.files.click();
    },

    //Save selected files to local variable
    handleFilesUpload(){
      this.files = this.$refs.files.files;
    },

    //Send files to the server
    async submitFiles(){
      let formData = new FormData();
      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];
        formData.append('files[' + i + ']', file);
      }
      
      const Data = {
        'contentType': 'audio-com',
        'files': formData
      }
      const answer = await this.$store.dispatch('uploadMedia', Data);
      
      const error = answer.statusCode.toString() === '902' ? answer.headers[0].value.toString() : '';

      this.$notify({
          type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
          message: this.$t('notifications.uplConfirmeMel'+ answer.statusCode.toString()) + error,
          icon: 'tim-icons icon-bell-55'
        });

      this.files = '';
      this.$refs.files.value = null;
      await this.$store.commit('setUpload', 0);
      this.modals.miniUpload = false;

      await this.fetchMedia();
    },
    
    //Delete all unused elements in table
    async unusedDelete() {
      this.$notify({
        type: 'info',
        message: this.$t('notifications.unConfirmeWait'),
        icon: 'tim-icons icon-time-alarm'
      });

      const Data = {
        'contentType': 'audio-com'
      };

      const answer = await this.$store.dispatch('unusedMediaDel', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.unConfirmeAd' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchMedia();
    },

    //Delete selected elements in table
    async Delete() {
      this.$notify({
        type: 'info',
        message: this.$t('notifications.unConfirmeWait'),
        icon: 'tim-icons icon-time-alarm'
      });

      let listForDelete = this.multipleSelection.map(element => ({ 
          uid: element.uid
      }));
      
      const Data = {
        'contentType': 'audio-com',
        'forDelete': listForDelete
      }
      
      let answer = await this.$store.dispatch('deleteMediaMass', Data);
      
      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.unConfirme'+ answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      this.searchQuery = '';
      await this.fetchMedia();
    },

    //Fill selection array when selection is changed by user
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }    
  },
  async mounted() {
    // Fetch media list
    await this.fetchMedia();

  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query*/
     
    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name');
    }
  }
};
</script>
<style>
</style>
