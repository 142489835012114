<template>
  <div>
    <div class="row">
      <div class="btn-group" style="margin-top: 5px;">
        <base-dropdown :title="`\u00A0 \u00A0 \u00A0 \u00A0` + $t('buttons.action') + `\u00A0 \u00A0 \u00A0 \u00A0`" :title-classes="'btn btn-round btn-' + colorUnification" :menuOnRight="$rtl.isRTL" :disabled="loading">
          <a class="dropdown-item" @click="editPublication" v-if="!isEdit && !loading">{{ $t('buttons.edit') }}</a>       
          <h6 class="dropdown-header" v-if="isEdit">{{ $t('buttons.activate') }}</h6>
          <a class="dropdown-item" @click="editPublication" v-if="isEdit">{{ $t('buttons.activateNow') }}</a>
          <a class="dropdown-item" @click="modals.miniDelayed = true" v-if="isEdit">{{ $t('buttons.activateDelayed') }}</a>
          <div class="dropdown-divider" v-if="isEdit"></div>
          <a class="dropdown-item" @click="modals.miniRename = true" v-if="isEdit">{{ $t('buttons.rename') }}</a>
        </base-dropdown>
      </div>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDel = true" v-if="isEdit && !isMobile">{{ $t('buttons.delete') }}</base-button>    
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDel = true" v-if="isEdit && isMobile">
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </div>
    
    <div class="row mt-2">
      <div class="col-12">  
        <card v-loading="loading">
          <h4 class="card-title">{{ record.name }}
            <span slot="header" :class="badgeColor">
              {{ record.stateCode.toString() === 'DELAYED' ? (record.stateCode + ' ' + new Date(record.delayedTime).toLocaleString()) : record.stateCode }}
            </span>
          </h4>
          
          <small class="text-muted"><b>{{ $t('publication.description') }}</b>
            {{ record.description }}
          </small> 
          
          <hr color="grey" border="none">
          
          <div  class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="row">
                <div class="col"><h5>{{ $t('dataTable.type') }}:</h5></div>
                <div class="col" ><h5> {{ this.record.typeCode }} </h5></div>
              </div>

              <div class="row">
                <div class="col"><h5>{{ $t('dataTable.version') }}:</h5></div>
                <div class="col" ><h5> {{ this.record.version }} </h5></div>
              </div>
            </div>
          </div>
        </card>
        
        <div  class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">    
            <card>
              <h4 slot="header" class="card-title">{{ $t('dataTable.inGroup') }}
                <router-link :to="'/equipments/groupsOne/' + record.playpointgroupUid">
                  {{ curentBindGr.name }}
                </router-link>
                <span slot="header" :class="[curentBindGr.status === 'ACTIVE' ? 'badge badge-pill badge-success' : 'badge badge-pill badge-citrus']">
                  {{ curentBindGr.status }}
                </span>
              </h4>
              
              <div>
                <div
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <el-select
                    :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                    :type="colorUnification"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      :class="['select-'+ colorUnification]"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>

                  <base-input>
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      :placeholder="$t('profileAction.search')"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </base-input>
                </div>
                <el-table :data="queriedData" v-loading="loading2" :empty-text="loading2 ? $t('dataTable.loading') : $t('dataTable.noData')">
                  <el-table-column type="expand" v-if="isMobile" width="25">
                    <div slot-scope="props">
                      <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.lastheartbeatStateCode)"> {{props.row.lastheartbeatStateCode}} </span></p>
                    </div>
                  </el-table-column>
                  
                  <el-table-column
                    v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :prop="column.prop"
                    :label="$t(column.label)"
                    :formatter="column.formatter"
                    sortable
                    :align="$rtl.isRTL ? 'right' : 'left'"
                  >
                  </el-table-column>

                  <el-table-column v-if="isMobile" width="38">
                    <template slot-scope="props">
                      <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.lastheartbeatStateCode) + ')'"></span>
                    </template>
                  </el-table-column>

                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                    {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                    {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                    {{ $t('pagination.4') }}
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  :type="colorUnification"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </card>    
          </div>
          
          <div class="col-lg-6 col-md-6 col-sm-6">    
            <card>
              <h4 slot="header" class="card-title">{{ $t('dataTable.inPlaylist') }}
                <router-link :to="'/media/playlistsOne/' + record.playlistUid">
                  {{ curentBindPl.name }}
                </router-link>
                <span slot="header" :class="[curentBindPl.status === 'ACTIVE' ? 'badge badge-pill badge-success' : 'badge badge-pill badge-citrus']">
                  {{ curentBindPl.status }}
                </span>
              </h4>
              <div>
                <div
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <el-select
                    :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                    :type="colorUnification"
                    v-model="pagination2.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      :class="['select-'+ colorUnification]"
                      v-for="item in pagination2.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>

                  <base-input>
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      :placeholder="$t('profileAction.search')"
                      v-model="searchQuery2"
                      aria-controls="datatables"
                    >
                    </el-input>
                  </base-input>
                </div>
                <el-table :data="queriedData2" v-loading="loading3" :empty-text="loading3 ? $t('dataTable.loading') : $t('dataTable.noData')">
                  <el-table-column type="expand" v-if="isSmart && isMobile" width="25">
                    <div slot-scope="props">
                      <p v-if="isSmart"><b>{{ $t('dataTable.time') }}</b>: {{ props.row.start }}</p>
                    </div>
                  </el-table-column>

                  <el-table-column
                    v-for="column in tableColumns2"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :prop="column.prop"
                    :label="$t(column.label)"
                    :formatter="column.formatter"
                    sortable
                    :align="$rtl.isRTL ? 'right' : 'left'"
                  >
                  </el-table-column>

                  <!--Start play time for smart Playlist -->
                  <el-table-column :min-width="30" :label="$t('dataTable.time')" v-if="isSmart && !isMobile">
                    <div slot-scope="props">
                      {{ props.row.start }}
                    </div>
                  </el-table-column>

                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from2 + 1) }}
                    {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to2) }}
                    {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total2) }}
                    {{ $t('pagination.4') }}
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  :type="colorUnification"
                  v-model="pagination2.currentPage"
                  :per-page="pagination2.perPage"
                  :total="total2"
                >
                </base-pagination>
              </div>
            </card>   
          </div>
        </div>

      </div>
    </div>

    <!-- Scroll to top component -->
    <ScrollToTop></ScrollToTop>

    <!--Modal for Delete -->
    <modal
      :show.sync="modals.miniDel"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-bell-55" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDel') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="deletePublication(), modals.miniDel = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDel = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for Rename -->
    <modal
      :show.sync="modals.miniRename"
      class="modal-info"
      :show-close="false"
      modal-classes="modal-lg"
      headerClasses="justify-content-center"
      type="notice"
    >
      <ValidationObserver v-slot="{ valid }">
          
        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-pencil" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.renameTitle') }}</h4>
        
        <div  class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.name') }}</label>
            <ValidationProvider
              :rules="{ required: true, min: 3, max: 50}"
              v-slot="{ valid }"
              :bails="false" 
            >
              <input
                class="form-control modal-inputs"
                name="valuePubName" type="text"
                v-model="valuePubName"
              >
              <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
              >
                {{ $t('validation.name') }}
              </span>
            </ValidationProvider>  
            
            <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.description') }}</label>
            <ValidationProvider
              :rules="{ required: true, min: 3, max: 150}"
              v-slot="{ valid }"
              :bails="false" 
              >
                <input
                  class="form-control modal-inputs"
                  name="valueDiscr" type="text"
                  v-model="valueDescr"
                >
                <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
                >
                  {{ $t('validation.description') }}
                </span>
              </ValidationProvider>    
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.playlist') }}</label>
            <div>
              <ValidationProvider
              :rules="{ required: true}"
              v-slot="{ valid }"
              :bails="false" 
              >
                <el-select
                  v-model="valuePl"
                  :value="valuePl"
                  filterable
                  class="select-default modal-inputs"
                >
                  <el-option
                    v-for="item in plList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
                >
                  {{ $t('validation.required') }}
                </span>
              </ValidationProvider>  
            </div>
            
            <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.group') }}</label>
            <div>
              <ValidationProvider
              :rules="{ required: true}"
              v-slot="{ valid }"
              :bails="false" 
              >
                <el-select
                  v-model="valueGr"
                  :value="valueGr"
                  filterable
                  class="select-default modal-inputs"
                >
                  <el-option
                    v-for="item in grList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
                >
                  {{ $t('validation.required') }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div>  

        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="renamePublication(), modals.miniRename = false"
            >{{ $t('modal.confirmRename') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniRename = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>
            
      </ValidationObserver>
    </modal>

    <!--Modal for Delayed activation -->
    <modal
      :show.sync="modals.miniDelayed"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >

      <ValidationObserver v-slot="{ valid }">

        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-link-72" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.titleDelayed') }}</h4>
        
        <ValidationProvider
          :rules="{ required: true}"
          v-slot="{ valid }"
          :bails="false" 
        >
          <el-date-picker
            :key="componentKey"
            :picker-options="{firstDayOfWeek: 1}"
            v-model="delayedDateTime"
            type="datetime"
            :placeholder="$t('modal.placeholderDelayed')">
          </el-date-picker>
          <span
            v-if="!valid"
            style="color: #000080; font-size: 12px;" 
          >
            {{ $t('validation.required') }}
          </span>
        </ValidationProvider>

        <div>
          <base-button
            type="neutral"
            link
            :disabled="!valid"
            @click="delayedActivation(), modals.miniDelayed = false"
            >{{ $t('modal.confirmRename') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniDelayed = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>

      </ValidationObserver>
    </modal>

  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, DatePicker } from 'element-ui';
import { BasePagination, Modal } from 'src/components';
import Fuse from 'fuse.js';
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import { search } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";
import locale from 'element-ui/lib/locale';
import ua from 'element-ui/lib/locale/lang/ua';
import de from 'element-ui/lib/locale/lang/de';
import ru from 'element-ui/lib/locale/lang/ru-RU';
import en from 'element-ui/lib/locale/lang/en';
import cn from 'element-ui/lib/locale/lang/zh-CN';
import ar from 'element-ui/lib/locale/lang/ar';
import es from 'element-ui/lib/locale/lang/es';
import fr from 'element-ui/lib/locale/lang/fr';
import pt from 'element-ui/lib/locale/lang/pt';



extend('required', required);
extend('min', min);
extend('max', max);


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    ScrollToTop
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },
    
    //Compute curent language
    componentLanguage() {
      switch (this.$i18n.locale.toString()) {
        case "ru":
          return ru;
          break;
        case "en":
          return en;
          break;
        case "de":
          return de;
          break;
        case "ua":
          return ua;
          break;
        case "cn":
          return cn;
          break;
        case "ar":
          return ar;
          break;
        case "es":
          return es;
          break;
        case "fr":
          return fr;
          break;
        case "pt":
          return pt;
          break;
      }
    },

    //Compute badge color
    badgeColor() {
      switch (this.record.stateCode.toString()) {
        case "ACTIVE":
          return "badge badge-pill badge-success";
          break;
        case "PREPARE":
          return "badge badge-pill badge-citrus";
          break;
        case "DELAYED":
          return "badge badge-pill badge-info";
          break;
      }
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    queriedData2() {
      let result = this.tableData2;
      if (this.searchedData2.length > 0) {
        result = this.searchedData2;
      }
      return result.slice(this.from2, this.to2);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.tableData2.length;
    }
  },
  data() {
    return {
      componentKey: 0,
      loading: true,
      loading2: true,
      loading3: true,
      loading4: true,
      loading5: true,
      isEdit: false,
      isSmart: false,
      plList: [],
      grList: [],
      record: {
        stateCode: ''
      },     
      record2: [],
      tableData: [],
      tableData2: [],
      searchedData: [],
      searchedData2: [],
      searchQuery: '',
      searchQuery2: '',
      valuePl: '',
      valueGr: '',
      delayedDateTime: '',
      valuePubName: null,
      valueDescr: null,
      fuseSearch: null,
      fuseSearch2: null,
      curentBindPl: {
        name: null,
        status: null
      },
      curentBindGr: {
        name: null,
        status: null
      },
      modals: {
        miniDel: false,
        miniRename: false,
        miniDelayed: false
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      pagination2: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 60,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'/equipments/devicesOne/' + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'lastheartbeatStateCode',
          label: 'dataTable.status',
          minWidth: 40,
          formatter: (row, col, cellValue, index) => {           
            switch (cellValue.toString()) {
              case "neverup":
                return <span slot="header" class="badge badge-pill badge-info"> {cellValue} </span>;
                break;
              case "online":
                return <span slot="header" class="badge badge-pill badge-success"> {cellValue} </span>;
                break;
              case "linklost":
                return <span slot="header" class="badge badge-pill badge-citrus"> {cellValue} </span>;
                break;
              case "linkdown":
                return <span slot="header" class="badge badge-pill badge-danger"> {cellValue} </span>;
                break;
            }
          }
        }
      ],
      tableColumns2: [
        {
          prop: 'order',
          label: 'dataTable.№',
          minWidth: 20,
          formatter: (row, col, cellValue, index) => {
            return cellValue/10
          }
        },
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 50,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={((this.record.typeCode == 'VIDEO') ? '/media/videosOne/' : '/media/melodiesOne/') + row.uid}> {cellValue} </router-link>
          }
        }
      ]
    };
  },
  methods: {
    // Select colors for status data in mobile view
    stateColor(cellValue) {
      switch (cellValue.toString()) {
        case "online":
          return "success";
          break;
        case "linklost":
          return "citrus";
          break;
        case "linkdown":
          return "danger";
          break;
        case "neverup":
          return "info";
          break;
      }
    },

    // Fetch Publication by ID for head of page
    async fetchPubById() {
      this.loading = true;
      this.record = await this.$store.dispatch('fetchPubById', this.$route.params.id);
      this.isEdit = this.record.stateCode === 'ACTIVE' || this.record.stateCode === 'DELAYED' ? false : true;
      this.valuePubName = this.record.name;
      this.valueDescr = this.record.description;   
      this.loading = false;
    },

    // Fetch Group binded by this publication
    async fetchGroup() {
      this.loading2 = true;

      this.tableData = await this.$store.dispatch('fetchGrById', this.record.playpointgroupUid) 
      this.curentBindGr.name = this.tableData.name;
      this.curentBindGr.status = this.tableData.stateCode; 
      this.valueGr = this.tableData.id;
      this.tableData = this.tableData.playpoints;

      // First Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name', 'lastheartbeatStateCode'],
        threshold: 0.1
      });

      this.loading2 = false;
    },

    // Fetch Playlist binded by this publication
    async fetchPlaylist() {
      this.loading3 = true;

      this.tableData2 = await this.$store.dispatch('fetchPlContent', this.record.playlistUid);
      this.isSmart = this.tableData2.typeCode === 'AUDIO-SMART' || this.record.typeCode === 'VIDEO-SMART' ? true : false;
      this.curentBindPl.name = this.tableData2.name;
      this.curentBindPl.status = this.tableData2.stateCode;
      this.valuePl = this.tableData2.id;
      this.tableData2 = this.tableData2.tracks;
      
      // Second Fuse search initialization.
      this.fuseSearch2 = new Fuse(this.tableData2, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name'],
        threshold: 0.1
      });

      this.loading3 = false;
    },

    // Fetch Groups list for publication
    async fetchGrList() {
      this.loading4 = true;

      this.grList = await this.$store.dispatch('fetcListGrForPub', this.record.typeCode);

      this.loading4 = false;
    },

    // Fetch Playlists list for publication
    async fetchPlList() {
      this.loading5 = true;

      let smart = await this.$store.dispatch('fetcListPlForPub', this.record.typeCode + '-smart');
      this.plList = await this.$store.dispatch('fetcListPlForPub', this.record.typeCode);
      this.plList = this.plList.concat(smart);

      this.loading5 = false;
    },

    // Status change
    async editPublication() {
      const answer = await this.$store.dispatch('editPub', this.$route.params.id);
      
      this.$notify({
        type: ['0', '8', '9'].includes(answer.toString()) ? 'success' : 'danger',
        message: this.$t('notifications.editConfirmePub'+ answer.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPubById();
    },

    // Delayed activation
    async delayedActivation() {
      const Data = {
        'uid': this.$route.params.id,
        'startdatetime': this.delayedDateTime.toISOString()
      };
      const answer = await this.$store.dispatch('delayedActivation', Data);
      
      this.$notify({
        type: ['0', '9'].includes(answer.toString()) ? 'success' : 'danger',
        message: this.$t('notifications.editConfirmePub'+ answer.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchPubById();
    },

    //Rename publication
    async renamePublication(){
      const Data = {
        'name': this.valuePubName,
        'uid': this.record.uid,
        'playpointgroupid': this.valueGr,
        'playlistid': this.valuePl,
        'description': this.valueDescr
      };
      
      const answer = await this.$store.dispatch('renamePub', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.renConfirmePub' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      })

      await this.fetchPubById();
      await this.fetchGroup();
      await this.fetchPlaylist();
    },

    //Delete the element
    async deletePublication() {
      const Data = {
        'uid': this.$route.params.id
      };
    
      const answer = await this.$store.dispatch('deletePub', Data);

      this.$notify({
        type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
        message: this.$t('notifications.delConfirmePub' + answer.statusCode.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      this.$router.push('/deployments/publications');
    }
  },
  async mounted() {
    // Set start language for date picker
    locale.use(this.componentLanguage)

    // Fetch Publication by ID for head of page
    await this.fetchPubById()

    // Fetch group in publication
    await this.fetchGroup()

    // Fetch playlist in publication
    await this.fetchPlaylist()
    
    // Fetch Groups list for publication
    await this.fetchGrList()

    // Fetch Playlists list for publication
    await this.fetchPlList()

  },
  watch: {
    //Change languge of date pick component, when locale changed
    componentLanguage() {
      locale.use(this.componentLanguage)
      this.componentKey += 1; //re-render the component for full locale change (day not changing, need to list day page for re-render)
    },

    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name', 'lastheartbeatStateCode');
    },
    searchQuery2(value) {
      this.searchedData2 = search(value, this.tableData2, this.fuseSearch2, 'name');
    }
  }
};
</script>
<style>
</style>
