<template>
  <div>
    <div class="row mt-2">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Playlist</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData" v-loading="loading" :empty-text="loading ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('admLog.receiveTime') }}</b>: {{ new Date(props.row.svc_getpackagedate).toLocaleString() }}</p>
                  <p><b>{{ $t('admLog.playlist') }}</b>: <router-link :to="'/media/playlistsOne/' + props.row.playlistuid"> {{props.row.playlist}} </router-link></p>
                  <p><b>{{ $t('admLog.version') }}</b>: {{ props.row.playlistversion }}</p>
                  <p><b>{{ $t('admLog.track') }}</b>: <router-link :to="'/media/melodiesOne/' + props.row.playtrackuid"> {{props.row.playtrackname}} </router-link></p>
                </div>
              </el-table-column>

              <el-table-column
                v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import { search } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ScrollToTop
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      loading: true,
      tableData: [],
      searchedData: [],
      searchQuery: '',
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'datetimeObject',
          label: 'admLog.eventTime',
          minWidth: 20,
          formatter: (cellrow, col, cellValue, indexValue) => {
            return new Date(cellValue).toLocaleString()
          }
        },
        {
          prop: 'svc_clientreadtime',
          label: 'admLog.receiveTime',
          minWidth: 15,
          formatter: (row, col, cellValue, index) => { 
            return new Date(cellValue).toLocaleString()
          }
        },
        {
          prop: 'playlist.name',
          label: 'admLog.playlist',
          minWidth: 25,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={'/media/playlistsOne/' + row.playlist.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'playlist.typecode',
          label: 'admLog.type',
          minWidth: 15
        },
        {
          prop: 'playlist.version',
          label: 'admLog.version',
          minWidth: 25
        }
      ]
    };
  },
  methods: {
    async fetchLogs() {
      this.loading = true

      this.tableData = await this.$store.dispatch('PlaylistLog', this.$route.params.id);
      
      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['playlist.name'],
        threshold: 0.1
      });

      this.loading = false; 
    }
  },
  async mounted() {
    // Fetch data from server by AXIOS - JSON.
    await this.fetchLogs();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query*/
     
    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'playlist.name');
    }
  }
};
</script>
<style>
</style>
