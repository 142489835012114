<template>
  <div>
    <div class="row">
      <base-button round class="animation-on-hover" :type="colorUnification" @click="modals.miniCreate = true" v-if="this.multipleSelection.length == 0" :disabled="true">{{ $t('buttons.create') }}</base-button>
      <base-button round class="animation-on-hover" :type="colorUnification" @click="statusChange" v-if="this.multipleSelection.length > 0">{{ $t('buttons.changeStatus') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" type="danger" @click="modals.miniDel = true" v-if="this.multipleSelection.length > 0 && !isMobile" :disabled="true">{{ $t('buttons.delete') }}</base-button>
      <base-button round class="animation-on-hover" :class="$rtl.isRTL ? 'btn-left' : 'btn-right'" icon type="danger" @click="modals.miniDel = true" v-if="this.multipleSelection.length > 0 && isMobile" :disabled="true">
        <i class="tim-icons icon-trash-simple"></i>
      </base-button>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ $t('sidebar.packagePub') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData" v-loading="loading" :empty-text="loading ? $t('dataTable.loading') : $t('dataTable.noData')" @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
              >
              </el-table-column>

              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('dataTable.status') }}</b>: <span slot="header" :class="'badge badge-pill badge-' + stateColor(props.row.state.code)"> {{props.row.state.code}} </span></p>
                  <p><b>{{ $t('dataTable.channel') }}</b>: {{ props.row.channel.name }}</p>
                  <p><b>{{ $t('dataTable.changed') }}</b>: {{ new Date(props.row.changedate).toLocaleString() }}</p>
                </div>
              </el-table-column>
              
              <el-table-column
                v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>

              <el-table-column v-if="isMobile" width="38">
                <template slot-scope="props">
                  <span :style="'height: 14px; width: 14px; border-radius: 50%; display: inline-block; background-color: var(--' + stateColor(props.row.state.code) + ')'"></span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import { search } from "@/util/functions.js";
import ScrollToTop from "../Layout/ScrollToTop.vue";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ScrollToTop
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      loading: true,
      tableData: [],
      searchedData: [],
      multipleSelection: [],
      searchQuery: '',
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'package.name',
          label: 'dataTable.name',
          minWidth: 35
        },
        {
          prop: 'state.code',
          label: 'dataTable.status',
          minWidth: 10,
          formatter: (row, col, cellValue, index) => {           
          if (cellValue==='ACTIVE') {
            return <span slot="header" class="badge badge-pill badge-success"> {cellValue} </span>
          } else
            return <span slot="header" class="badge badge-pill badge-citrus"> {cellValue} </span>
          }
        },
        {
          prop: 'channel.name',
          label: 'dataTable.channel',
          minWidth: 35
        },
        {
          prop: 'changedate',
          label: 'dataTable.changed',
          minWidth: 20,
          formatter: (cellrow, col, cellValue, indexValue) => {
            return new Date(cellValue).toLocaleString()
          }
        }
      ]
    };
  },
  methods: {
    // Select colors for status data in mobile view
    stateColor(cellValue) {
      if (cellValue==='ACTIVE') {
        return "success"
      } else
        return "citrus"
    },

    // Fetch device list
    async fetchDepPack() {
      this.loading = true;

      this.tableData = await this.$store.dispatch('fetchUpdDepPack');

      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name'],
        threshold: 0.1
      });

      this.loading = false;
    },
    
    // Status change
    async statusChange() {
      this.$notify({
        type: 'info',
        message: this.$t('notifications.statusConfirmeWait'),
        icon: 'tim-icons icon-bell-55'
      });

      let answer = '';
      for (const item of this.multipleSelection) {
        let answer_temp = await this.$store.dispatch('editUpdDepPack', item.uid);
        answer = answer === '1' ? '1' : answer_temp;
      }
      
      this.$notify({
        type: answer.toString() === '1' ? 'danger' : 'success',
        message: this.$t('notifications.statusConfirme' + answer.toString()),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchDepPack() 
    },
    
    //Fill selection array when selection is changed by user
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  },
  async mounted() {
    // Fetch data from server by AXIOS - JSON.
    await this.fetchDepPack();
    
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query*/
     
    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name');
    }
  }
};
</script>
<style>
</style>
