<template>
  <div>
    
    <div class="row mt-2">
      <div class="col-12">
        <div  class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <card card-body-classes="table-full-width" v-loading="loading">
              <h4 class="card-title">{{ record.name }}
                <span slot="header" :class="badgeColor">
                  {{ record.lhbstatecode }}  {{ new Date(record.lhbplaypointdatetime).toLocaleString() }}
                </span>
              </h4>

              <small class="text-muted"><b>{{ $t('device.description') }}</b>
                {{ record.description }}
              </small> 

              <hr color="grey" border="none">

              <div class="row">
                <div class="col"><h5>{{ $t('device.licKey') }}</h5></div>
                <div class="col"><h5>{{ record.licensekey }}</h5></div>
              </div>
              <br>
              <div class="row">
                  <div class="col"><h5>{{ $t('device.model') }}</h5></div>
                  <div class="col"><h5>{{ record.typeCode }}</h5></div>
              </div>
              <br>
              <div class="row">
                  <div class="col"><h5>{{ $t('device.expire') }}</h5></div>
                  <div class="col"><h5>{{ new Date(record.licenseexpiredate).toLocaleString() }}</h5></div>
              </div>
              <br>
              <div class="row">
                  <div class="col"><h5>{{ $t('device.inGroup') }}</h5></div>
                  <div class="col">
                    <h5 v-if="record.groupuid !=='-'">
                      <!-- <router-link :to="'/equipments/groupsOne/' + record.groupuid"> -->
                        {{ record.groupname }}
                      <!-- </router-link>   -->
                    </h5>
                    <h5 v-else> {{ record.groupname }} </h5>
                  </div>
              </div>
              <br>
              <div class="row">
                  <div class="col"><h5>{{ $t('device.curentPl') }}</h5></div>
                  <div class="col">
                    <h5 v-if="record.playlistuid !== null">
                      <!-- <router-link :to="'/media/playlistsOne/' + record.playlistuid"> -->
                        {{ record.playlistname }}
                      <!-- </router-link>   -->
                    </h5>
                    <h5 v-else> - </h5>
                  </div>
              </div>
              <br>
              <div class="row">
                  <div class="col"><h5>{{ $t('device.curentPub') }}</h5></div>
                  <div class="col">
                    <h5 v-if="record.deployuid !== null">
                      <!-- <router-link :to="'/deployments/publicationsOne/' + record.deployuid"> -->
                        {{ record.deployname }}
                      <!-- </router-link>   -->
                    </h5>
                    <h5 v-else> - </h5>
                  </div>
              </div> 

            </card>

            <card card-body-classes="table-full-width" v-loading="loading">
              <h4 class="card-title">{{ $t('device.techInfo') }}</h4>

              <small class="text-muted"><b>{{ $t('device.description') }}</b>
                {{ record.lhbplaypointcomment }}
              </small> 

              <hr color="grey" border="none">

              <div class="row">
                <div class="col"><h5>{{ $t('device.created') }}</h5></div>
                <div class="col"><h5>{{ new Date(record.createdate).toLocaleString() }}</h5></div>
              </div>
              <br>
              <div class="row">
                <div class="col"><h5>{{ $t('device.changed') }}</h5></div>
                <div class="col"><h5>{{ new Date(record.changedate).toLocaleString() }}</h5></div>
              </div>
              <br>
              <div class="row">
                  <div class="col"><h5>{{ $t('device.version') }}</h5></div>
                  <div class="col"><h5>{{ record.lhbplaypointrevision }}</h5></div>
              </div>
              <br>
              <div v-if="record.playpointmetrics != null">
                <div class="row">
                    <div class="col"><h5>{{ $t('device.ip') }}</h5></div>
                    <div class="col"><h5>{{ record.playpointmetrics.length > 12 ? record.playpointmetrics[11].pointvalue : '-' }}</h5></div>
                </div>
                <br>
                <div class="row">
                    <div class="col"><h5>{{ $t('device.mac') }}</h5></div>
                    <div class="col"><h5>{{ record.playpointmetrics.length > 12 ? record.playpointmetrics[12].pointvalue : '-' }}</h5></div>
                </div>
                <br>
                <div class="row">
                    <div class="col"><h5>{{ $t('device.temp') }}</h5></div>
                    <div class="col"><h5>{{ record.playpointmetrics.length > 12 ? record.playpointmetrics[10].pointvalue + record.playpointmetrics[10].pointparamtype : '-' }}</h5></div>
                </div>
              </div>
            </card>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <card card-body-classes="table-full-width" v-loading="loading">
              <h4 class="card-title" v-if="!isOnline">{{ $t('playlist.onlinePlay') }}</h4>

              <div align="center">
                <base-button
                  round
                  class="animation-on-hover"
                  :type="colorUnification"
                  @click="onlinePlayer()"
                  v-if="isOnline"
                >
                  <i class="tim-icons icon-wifi"></i>{{ $t('playlist.onlinePlay') }}
                </base-button>

                <audio controls ref='audio_player' @ended="nextMedia()" controlslist="nodownload" class="audio" v-show="!isOnline && isAudio"></audio>
                <video controls ref='video_player' @ended="nextMedia()" controlslist="nodownload" class="video" v-show="!isOnline && !isAudio"></video>
                
                <div v-if="!isOnline && !loading3">
                  <small class="text-muted"><b>{{ $t('playlist.curent') }}</b>
                    <!-- <router-link
                      :to="((record.typeCode == 'VIDEO') ? '/media/videosOne/' : '/media/melodiesOne/') + this.tableData[this.curentMedia].uid"
                    > -->
                      {{ this.tableData[this.curentMedia].name }}
                    <!-- </router-link> -->
                  </small>
                </div>
              </div>
            </card>

            <h4 class="card-title" style="text-align: center">{{ $t('device.lastPlayed') }}</h4>
          
            <time-line v-loading="loading2" type="simple">
              <time-line-item v-for="tracks in record2" :key="tracks.playTime"
                inverted
                :badge-type="colorUnification"
                badge-icon="tim-icons icon-volume-98"
              >
                <div slot="header">
                  <span  :class="badgeColor"
                    >{{ new Date(tracks.playTime).toLocaleString() }}
                    </span> 
                    <label class="float-right">
                      {{ tracks.playList }}
                    </label>
                </div>
                <div slot="content" style="margin: -5px 0px;">
                    <!-- <router-link :to="((record.typeCode == 'VIDEO') ? '/media/videosOne/' : '/media/melodiesOne/') + tracks.trackUid"> -->
                      <p>
                        {{ tracks.trackName }}
                      </p>
                    <!-- </router-link>                     -->
                </div>

              </time-line-item>

            </time-line> 
            
          </div>
        </div>
      </div>
    </div>

    <!--Modal for Delete -->
    <modal
      :show.sync="modals.miniDel"
      class="modal-danger"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div slot="header">
        <i class="tim-icons icon-bell-55" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.titleDel') }}</h4>
      <p align="center" style="color: white">{{ $t('modal.textDel') }}</p>
      <div>
        <base-button 
          type="neutral"
          link
          @click="deleteDevice(), modals.miniDel = false"
          >{{ $t('modal.confirmDel') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniDel = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for Rename -->
    <modal
      :show.sync="modals.miniRename"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <ValidationObserver v-slot="{ valid }">
          
        <div class="modal-profile" style="margin: auto;">
          <i class="tim-icons icon-pencil" style="font-size: 3em"></i>
        </div>
        <h4 align="center" style="color: white">{{ $t('modal.renameTitle') }}</h4>
        
        <div class="col">
          <label class="form-control-label text-white" for="newpl_name">{{ $t('modal.name') }}</label>
          <div>
            <ValidationProvider
              :rules="{ required: true, min: 3, max: 50}"
              v-slot="{ valid }"
              :bails="false" 
            >
              <input
                class="form-control modal-inputs"
                name="valueDevName" type="text"
                v-model="valueDevName"
              >
              <span
                  v-if="!valid"
                  style="color: #000080; font-size: 12px;" 
              >
                {{ $t('validation.name') }}
              </span>
            </ValidationProvider>
          </div>
          
          <label style="margin-top: 15px;" class="form-control-label text-white" for="newpl_name">{{ $t('modal.description') }}</label>
          <ValidationProvider
            :rules="{ required: true, min: 3, max: 150}"
            v-slot="{ valid }"
            :bails="false" 
          >
            <input
              class="form-control modal-inputs"
              name="valueDiscr" type="text"
              v-model="valueDescr"
            >
            <span
              v-if="!valid"
              style="color: #000080; font-size: 12px;" 
            >
              {{ $t('validation.description') }}
            </span>
          </ValidationProvider>
        </div>   

        <div>
          <base-button 
            type="neutral"
            link
            :disabled="!valid"
            @click="renameDevice(), modals.miniRename = false"
            >{{ $t('modal.confirmRename') }}
          </base-button>
          <base-button
            class="float-right"
            type="neutral"
            link
            @click.native="modals.miniRename = false"
            >{{ $t('modal.cancelDel') }}
          </base-button>
        </div>

      </ValidationObserver>
    </modal>
    
    <!--Modal for Statistic -->
    <modal
      :show.sync="modals.miniStat"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div class="modal-profile" style="margin: auto;">
        <i class="tim-icons icon-chart-pie-36" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.statTitle') }}</h4>
      <h4 align="center" style="color: white">{{sliderValue*10/10}}</h4>
      
      <div style="margin-top: 15px;">
        <slider
          class="slider-visual"
          v-model="sliderValue"
          type="success"
          :options="{step: 1}"
          :range="{min: 1, max: 45}"
        >
        </slider> 
      </div>
       
      <div>
        <base-button 
          type="neutral"
          link
          @click="statistic(), modals.miniStat = false"
          >{{ $t('modal.confirmStat') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniStat = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

    <!--Modal for ReportR1 -->
    <modal
      :show.sync="modals.miniRepR1"
      class="modal-info"
      :show-close="false"
      headerClasses="justify-content-center"
      type="notice"
      appendToBody
    >
      <div class="modal-profile" style="margin: auto;">
        <i class="tim-icons icon-chart-pie-36" style="font-size: 3em"></i>
      </div>
      <h4 align="center" style="color: white">{{ $t('modal.statTitle') }}</h4>
      <h4 align="center" style="color: white">{{sliderValueR1*10/10}}</h4>
      
      <div style="margin-top: 15px;">
        <slider
          class="slider-visual"
          v-model="sliderValueR1"
          type="success"
          :options="{step: 1}"
          :range="{min: 1, max: 7}"
        >
        </slider> 
      </div>
       
      <div>
        <base-button 
          type="neutral"
          link
          @click="reportR1(), modals.miniRepR1 = false"
          >{{ $t('modal.confirmStat') }}
        </base-button>
        <base-button
          class="float-right"
          type="neutral"
          link
          @click.native="modals.miniRepR1 = false"
          >{{ $t('modal.cancelDel') }}
        </base-button>
      </div>
    </modal>

  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import { TimeLine, TimeLineItem, Modal, Slider } from 'src/components';
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";

extend('required', required);
extend('min', min);
extend('max', max);


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Slider,
    Modal,
    TimeLine,
    TimeLineItem,
    [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },
    // Status badges color
    badgeColor() {
      switch (this.record.lhbstatecode.toString()) {
        case "online":
          return "badge badge-pill badge-success";
          break;
        case "linklost":
          return "badge badge-pill badge-citrus";
          break;
        case "linkdown":
          return "badge badge-pill badge-danger";
          break;
        case "neverup":
          return "badge badge-pill badge-info";
          break;
      }
    }
  },
  data() {
    return {
      isOnline: true,
      playlistVer: null,
      curentMedia: 0,
      isAudio: true,
      media_player: null,
      sliderValue: 15,
      sliderValueR1: 3,
      valueDevName: null,
      valueDescr: null,
      devTypes: null,
      loading: true,
      loading2: true,
      loading3: true,
      tableData: [],
      record: {
        lhbstatecode: ''
      },
      record2: [],
      modals: {
        miniDel: false,
        miniStat: false,
        miniRename: false,
        miniRepR1: false
      }
    };
  },
  methods: {
    // Convert to ISO string but with time zone code
    toISOStringWithTZ(date) {
      const offset = date.getTimezoneOffset()
      const offsetAbs = Math.abs(offset)
      const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString()
      return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(Math.floor(offsetAbs / 60)).padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`
    },
    
    //Statistic report
    async statistic(){
      const Data = {
        'mode': 'device',
        'uid': this.$route.params.id,
        'days': this.sliderValue*10/10
      }
      
      this.$notify({
        type: 'info',
        message: this.$t('notifications.reportWait'),
        icon: 'tim-icons icon-bell-55'
      });

      await this.$store.dispatch('repStatistic', Data);

      this.$notify({
        type: 'success',
        message: this.$t('notifications.report'),
        icon: 'tim-icons icon-bell-55'
      })
    },

    //R1 report
    async reportR1(){
      const Data = {
        'trackuid': '-',
        'playpointuid': this.$route.params.id,
        'playlistuid': '-',
        'period': this.sliderValueR1*10/10
      }
      
      this.$notify({
        type: 'info',
        message: this.$t('notifications.reportWait'),
        icon: 'tim-icons icon-bell-55'
      });

      await this.$store.dispatch('repR1', Data);

      this.$notify({
        type: 'success',
        message: this.$t('notifications.report'),
        icon: 'tim-icons icon-bell-55'
      })
    },

    // Fetch Device by ID for head
    async fetchDeviceById() {
      this.loading = true;

      this.record = await this.$store.dispatch('fetchDevById', this.$route.params.id);
      this.valueDevName = this.record.name;
      this.valuDevType = this.record.typeCode;
      this.valueDescr = this.record.description;

      this.loading = false;
    },

    // Fetch last played tracks
    async fetchLastPlayed() {
      this.loading2 = true;

      this.record2 = await this.$store.dispatch('lastPlayed', this.record.licensekey);

      this.loading2 = false;
    },

    //Rename Device
    async renameDevice(){
      const Data = {
        'name': this.valueDevName,
        'uid': this.record.uid,
        'description': this.valueDescr
      }
      await this.$store.dispatch('renameDev', Data);

      this.$notify({
        type: 'success',
        message: this.$t('notifications.renConfirmeDev'),
        icon: 'tim-icons icon-bell-55'
      });

      await this.fetchDeviceById();
    },

    //Delete Device
    async deleteDevice() {
      const Data = {
        'uid': this.record.uid
      }

    const answer = await this.$store.dispatch('deleteDev', Data);

    this.$notify({
      type: answer.statusCode.toString() === '200' ? 'success' : 'danger',
      message: this.$t('notifications.delConfirmeDev'+ answer.statusCode.toString()),
      icon: 'tim-icons icon-bell-55'
    });

    this.$router.push('/equipments/devices')
    },

    // Set start media to player
    async onlinePlayer() {
      this.loading3 = true;
      this.$notify({
          type: 'info',
          message: this.$t('playlist.onlinePlayWait'),
          icon: 'tim-icons icon-bell-55'
        });
      this.isAudio = this.record.typeCode === 'AUDIO' ? true : false;
      this.tableData = await this.$store.dispatch('fetchPlContent', this.record.playlistuid);
      this.playlistVer = this.tableData.version;
      this.tableData = this.tableData.tracks;
      this.loading3 = false;

      this.media_player = this.isAudio ? this.$refs.audio_player : this.$refs.video_player;

      // Find start position if its SMART playlist, otherwise play from first track
      if (this.tableData[0].start !== null) {
        

        // Step №1: Check if it`s time to play content from playlist
        if ((new Date() > new Date(new Date().toISOString().split('T')[0] + 'T' + this.tableData[0].start)) &&
            (Date.now() < new Date(new Date().toISOString().split('T')[0] + 'T' + this.tableData[this.tableData.length-1].start))) {
          
          this.isOnline = false;
          
          // Step №2: Find position of content that must play now
          this.tableData.every(function(item, i, arr) {
            let playlistTimer = new Date(new Date().toISOString().split('T')[0] + 'T' + item.start)

            if (playlistTimer > Date.now()) {
              this.curentMedia = i;
              return false
            }
            return true
          }, this);
          
          
          this.media_player.src = this.tableData[this.curentMedia].url;
          this.media_player.play();
          this.sendHeartbeat();
        } else {
          this.$notify({
            type: 'danger',
            message: this.$t('playlist.onlinePlayRefusal'),
            icon: 'tim-icons icon-bell-55'
          });
        }

      } else {
        this.isOnline = false;
        this.media_player.src = this.tableData[this.curentMedia].url;
        this.media_player.play();
        this.sendHeartbeat();
      }
    },

    // Loop playing
    async nextMedia() {
      this.sendLogs();

      // Send heartbeat/update if time div 3
      if (new Date().getMinutes()%3 === 0) {
        this.sendHeartbeat();
        this.checkPlUpdate();
      }
      
      if (this.curentMedia === this.tableData.length - 1) {
          this.curentMedia = 0;
      } else {
          this.curentMedia++;
      }

      this.media_player.src = this.tableData[this.curentMedia].url;
      this.media_player.play();
      // Update played content list after avery played
      await this.fetchLastPlayed();
    },

    // Send logs
    async sendLogs () {
      const formData = [{
        'objectclass': "engine_playmonitor",
        'version': "2.0",
        'playpointkey': this.record.licensekey,
        'playlist': this.record.playlistname,
        'playlistuid': this.record.playlistuid,
        'playlistversion': this.playlistVer,
        'playtrackuid': this.tableData[this.curentMedia].uid,
        'playtrackname': this.tableData[this.curentMedia].name,
        'playtime': this.toISOStringWithTZ(new Date()),
        'comment': "-"
      }];
      const Data = {
        'playpointid': this.record.licensekey,
        'formData': formData
      };

      await this.$store.dispatch('sendPlayed', Data);
    },

    // Send heartbeats
    async sendHeartbeat () {
      const Data = {
        'objectclass': "engine_heartbeat",
        'version': "1.0",
        'playpointkey': this.record.licensekey,
        'playpointrevision': this.$i18n.t('playlist.onlinePlay'),
        'playpointstate': "OK",
        'playpointplaylist': this.record.playlistname,
        'playpointcomment': this.$i18n.t('playlist.onlinePlay'),
        'playpointdatetime': this.toISOStringWithTZ(new Date())
      }
      await this.$store.dispatch('sendHeartbeat', Data);
    },

     // Check playlist update
    async checkPlUpdate () {
      let fetchPl =  await this.$store.dispatch('fetchDevById', this.$route.params.id);
      let fetchPlVersion =  await this.$store.dispatch('fetchPlContent', fetchPl.playlistuid);

      // fetchPlVersion.version !== this.playlistVer || 

      if (fetchPlVersion.uid !== this.record.playlistuid) {
        await this.fetchDeviceById();
        await this.onlinePlayer();
      }

    }


  },
  async mounted() {
    // Fetch Device by ID for head
    await this.fetchDeviceById();

    // Fetch last played tracks
    await this.fetchLastPlayed();
    
  },
  watch: {
    
  }
};
</script>
<style>
</style>
