<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-white">
            <template slot="header">
              <img src="img/card-info.png" alt="" class="login-img" />
              <h1 class="card-title">{{ $t('loginRegPage.titleL') }}</h1>
            </template>

            <div>
              <ValidationProvider
                name="username"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
              <base-input
                required
                v-model="username"
                type="email"
                :placeholder="$t('loginRegPage.email')"
                addon-left-icon="tim-icons icon-email-85"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
             </ValidationProvider>

             <ValidationProvider
               name="password"
               rules="required|min:1"
               v-slot="{ passed, failed, errors }"
             >
             <base-input
               required
               v-model="password"
               :placeholder="$t('loginRegPage.password')"
               addon-left-icon="tim-icons icon-lock-circle"
               type="password"
               :error="errors[0]"
               :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
             </base-input>
            </ValidationProvider>
            </div>

            <div slot="footer">
              <base-button native-type="submit" type="info" class="mb-3" size="lg" block>
                {{ $t('loginRegPage.start') }}
              </base-button>
              <div style="float: right;">
                <small><i> {{version}} </i></small>
              </div>              
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  name: 'login',
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    username: '',
    password: ''
  }),

  computed: {
    userRights() {
      return JSON.parse(localStorage.getItem('user'))
    }
  },

  methods: {

    // User authorization
    async submit() {
      
      const formData = {
        'username': this.username,
        'password': this.password
      };
     
      try {
        await this.$store.dispatch('auth_login', formData);

        //Apply personal settings to store variables, if they were saved priviosly
        await this.$store.dispatch('personRead', JSON.parse(localStorage.getItem('user')).uid);

        if (JSON.parse(localStorage.getItem('personalization')).language !== null) {
          this.$i18n.locale = JSON.parse(localStorage.getItem('personalization')).language;
          
          // Update the lang="__" in the html tag when change language
          const getHTMLTag = document.documentElement;
          getHTMLTag.setAttribute("lang", this.$i18n.locale);

          if (this.$i18n.locale === "ar") {
            this.$rtl.enableRTL();
          }
        }
        
        if (this.userRights.role !== 'Streamer') {
          this.$router.push('/home/overview');
        } else {
          this.$router.push('/home/streaming/' + this.userRights.linkedDeviceUid);
        }         
               
      } catch {}
    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
