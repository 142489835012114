<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-lock card-white text-center">
            <img slot="header" src="@/assets/img/user-logo.jpg" alt="..." />

            <h4 class="card-title">{{ firstName + ' ' + lastName }}</h4>
            <ValidationProvider
              name="password"
              rules="required|min:1"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                required
                v-model="password"
                :placeholder="$t('loginRegPage.password')"
                addon-left-icon="tim-icons icon-lock-circle"
                type="password"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>

            <base-button native-type="submit" slot="footer" type="info" size="lg" round>
              {{ $t('sidebar.unlock') }}
            </base-button>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      firstName: JSON.parse(localStorage.getItem('personalization')).firstName,
      lastName: JSON.parse(localStorage.getItem('personalization')).lastName,
      username: JSON.parse(localStorage.getItem('user')).username,
      password: ''
    }
  },
  computed: {
    userRights() {
      return JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {

    // User authorization
    async submit() {
      
      const formData = {
        'username': this.username,
        'password': this.password
      };
     
      try {
        await this.$store.dispatch('auth_login', formData);

        //Apply personal settings to store variables, if they were saved priviosly
        await this.$store.dispatch('personRead', JSON.parse(localStorage.getItem('user')).uid);

        if (JSON.parse(localStorage.getItem('personalization')).language !== null) {
          this.$i18n.locale = JSON.parse(localStorage.getItem('personalization')).language;
          
          // Update the lang="__" in the html tag when change language
          const getHTMLTag = document.documentElement;
          getHTMLTag.setAttribute("lang", this.$i18n.locale);

          if (this.$i18n.locale === "ar") {
            this.$rtl.enableRTL();
          }
        }
        
        if (this.userRights.role !== 'Streamer') {
          this.$router.push('/home/overview');
        } else {
          this.$router.push('/home/streaming/' + this.userRights.linkedDeviceUid);
        }         
               
      } catch {}
    }
  }
};
</script>
<style></style>
